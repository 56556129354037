import { useEffect, useState, useCallback } from 'react'
import { getUrlParam } from '@common/oneclub/utils'
import { useHistory } from 'react-router-dom'
import { ONECLUB_APP } from '../constants'
import owFavicon from '../assets/imgs/ow-favicon.png'
import osFavicon from '../assets/imgs/os-favicon.webp'
import ocFavicon from '../assets/imgs/oc-favicon.png'
import logoOs from '../assets/imgs/logo-os.svg'
import logoOc from '../assets/imgs/logo-oc.svg'
import logoOw from '../assets/imgs/logo-ow.svg'

export default function useAdaptApp() {
  const app = getUrlParam('app')
  const history = useHistory()
  const [logo, setLogo] = useState<string>(logoOs)

  // 左上角的logo
  const setLeftLogo = useCallback(() => {
    const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    let title = ''
    document.getElementsByTagName('head')[0].appendChild(link)
    if (!app || !ONECLUB_APP.includes(app)) {
      history.replace('/middle')
      setLogo(logoOc)
      link.href = ocFavicon
      title = 'OneClub'
    }
    if (app === 'oc') {
      link.href = ocFavicon
      setLogo(logoOc)
      title = 'OneClub'
    }
    if (app === 'os') {
      link.href = osFavicon
      setLogo(logoOs)
      title = 'OneShip'
    }
    if (app === 'osvn') {
      link.href = osFavicon
      setLogo(logoOs)
      title = 'OneClub'
    }
    if (app === 'ow') {
      link.href = owFavicon
      setLogo(logoOw)
      title = 'OneWarehouse'
    }
    document.getElementsByTagName('title')[0].innerText = title
    document.getElementsByTagName('head')[0].appendChild(link)
  }, [app, history])

  useEffect(() => {
    setLeftLogo()
  }, [setLeftLogo])

  return {
    logo,
  }
}
