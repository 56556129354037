import React, { useContext, useMemo } from 'react'

type DeviceType = 'pc' | 'h5'
const context = React.createContext<DeviceType>('pc')

export const useDevice = () => {
  return useContext(context)
}

export const useIsPc = () => {
  const type = useDevice()
  return useMemo(() => type === 'pc', [type])
}

export const useIsH5 = () => {
  const type = useDevice()
  return useMemo(() => type === 'h5', [type])
}

const { Provider } = context

interface DeviceProviderProps {
  device: DeviceType
}

export const DeviceProvider: React.FC<DeviceProviderProps> = (props) => {
  const { device, children } = props

  return <Provider value={device}>{children}</Provider>
}

DeviceProvider.displayName = 'DeviceProvider'
