import React, { useState } from 'react'
import { OneInput } from '@yy/one-ui'
import cls from 'classnames'
import { InputProps } from 'antd/lib/input'
import { withLabelFormItem, LabelHocProps } from '@common/oneclub/components'
import hidePasswordIcon from './hidePassword.svg'
import showPasswordIcon from './showPassword.svg'
import styles from './index.module.less'

export const PasswordInputNormal: React.FC<InputProps> = (props) => {
  const { className, ...otherProps } = props
  const [showPassword, setShowPassword] = useState(false)
  const right = (
    <img
      className={styles.hidePasswordIcon}
      src={(showPassword && showPasswordIcon) || hidePasswordIcon}
      onClick={() => {
        setShowPassword((showPassword) => !showPassword)
      }}
    />
  )

  return <OneInput className={cls(styles.pwd, className)} suffix={right} type={(showPassword && 'text') || 'password'} {...otherProps}></OneInput>
}

PasswordInputNormal.displayName = 'PasswordInputNormal'

export const PasswordInput = withLabelFormItem<InputProps & LabelHocProps>(PasswordInputNormal)
