import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from 'antd'
import { Language as LanguageIcon, LogOut, ChevronRight } from '@yy/one-icon'
import { useDispatch, useSelector } from '@common/oneclub/store'
import { loginout as logout } from '@common/oneclub/store/authSlice'
import { getOneClubPath } from '@common/oneclub/utils'
import { useHistory } from 'react-router'
import type { MenuProps } from 'antd'
import type { MenuInfo } from 'rc-menu/lib/interface'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import styles from './index.module.less'

export interface ProfileMenuProps extends Omit<MenuProps, 'items'> {
  onLangClick?(event: MenuInfo): void
}

export const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
  const { onClick, onLangClick, ...restProps } = props
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userInfo = useSelector((state) => state.auth.userInfo)
  const languageStore = useSelector((state) => state.language)
  const languageList = useSelector((state) => state.user.languageList)
  const history = useHistory()

  const onActions = useCallback(
    (event: MenuInfo) => {
      try {
        if (typeof onClick === 'function') {
          onClick(event)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }

      try {
        if (typeof onLangClick === 'function') {
          switch (event.key) {
            case 'lang':
              onLangClick(event)
              break
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    [onClick, onLangClick]
  )

  const handleLogout = useCallback(async () => {
    await dispatch(logout())
    const url = getOneClubPath('/user/login', languageStore.lang)
    history.push(url)
  }, [dispatch, history, languageStore.lang])

  const items = useMemo<ItemType[]>(() => {
    return [
      {
        key: 'profile',
        label: (
          <div className={styles.profile}>
            <h1 className={styles.name}>{userInfo?.oneshipName}</h1>
            <span className={styles.email}>{userInfo?.email || userInfo?.phone}</span>
          </div>
        ),
        disabled: true,
      },
      {
        key: 'lang',
        icon: <LanguageIcon className={styles.icon} />,
        label: (
          <div className={styles.language}>
            {languageList.find((item) => item.languageType === languageStore?.languageType)?.label}
            <ChevronRight style={{ fontSize: 18 }} />
          </div>
        ),
      },
      {
        key: 'logout',
        onClick: handleLogout,
        icon: <LogOut className={styles.icon} />,
        label: t('frame.logout'),
      },
    ]
  }, [userInfo?.oneshipName, userInfo?.email, userInfo?.phone, languageList, handleLogout, t, languageStore?.languageType])

  return <Menu className={styles.root} {...restProps} onClick={onActions} items={items} />
}

ProfileMenu.displayName = 'ProfileMenu'
