import { options2Map } from '@common/oneclub/utils/options2map'

// 产品类型
export enum PRODUCT {
  oneship = 'ONESHIP',
  oneWarehouse = 'ONEWAREHOUSE',
  oneClub = 'ONECLUB',
  all = 'ALL',
}

export const PRODUCT_OPTIONS = [
  {
    value: PRODUCT.oneship,
    label: 'OneShip',
  },
  {
    value: PRODUCT.oneWarehouse,
    label: 'OneWarehouse',
  },
]

export const WALLET_PRODUCT_OPTIONS = [
  {
    value: PRODUCT.oneship,
    label: 'OneShip',
  },
  {
    value: PRODUCT.oneWarehouse,
    label: 'OneWarehouse',
  },
  {
    value: PRODUCT.oneClub,
    label: 'OneClub',
  },
]

export const ProductMap = options2Map(WALLET_PRODUCT_OPTIONS, 'value', 'label')
