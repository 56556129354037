import React, { useMemo } from 'react'
import { Redirect } from 'react-router'
import { User, Onboard, Order, Setting, Balance } from '@yy/one-icon'
import { AdminLayout } from '@/layouts/AdminLayout'
import { withAsync } from '@/components/Async'
import { travelRoutes } from '@common/oneclub/utils'
import { useSelector } from '@common/oneclub/store'
import { withSuperManager } from '@/components/Authorization'
import { withAuthCheck } from '@common/oneclub/components/Authorization'
import { KYC_MEMU_COUNTRY } from '@common/oneclub/constants/kyc'
import BlankLayout from '@/layouts/Blank'
import type { RouteConfig } from 'react-router-config'

interface AdminRouteContext {
  /** 权限 */
  permission: {
    /** 主账号 */
    root?: boolean
    /** 允许申请的地区 */
    apply?: boolean
  }
  /** 是否展示钱包 */
  displayWallet?: boolean
}

interface AdminRouteConfig extends RouteConfig {
  interceptor?(route: RouteConfig, context: AdminRouteContext): RouteConfig
  routes?: AdminRouteConfig[]
}

const routes: AdminRouteConfig[] = [
  {
    name: 'admin',
    path: '/admin',
    component: withSuperManager(withAuthCheck(AdminLayout)),
    routes: [
      /** 多平台入口页 */
      {
        icon: User,
        name: 'middle',
        menuTitle: 'sidebarMenu.middle',
        path: '/middle',
        component: withAsync(() => import(/* webpackChunkName: "Middle" */ '@/containers/admin/Middle')),
        exact: true,
      },
      /** 管理员设置 */
      {
        icon: User,
        name: 'manager',
        menuTitle: 'sidebarMenu.manager',
        path: '/manager',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/Manager')),
        exact: true,
        permission: ['root'],
      },
      /** 添加管理员 */
      {
        name: 'addManager',
        path: '/manager/add',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/ManagerForm')),
        exact: true,
      },
      /** 编辑管理员 */
      {
        name: 'editManager',
        path: '/manager/:id',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/ManagerForm')),
        exact: true,
      },
      /** 商家信息管理 */
      {
        icon: Onboard,
        name: 'seller',
        menuTitle: 'sidebarMenu.seller',
        path: '/seller',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/Seller')),
        exact: true,
        permission: ['root'],
      },
      {
        name: 'seller-skyinfo',
        path: '/seller/skyInfo',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/Seller/KycInfo')),
        exact: true,
        // permission: ['root'],
      },
      /** 账号管理 */
      {
        icon: Setting,
        name: 'account',
        menuTitle: 'sidebarMenu.account',
        path: '/account',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/Account')),
        exact: true,
      },
      /** 服务申请 */
      {
        icon: Order,
        name: 'serviceApply',
        menuTitle: 'service.title',
        path: '/serviceApply',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/ServiceApply')),
        exact: true,
        permission: ['root', 'apply'],
      },
      {
        name: 'apply',
        path: '/serviceApply/apply',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/ServiceApply/Apply')),
        exact: true,
      },
      {
        path: '/sign',
        component: withAsync(() => import(/* webpackChunkName: "AdminManage" */ '@/containers/admin/Sign')),
        exact: true,
      },
      {
        path: '/wallet',
        component: BlankLayout,
        menuTitle: 'sidebarMenu.wallet',
        icon: Balance,
        interceptor(route, context) {
          const { displayWallet } = context
          return displayWallet ? route : null
        },
        routes: [
          {
            path: '/',
            component: withAsync(() => import('@/containers/admin/Wallet')),
            exact: true,
          },
          /** 流水记录 */
          {
            path: '/record',
            component: withAsync(() => import('@/containers/admin/Wallet/Record')),
            exact: true,
          },
          {
            path: '/pay',
            component: withAsync(() => import('@/containers/admin/Wallet/Pay')),
            exact: true,
          },
        ],
      },
      {
        path: ['/', '/*'],
        exact: true,
        interceptor(route, context) {
          const { permission } = context
          if (typeof permission?.root !== 'boolean') {
            return null
          }

          const component = () => <Redirect to="/404" />
          return Object.assign({}, route, { component })
        },
      },
    ],
    interceptor(route, context) {
      // 权限获取参考 `routers/interceptors/permission.ts` 文件
      const { permission } = context
      const routes = travelRoutes(route.routes, (route) => {
        // 超级用户权限
        if (Array.isArray(route?.permission)) {
          const permits = route?.permission.filter((name) => permission[name])
          if (permits?.length === route?.permission?.length) {
            return route
          }

          return null
        }

        return route
      })

      route.routes = routes
      return route
    },
  },
]

/**
 * 权限控制
 * @description
 * 放在这里可以很好管理整一个模块的路由
 * 因为若前置条件没有达到(例如: 正在等待获取接口)
 * 这时应该输出 null 给调用方, 以便设置 loading
 */
export const useAdminRoutes = (routes: RouteConfig[]) => {
  const info = useSelector((state) => state?.user?.info)
  const displayWallet = useSelector((state) => state?.auth?.userInfo?.displayWallet)

  const permission = useMemo(() => {
    const apply = KYC_MEMU_COUNTRY.includes(info?.countryCode)
    const root = typeof info?.mainAccount === 'boolean' ? info?.mainAccount : false
    return { root, apply }
  }, [info])

  return useMemo(() => {
    if (!info) {
      return null
    }

    return travelRoutes(routes, (route: RouteConfig) => {
      if (typeof route.interceptor === 'function') {
        return route.interceptor(route, { permission, displayWallet })
      }

      return route
    })
  }, [info, routes, permission, displayWallet])
}

export default routes
