import { ValuesType } from 'utility-types'
import * as Types from '@common/oneclub/types'

/** 已取消 */
export const CANCELLED = 0

/** 新建 */
export const TO_BE_DELIVERED = 1

/** 待揽收 */
export const TO_PICK_UP = 2

/** 已签收 */
export const RECEIVED = 3

/** 问题件 */
export const ERROR = 4

/** 已退件 */
export const RETURNED = 5

/** 退货在途 */
export const RETURNING = 6

/** 已揽收 */
export const PROCESS = 21

/** 在途中 */
export const SHIPPING = 22

/** 到达派件城市 */
export const REACHED = 23

/** 派送中 */
export const DELIVERING = 24

/** 已到店 */
export const ARRIVED_STORE = 24002

/** 拒收 */
export const REJECTED = 25

/** 待发货子状态 */
export const TO_BE_DELIVERED_SUB_STATUS = <const>{
  /** 处理中 */
  PROCESS: 1000,
  /** 发货失败 */
  FAIL: 1001,
  /** 运营处理中 */
  OPERATION_PROCESS: 1002,
}

/** 订单状态 */
export const ORDER_STATUS = <const>{
  CANCELLED,
  TO_BE_DELIVERED,
  TO_PICK_UP,
  RECEIVED,
  ERROR,
  RETURNED,
  RETURNING,
  PROCESS,
  SHIPPING,
  REACHED,
  DELIVERING,
  REJECTED,
  ARRIVED_STORE,
}

type OrderStatus = ValuesType<typeof ORDER_STATUS> | ValuesType<typeof TO_BE_DELIVERED_SUB_STATUS>

/** 运单Tab(筛选专用) */
export const LOGISTICS_TAB: Types.Dict<{ value: OrderStatus[] }> = [
  {
    value: [],
    label: 'logistics.list.all',
  },
  {
    value: [TO_BE_DELIVERED_SUB_STATUS.PROCESS, TO_BE_DELIVERED_SUB_STATUS.FAIL],
    label: 'logistics.list.pendingToShip',
  },
  {
    value: [TO_PICK_UP],
    label: `enums.waybillStatus.${TO_PICK_UP}`,
  },
  {
    value: [PROCESS, SHIPPING, REACHED, DELIVERING],
    label: 'logistics.list.shipping',
  },
  {
    value: [RECEIVED],
    label: 'logistics.list.completed',
  },
  {
    value: [ERROR, REJECTED, RETURNED, RETURNING],
    label: 'logistics.list.unusual',
  },
  {
    value: [CANCELLED],
    label: 'logistics.list.cancelled',
  },
]

/** 订单状态对应的Label */
export const ORDER_STATUS_LABEL: Types.DictLabel<OrderStatus, { color: string }> = {
  // 待发货以及子状态
  [TO_BE_DELIVERED]: {
    label: `enums.logisticStatus.${TO_BE_DELIVERED}`,
    color: 'default',
  },
  [TO_PICK_UP]: {
    label: `enums.logisticStatus.${TO_PICK_UP}`,
    color: 'processing',
  },
  // 已揽收
  [PROCESS]: {
    label: 'logistics.list.pickedUp',
    color: 'processing',
  },
  [SHIPPING]: {
    label: 'logistics.list.shipping',
    color: 'processing',
  },
  [DELIVERING]: {
    label: 'logistics.list.delivering',
    color: 'processing',
  },
  [REACHED]: {
    label: 'logistics.list.reached',
    color: 'default',
  },
  [RECEIVED]: {
    label: 'logistics.list.received',
    color: 'success',
  },
  [REJECTED]: {
    label: 'logistics.list.rejected',
    color: 'end',
  },
  /** 退货中 */
  [RETURNING]: {
    label: 'logistics.list.returning',
    color: 'processing',
  },
  [RETURNED]: {
    label: 'logistics.list.returned',
    color: 'end',
  },
  [CANCELLED]: {
    label: 'logistics.list.cancelled',
    color: 'end',
  },
  [ERROR]: {
    label: 'logistics.list.error',
    color: 'error',
  },
  [TO_BE_DELIVERED_SUB_STATUS.PROCESS]: {
    label: 'logistics.list.processing',
    color: 'processing',
  },
  [TO_BE_DELIVERED_SUB_STATUS.OPERATION_PROCESS]: {
    label: 'logistics.list.failed',
    color: 'error',
  },
  [TO_BE_DELIVERED_SUB_STATUS.FAIL]: {
    label: 'logistics.list.failed',
    color: 'error',
  },
  [ARRIVED_STORE]: {
    label: 'logistics.list.arrivedStore',
    color: 'success',
  },
}

export type ShipmentStatusDict = Types.Dict<{ value: OrderStatus }>

/** 运单状态 */
export const SHIPMENT_STATUS: Types.Dict<{ value: OrderStatus }> = [
  {
    value: CANCELLED,
    label: 'logistics.list.cancelled',
  },
  {
    value: TO_BE_DELIVERED_SUB_STATUS.PROCESS,
    label: `enums.logisticStatus.${TO_BE_DELIVERED_SUB_STATUS.PROCESS}`,
  },
  {
    value: TO_BE_DELIVERED_SUB_STATUS.FAIL,
    label: 'logistics.list.failed',
  },
  {
    value: TO_PICK_UP,
    label: `enums.logisticStatus.${TO_PICK_UP}`,
  },
  {
    value: RECEIVED,
    label: 'logistics.list.received',
  },
  {
    value: ERROR,
    label: 'logistics.list.error',
  },
  {
    value: RETURNED,
    label: 'logistics.list.returned',
  },
  {
    value: RETURNING,
    label: 'logistics.list.returning',
  },
  {
    value: PROCESS,
    label: 'logistics.list.pickedUp',
  },
  {
    value: SHIPPING,
    label: 'logistics.list.shipping',
  },
  {
    value: REACHED,
    label: 'logistics.list.reached',
  },
  {
    value: DELIVERING,
    label: 'logistics.list.delivering',
  },
  {
    value: ARRIVED_STORE,
    label: 'logistics.list.arrivedStore',
  },
  {
    value: REJECTED,
    label: 'logistics.list.rejected',
  },
]

export const SHIPED_STATUS = ['delivery', 'canceled', 'closed', 'finished']

export enum PickupTypesEnum {
  /** 上门揽收 */
  PICK_UP = 1,
  /** 商家自寄 */
  DROP_OFF = 2,
}

export const PICKUP_TYPES_MAP = {
  [PickupTypesEnum.PICK_UP]: 'courier.detail.comeHome',
  [PickupTypesEnum.DROP_OFF]: 'courier.detail.selfDelivery',
}

export enum DeliveryTypeEnum {
  /** 店配 */
  PICKUP_IN_STORE = 4,
}
