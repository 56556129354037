import React, { useEffect } from 'react'
import { pick } from 'lodash'
import { useMount } from 'ahooks'
import * as Sentry from '@sentry/browser'
import { useSelector } from '@common/oneclub/store'
import { shallowEqual } from 'react-redux'

const SSID = 'ssid'

let ssid: string = (() => {
  try {
    return window.sessionStorage.getItem(SSID)
  } catch (error) {
    // nothing todo...
  }
})()

function genSSID() {
  if (ssid) {
    return ssid
  }

  ssid = (Math.floor(Math.random() * 10e6) + Date.now()).toString(32)
  window.sessionStorage.setItem('ssid', ssid)
  return ssid
}

export const SentryConfiguration: React.FC = React.memo((props) => {
  const { children } = props
  const info = useSelector((state) => state?.auth?.userInfo, shallowEqual)
  // 设置当前访问的 Session ID
  // 用于追踪用户本次访问的错误
  useMount(() => {
    const ssid = genSSID()
    Sentry.setTag('ssid', ssid)
  })

  // 设置用户信息
  useEffect(() => {
    const { nickname, email } = info || {}
    const userInfo = { nickname, email }
    const validProps = Object.keys(userInfo).filter((name) => userInfo[name])
    const finalUserInfo = pick(userInfo, validProps)

    if (Object.keys(finalUserInfo).length > 0) {
      // 源码是覆盖, 所以这里必须每次更新用户信息都需要完整的字段
      Sentry.setUser(finalUserInfo)
    }
  }, [info])

  return <>{children}</>
})

SentryConfiguration.displayName = 'SentryConfiguration'
