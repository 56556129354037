import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'

import store from '@common/oneclub/store'

import { setLanguage } from '@common/oneclub/store/languageSlice'
import { LANGUAGE } from '@common/oneclub/constants'
import { getLocalLang, getUrlParam } from './utils'
const localLang = getLocalLang()

export const noLang = {
  label: '显示多语言key',
  lang: 'nokey',
  languageType: 'nokey' as 'en',
  antd: 'nokey_NO',
}
export const canShowI18nKey = process.env.APP_ENV !== 'product'
if (canShowI18nKey) {
  const t = i18n.t
  i18n.t = (...args) => (i18n.language === noLang.lang ? args[0] : t.call(i18n, ...args))
}

const assignFetch = async (params: { url: string; nameSpace: string }[]) => {
  const datas = await Promise.all(params.map(({ url }) => fetch(url).then((res) => res.json())))
  return datas
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: function (lngs: string[]) {
        const lng = lngs[0]
        if (lng === 'zh' || lng === 'zh-CN') {
          return `${process.env.I18N_URL}/zh-hans-cn.json?lng=${lng}`
        }
        if (lng === 'zh-TW') {
          return `${process.env.I18N_URL}/zh-hant-tw.json?lng=${lng}`
        }
        if (lng === 'zh-HK') {
          return `${process.env.I18N_URL}/zh-hant-hk.json?lng=${lng}`
        }
        return `${process.env.I18N_URL}/{{lng}}.json?lng=${lng}`
      },
      async request(_options, url, _payload, callback) {
        const commonUrl = url.replace(process.env.I18N_URL, process.env.COMMON_LANG_URL)
        const urls = [
          { url, nameSpace: 'translation' },
          { url: commonUrl, nameSpace: 'common' },
        ]
        // 如果i18n已初始化，则直接请求
        const datas = await assignFetch(urls)
        urls.forEach((config, index) => {
          i18n.addResources(getUrlParam('lng', url), config.nameSpace, datas[index])
        })
        callback(null, { status: 200, data: null })
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: localLang?.[0]?.lang,
    debug: false,
    supportedLngs: ['en', 'zh', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
    keySeparator: '',
    load: 'currentOnly',
  })
  .then(() => {
    store.dispatch(setLanguage(localLang[0] || LANGUAGE.find((lang) => lang.languageType === 'en')))
  })

export const LanguageChangedEvents: Set<(language: string) => void> = new Set()

export const onLanguageChanged = (fn: (language: string) => void) => {
  LanguageChangedEvents.add(fn)
  return () => {
    LanguageChangedEvents.delete(fn)
  }
}

/**
 * 如果没有对应的多语言，则使用fallback
 * @param key
 * @param t
 * @param fallback
 * @returns
 */
export const fallbackT = (key, t, fallback) => {
  const errorText = t(key)
  if (errorText === key) return fallback
  return errorText
}

export default i18n
