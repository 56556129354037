import { MESSAGE_RESOURCE, DOWNLOAD_FAILED_MAP, DOWNLOAD_SUCCESS_MAP, OPERATE_STATUS } from '@common/oneclub/constants'
import type { DownloadItemType, MessageItemType } from '@common/oneclub/store/notificationSlice/type'
import { cloneDeep } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from '@common/oneclub/store'
import { useIsH5 } from '../components/Device'
import { translateCurrency } from '../utils'
import { useHistory } from 'react-router-dom'
import { useUpdateDefaultWallet } from './useUpdateDefaultWallet'

interface MessageInfo {
  /** 站内信标题 */
  title: string
  /**站内信内容第一行 */
  content: string
  /** 站内信标题前缀 */
  suffix?: string
  /** 站内信内容第二行（某些站内信有两行内容） */
  content1?: string
}

interface BtnConfigsType {
  btnText: string
  onClick: (p?: any) => void
  show: string | boolean
}

export type StatusTypes = 'success' | 'normal' | 'fail'

interface Params {
  messageItem: MessageItemType
  onClose?: () => void
  isH5?: boolean
}

/**
 * 消息中心信息展示
 */
export function useGetMessageInfo({ messageItem, onClose, isH5 }: Params) {
  const { t: originT } = useTranslation()
  const history = useHistory()
  const countryCode = useSelector((state) => state.auth.userInfo.countryCode)
  const updateDefalutWallet = useUpdateDefaultWallet()
  // 格式化一下
  const item = useMemo(() => {
    const formatItem = cloneDeep(messageItem)
    if (!formatItem?.extInfo) {
      formatItem.extInfo = {}
    }
    try {
      formatItem.extInfo = {
        ...formatItem.extInfo,
        ...JSON.parse(formatItem.msgBody),
      }
    } catch (e) {
      // do nothing
    }
    return formatItem
  }, [messageItem])

  // 统一注入extInfo
  const t = useCallback(
    (key: string, otherInfo?: Record<string, string>) =>
      originT(key, {
        ...item.extInfo,
        ...otherInfo,
      }),
    [item.extInfo, originT]
  )

  /** 消息状态 */
  const messageStatus = useMemo<StatusTypes>(() => {
    if (item.extInfo?.status === OPERATE_STATUS.success) {
      return 'success'
    }

    if (item.extInfo?.status === OPERATE_STATUS.fail) {
      return 'fail'
    }

    return 'success'
  }, [item.extInfo?.status])

  // 消息中心通知
  const messageInfo = useMemo<MessageInfo>(() => {
    // 充值到账提醒
    if (item.msgSource === MESSAGE_RESOURCE.walletDeposit) {
      return {
        title: t('message.rechargeTitle'),
        content: t('message.rechargeContent'),
        suffix: t(`wallet.account${countryCode}`),
      }
    }

    if (item.msgSource === MESSAGE_RESOURCE.walletDepositFail) {
      return {
        title: t('message.rechargeTitle.failed'),
        content: t('message.rechargeContent.failed'),
        suffix: t(`wallet.account${countryCode}`),
      }
    }

    if (item.msgSource === MESSAGE_RESOURCE.adjustAccount) {
      const infos = {
        amount: translateCurrency(Math.abs(item.extInfo.amount)),
        afterdealTotalBalance: translateCurrency(item.extInfo.afterdealTotalBalance),
      }
      let content = item.extInfo?.amount > 0 ? t('adjust.account.content.add', infos) : t('adjust.account.content.sub', infos)
      if (isH5) {
        content = item.extInfo?.amount > 0 ? t('adjust.account.contentH5.add', infos) : t('adjust.account.contentH5.sub', infos)
      }
      return {
        title: t('adjust.account.title', {
          currencyCountryCode: originT(`countryCode.${item.extInfo.currencyCountryCode}`, ''),
        }),
        content,
      }
    }

    return {
      title: '',
      content: '',
    }
  }, [item.msgSource, item.extInfo.amount, item.extInfo.afterdealTotalBalance, item.extInfo.currencyCountryCode, t, countryCode, isH5, originT])

  return {
    messageInfo,
    messageStatus,
    btnConfigs: [
      {
        show: item.msgSource === MESSAGE_RESOURCE.adjustAccount,
        btnText: t('common.viewDetails'),
        onClick() {
          updateDefalutWallet(item.extInfo.currencyCode)
          history.push('/admin/wallet')
        },
      },
    ]
      .filter((item) => {
        return item.show
      })
      .map((item) => ({
        ...item,
        onClick: () => {
          item.onClick?.()
          onClose?.()
        },
      })), // 暂时没操作按钮，需要再加
  }
}

interface ListItemType {
  onCheck?: () => void
}

/** 改变国家地区需要的信息 */
export interface ChangeAreaType {
  area: string
  data: any
}

export type IlistMessageItemType = ListItemType & {
  item: MessageItemType
}

export type IlistDownloadItemType = ListItemType & {
  item: DownloadItemType
}
/**
 * 下载中心消息展示
 */
export function useGetDownLoadInfo({ item }: IlistDownloadItemType) {
  const { t: originT } = useTranslation()

  // 统一注入item信息
  const t = useCallback((key: string) => originT(key, item), [item, originT])

  /** 消息状态 */
  const messageStatus = useMemo<StatusTypes>(() => {
    if ([1, 4].includes(item.fileStatus)) {
      return 'normal'
    }

    if (item.fileStatus === 2) {
      return 'success'
    }

    if (item.fileStatus === 3) {
      return 'fail'
    }
  }, [item.fileStatus])

  const messageInfo = useMemo<MessageInfo>(() => {
    if (item.fileStatus === 2) {
      return {
        title: t(DOWNLOAD_SUCCESS_MAP[item.taskType]?.title),
        content: t(DOWNLOAD_SUCCESS_MAP[item.taskType]?.content),
      }
    }

    if (item.fileStatus === 3) {
      return {
        title: t(DOWNLOAD_FAILED_MAP[item.taskType]?.title),
        content: t(DOWNLOAD_FAILED_MAP[item.taskType]?.content),
      }
    }

    return {
      title: '',
      content: '',
    }
  }, [item.fileStatus, item.taskType, t])

  // 解决ios window.open 不生效问题
  const isH5 = useIsH5()
  const jumpToSomeWhere = useCallback((url) => {
    const targetGun = document.createElement('a')
    targetGun.href = url
    targetGun.click()
  }, [])

  const handleDownload = useCallback(
    async (e?: React.MouseEvent) => {
      e && e.stopPropagation()
      if (isH5) {
        jumpToSomeWhere(item.presignedUrl)
      } else {
        window.open(item.presignedUrl)
      }
    },
    [isH5, item.presignedUrl, jumpToSomeWhere]
  )

  /** 操作按钮 */
  const btnConfigs = useMemo<BtnConfigsType[]>(() => {
    return [
      {
        btnText: t('download.button'),
        onClick: handleDownload,
        show: item.presignedUrl,
      },
    ].filter((item) => item.show)
  }, [handleDownload, item.presignedUrl, t])

  return {
    messageInfo,
    messageStatus,
    btnConfigs,
  }
}
