import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { MatchedRoute } from 'react-router-config'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
import { useMount } from 'ahooks'
import { BreadcrumbContext } from '../../context/BreadcrumbContext'
import Banner from '@common/oneclub/assets/imgs/brand.svg'
import { Profile } from '../Profile'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import type { MenuDataItem } from '@common/oneclub/utils'
import { useSelector } from '@common/oneclub/store'
import Notify from '../Notify'
import Download from '../Download'
import Wallet from '../Wallet'
import styles from './index.module.less'

const { Header, Content, Sider } = Layout

export interface PageLayoutProps {
  routers?: MenuDataItem[]
  window?: () => Window
  matchedRoutes: MatchedRoute<string>[]
}

export const PageLayout: React.FC<PageLayoutProps> = (props) => {
  const { routers, matchedRoutes, children } = props
  const { t } = useTranslation()
  const [openKeys, setOpenKeys] = useState([])
  const history = useHistory()

  const displayWallet = useSelector((state) => state.auth.userInfo.displayWallet)

  const renderMenuItem = useCallback(
    (item: MenuDataItem, hasParent = false) => {
      const { tag, path, menuTitle, icon: Icon } = item || {}
      const active = window.location.pathname.match(path as string)

      return {
        className: classNames(styles.singleItem, {
          [styles.hasParent]: hasParent,
          [styles.active]: !!active,
        }),
        label: (
          <span className={classNames({ [styles.subItemText]: !!Icon, [styles.text]: !Icon })}>
            {Icon ? <Icon className={classNames(styles.icon, 'icon')} /> : null}
            {path ? <Link to={path}>{t(menuTitle)}</Link> : t(menuTitle)}
            {tag ? <span className={styles.menuTag}>{tag}</span> : null}
          </span>
        ),
        key: path,
      }
    },
    [t]
  )

  const subMenuItemRender = useCallback(
    (item: MenuDataItem): ItemType => {
      const { tag, icon: SubIcon, menuTitle: subMenuTitle, path: subPath, children: subChildren } = item || {}
      const subActive = window.location.pathname.split('/')[1] === (subPath as string).split('/')[1]

      if (subChildren && subChildren.length > 0) {
        return {
          key: subPath,
          icon: typeof SubIcon === 'function' ? <SubIcon className={classNames(styles.icon, 'icon')} /> : SubIcon,
          className: classNames(styles.subItem, { [styles.subActive]: !!subActive }),
          label: (
            <>
              {t(subMenuTitle)}
              {tag ? <span className={styles.menuTag}>{tag}</span> : null}
            </>
          ),
        }
      }

      return renderMenuItem(item)
    },
    [renderMenuItem, t]
  )

  const handleMenuChange = useCallback(
    (keys) => {
      const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
      // 打开第一个子菜单
      const target = routers.find((it) => it.path === latestOpenKey)
      const jumpUrl = target?.children?.[0]?.path || target?.path
      jumpUrl && history.push(jumpUrl as string)
    },
    [history, openKeys, routers]
  )

  const handleMenuOpen = useCallback(() => {
    window.requestAnimationFrame(() => {
      const defaultOpenKey = window.location.pathname.split('/')
      setOpenKeys([`/${defaultOpenKey[1]}`])
    })
  }, [])

  useMount(() => {
    history.listen(() => {
      handleMenuOpen()
    })

    handleMenuOpen()
  })

  const items = useMemo(() => routers.map((item) => subMenuItemRender(item)), [routers, subMenuItemRender])

  return (
    <Layout hasSider className={styles.prolayout}>
      <Sider className={styles.sider} width={240}>
        <Menu items={items} theme="light" onOpenChange={handleMenuChange} mode={'inline'} openKeys={openKeys} defaultOpenKeys={['/ship']}>
          {routers.map((item) => subMenuItemRender(item))}
        </Menu>
      </Sider>

      <Layout className={styles.layout}>
        <Header className={classNames(styles.header)}>
          <div className={styles.headerContent}>
            <img src={Banner} className={styles.branding} />
            <div className={styles.headerRight}>
              {displayWallet && <Wallet />}
              <Notify />
              <Download hasWallet={displayWallet} />
              <Profile />
            </div>
          </div>
        </Header>

        <Content className={styles.container}>
          <BreadcrumbContext.Provider value={matchedRoutes}>
            <div id="scroll-content-target" className={styles.contentHeight}>
              {children}
            </div>
          </BreadcrumbContext.Provider>
        </Content>
      </Layout>
    </Layout>
  )
}

PageLayout.displayName = 'PageLayout'
