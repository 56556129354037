import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper'
import { getUrlParam } from '@common/oneclub/utils'
import { useTranslation } from 'react-i18next'
import { getPromoteInfoApi } from '../../api'
import { LANGUAGE, APP_COUNTRY, SOURCE_SYSTEM } from '@common/oneclub/constants'
import 'swiper/less'
import 'swiper/less/navigation'
import 'swiper/less/pagination'
import styles from '../../index.module.less'
import osP1 from '@common/oneclub/assets/imgs/layout/OS-P1.png'
import osP2 from '@common/oneclub/assets/imgs/layout/OS-P2.png'
import osP3 from '@common/oneclub/assets/imgs/layout/OS-P3.png'
import oWP1 from '@common/oneclub/assets/imgs/layout/OW-P1.png'
import oWP2 from '@common/oneclub/assets/imgs/layout/OW-P2.png'
import oWP3 from '@common/oneclub/assets/imgs/layout/OW-P3.png'
import oCP1 from '@common/oneclub/assets/imgs/layout/OC-P1.png'
import oCP2 from '@common/oneclub/assets/imgs/layout/OC-P2.png'
import oCP3 from '@common/oneclub/assets/imgs/layout/OC-P3.png'

const Carousel = () => {
  const app = getUrlParam('app')
  const { t, i18n } = useTranslation()
  const pics = {
    os: [
      {
        img: osP1,
        title: t('layout.slider.title.os1'),
        subTitle: t('layout.slider.subtittle.os1'),
      },
      {
        img: osP2,
        title: t('layout.slider.title.os2'),
        subTitle: t('layout.slider.subtittle.os2'),
      },
      {
        img: osP3,
        title: t('layout.slider.title.os3'),
        subTitle: t('layout.slider.subtittle.os3'),
      },
    ],
    osvn: [
      {
        img: osP1,
        title: t('layout.slider.title.os1'),
        subTitle: t('layout.slider.subtittle.os1'),
      },
      {
        img: osP2,
        title: t('layout.slider.title.os2'),
        subTitle: t('layout.slider.subtittle.os2'),
      },
      {
        img: osP3,
        title: t('layout.slider.title.os3'),
        subTitle: t('layout.slider.subtittle.os3'),
      },
    ],
    ow: [
      {
        img: oWP1,
        title: t('layout.slider.title.ow1'),
        subTitle: t('layout.slider.subtittle.ow1'),
      },
      {
        img: oWP2,
        title: t('layout.slider.title.ow2'),
        subTitle: t('layout.slider.subtittle.ow2'),
      },
      {
        img: oWP3,
        title: t('layout.slider.title.ow3'),
        subTitle: t('layout.slider.subtittle.ow3'),
      },
    ],
    oc: [
      {
        img: oCP1,
        title: t('layout.slider.title.oc1'),
        subTitle: t('layout.slider.subtittle.oc1'),
      },
      {
        img: oCP2,
        title: t('layout.slider.title.oc2'),
        subTitle: t('layout.slider.subtittle.oc2'),
      },
      {
        img: oCP3,
        title: t('layout.slider.title.oc3'),
        subTitle: t('layout.slider.subtittle.oc3'),
      },
    ],
  }

  const others = ['oshk', 'osmy', 'ossg', 'ostw', 'osth', 'osid', 'oscn', 'osau', 'osus', 'osjp']

  const isOther = others.find((i) => i === app)
  others.forEach((name) => (pics[name] = pics['os']))
  const [curAppInfo, setCurAppInfo] = useState(pics[app])
  const [allAppInfo, setAllAppInfo] = useState([])
  const updateCurAppInfo = (data = []) => {
    const newAppInfo = []
    data.forEach((i) => {
      const langDetail = i.langDetails.find((item) => item.lang === LANGUAGE.find((i) => i.lang === i18n.language).languageType)
      const defaultLangInfo = i.langDetails.find((i) => i.defaultPic === 1)
      newAppInfo.unshift({
        img: i.picUrl,
        title: langDetail?.title || defaultLangInfo?.title,
        subTitle: langDetail?.content || defaultLangInfo?.content,
        url: i.actionUrl,
      })
    })
    newAppInfo.length !== 0 && setCurAppInfo(newAppInfo)
    newAppInfo.length === 0 && setCurAppInfo(pics[app])
  }
  useEffect(() => {
    allAppInfo.length === 0 &&
      app &&
      getPromoteInfoApi({ countryCode: APP_COUNTRY?.[app] || 'normal', sourceSystem: SOURCE_SYSTEM[app] }).then(({ data: _data }) => {
        const { data = [] } = _data || {}
        updateCurAppInfo(data)
        setAllAppInfo(data)
      })
    updateCurAppInfo(allAppInfo)
    // eslint-disable-next-line
  }, [i18n.language, app])

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          clickableClass: 'my-pagination-clickable',
        }}
        modules={[Autoplay, Pagination]}
        className={styles.swiper}
      >
        {curAppInfo?.map(({ img, title, subTitle, url }) => {
          const finalUrl = /http/.test(url) ? url : 'https://' + url
          if (isOther && allAppInfo.length !== 0) {
            return [
              <SwiperSlide>
                <div
                  onClick={() => {
                    finalUrl && window.open(finalUrl)
                  }}
                  className={styles.configSwiper}
                  style={{ backgroundImage: `url(${img})` }}
                >
                  <div className={styles.swiperTitle}>{title}</div>
                  <div className={styles.swiperSubtitle}>{subTitle}</div>
                </div>
              </SwiperSlide>,
            ]
          }
          return [
            <SwiperSlide>
              <div className={styles.swiperContent}>
                <div className={styles.swiperbox}>
                  <img src={img} className={url ? styles.img : null}></img>
                  <div className={styles.swiperTitle}>{title}</div>
                  <div className={styles.swiperSubtitle}>{subTitle}</div>
                </div>
              </div>
            </SwiperSlide>,
          ]
        })}
      </Swiper>
    </>
  )
}

export default Carousel
