import { cloneDeep } from 'lodash'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import Api from '../../services/http'
import type { AppDispatch } from '..'
import type * as Types from './type'

const storeResultMapStorage = {
  key: 'notification-map-all-key',
  set(data) {
    sessionStorage.setItem(this.key, JSON.stringify(data))
    return true
  },
  get() {
    const data = sessionStorage.getItem(this.key)

    try {
      const _data = JSON.parse(data)
      return Array.isArray(_data) ? _data : []
    } catch (error) {
      return []
    }
  },
}

const initialState: Types.NotificationType = {
  downloadInfo: {
    pageNum: 1,
    hasNext: false,
    data: [],
    socketData: [],
    currentSocketData: null,
  },
  messageInfo: {
    pageNum: 1,
    hasNext: false,
    data: [],
    socketData: [],
    currentSocketData: null,
  },
  unReadMessageCount: 0,
  unReadDownloadCount: 0,
  // 目前只有一个自定义打印面对的异步事件，先以taskId为key存储所有taskResults，taskId全局唯一
  // 后续如果一个页面需要存在多个异步事件需要接受处理的话，再将每个异步事件独立开
  taskResultsMap: {},
  taskResultsMapAll: storeResultMapStorage.get(),
  // socket连接失败时补偿接口需要用到
  taskIdCaches: [],
  // dashboard首页通知
  hasNotifyCount: false,
}

export const NotificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setDownloadList(state, action: PayloadAction<Types.ResponseDownloadListType>) {
      const { pageNum, data, hasNext } = action.payload
      const list = pageNum >= 2 ? state.downloadInfo.data.concat(data) : data
      return {
        ...state,
        downloadInfo: {
          ...state.downloadInfo,
          pageNum,
          hasNext,
          data: list,
        },
      }
    },
    setSocketDownloadList(state, action: PayloadAction<Types.DownloadItemType[]>) {
      const data = action.payload
      const list = data.length ? state.downloadInfo.socketData.concat(data) : data
      const [currentSocketData] = data
      return {
        ...state,
        downloadInfo: {
          ...state.downloadInfo,
          currentSocketData,
          socketData: list,
        },
      }
    },
    setMessageList(state, action: PayloadAction<Types.ResponseMessageListType>) {
      const { pageNum, data, hasNext } = action.payload
      const list = pageNum >= 2 ? state.messageInfo.data.concat(data) : data
      return {
        ...state,
        messageInfo: {
          ...state.messageInfo,
          pageNum,
          hasNext,
          data: list,
        },
      }
    },
    setSocketMessageList(state, action: PayloadAction<Types.MessageItemType[]>) {
      const data = action.payload
      const list = data.length ? state.messageInfo.socketData.concat(data) : data
      const [currentSocketData] = data
      return {
        ...state,
        messageInfo: {
          ...state.messageInfo,
          currentSocketData,
          socketData: list,
        },
      }
    },
    setMessageRead(state, action: PayloadAction<Types.MessageReadType>) {
      const { messageInfo, unReadMessageCount } = state
      const { msgSeq } = action.payload
      // 没有msgSeq则为全部已读
      const newMessageList = messageInfo.data.map((item: Types.MessageItemType) => {
        if (!msgSeq || item.msgSeq === msgSeq) {
          const newItem = cloneDeep(item)
          newItem.read = true
          return newItem
        }
        return item
      })
      // 同时设置当前消息已读
      let currentSocketData = messageInfo.currentSocketData
      if (currentSocketData && !currentSocketData.read && (!msgSeq || currentSocketData.msgSeq === msgSeq)) currentSocketData = { ...currentSocketData, read: true }

      const count = unReadMessageCount - 1
      return {
        ...state,
        messageInfo: {
          ...state.messageInfo,
          currentSocketData,
          data: newMessageList,
        },
        unReadMessageCount: count < 0 ? 0 : count,
      }
    },
    setDownloadRead(state, action: PayloadAction<Types.DownloadReadType>) {
      const { downloadInfo, unReadDownloadCount } = state
      const newDownloadList = downloadInfo?.data?.map((item) => {
        if (item.id === action.payload.id) {
          const newItem = cloneDeep(item)
          newItem.read = true
          return newItem
        }
        return item
      })
      const count = unReadDownloadCount - 1
      return {
        ...state,
        downloadInfo: {
          ...state.downloadInfo,
          data: newDownloadList,
        },
        unReadDownloadCount: count < 0 ? 0 : count,
      }
    },
    setUnReadMessageCount(state, action: PayloadAction<{ messageCount: number }>) {
      const messageCount = action.payload.messageCount
      if (messageCount === null || messageCount === undefined) return state
      return {
        ...state,
        unReadMessageCount: Number(action.payload.messageCount),
      }
    },
    setUnReadDownloadCount(state, action: PayloadAction<{ exportCount: number }>) {
      const exportCount = action.payload.exportCount
      if (exportCount === null || exportCount === undefined) return state
      return {
        ...state,
        unReadDownloadCount: Number(exportCount),
      }
    },
    addTaskResultsItem(state, action: PayloadAction<Types.ITaskResultProps>) {
      const limit = 10000 // 防止无限增大
      const taskResultsMap = Object.assign({}, state.taskResultsMap, { [action.payload.taskId]: action.payload })
      const taskResultsMapAll = [action.payload, ...state.taskResultsMapAll]
      if (taskResultsMapAll.length > limit) taskResultsMapAll.splice(limit)
      storeResultMapStorage.set(taskResultsMapAll)
      return {
        ...state,
        taskResultsMap,
        taskResultsMapAll,
      }
    },
    deleteTaskResultsItem(state, action: PayloadAction<{ taskId: string }>) {
      const taskResultsMap = { ...state.taskResultsMap }
      delete taskResultsMap[action.payload.taskId]
      return {
        ...state,
        taskResultsMap,
      }
    },
    addTaskId(state, action: PayloadAction<{ taskId: string }>) {
      const taskIdCaches = [...state.taskIdCaches]
      taskIdCaches.push(action.payload.taskId)
      return {
        ...state,
        taskIdCaches,
      }
    },
    deleteTaskId(state, action: PayloadAction<{ taskId: string }>) {
      const taskIdCaches = state.taskIdCaches.filter((taskId) => taskId !== action.payload.taskId)
      return {
        ...state,
        taskIdCaches,
      }
    },
    setHasNotifyCount(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasNotifyCount: action.payload,
      }
    },
  },
})

export const notificationActions = NotificationSlice.actions

/** 导出文件列表 */
export const queryDownloadList =
  (props: { pageNum?: number; pageSize?: number } = {}) =>
  async (dispatch: AppDispatch) => {
    const { pageNum = 1, pageSize = 100 } = props
    const response = await Api.admin.post<Types.ResponseDownloadListType>('/api/user/task/page', { pageNum, pageSize })
    const { data: rstData, code } = response
    if (code === 'SUCCESS') {
      const { pageNum, hasNext, data: listData } = rstData
      dispatch(notificationActions.setDownloadList({ pageNum, hasNext, data: listData }))
    }
  }

/** 用户消息列表 */
export const queryMessageList =
  (props: { pageNum?: number; pageSize?: number } = {}) =>
  async (dispatch: AppDispatch) => {
    const { pageNum = 1, pageSize = 100 } = props
    const { data: rstData } = await Api.admin.post<Types.ResponseMessageListType>('/api/user/message/list', { pageNum, pageSize })
    const { hasNext, data: listData } = rstData
    dispatch(notificationActions.setMessageList({ pageNum: rstData.pageNum, hasNext, data: listData }))
  }

/** 已读消息 */
export const readMessage = (msgSeq?: string) => async (dispatch: AppDispatch) => {
  await Api.admin.post('/api/user/message/read', { msgSeq })
  dispatch(notificationActions.setMessageRead({ msgSeq }))
}

/** 已读下载 */
export const readDownload = (id: number) => async (dispatch: AppDispatch) => {
  await Api.admin.post('/api/user/task/read', { id })
  dispatch(notificationActions.setDownloadRead({ id }))
}

export default NotificationSlice.reducer
