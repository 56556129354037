import React, { useCallback, useState } from 'react'
import { useDispatch } from '@common/oneclub/store'
import { setCanSubmit } from '@common/oneclub/store/authSlice'
import cls from 'classnames'
import styles from './index.module.less'

export interface LabelHocProps {
  /**
   * 是否监听自动填充
   */
  watchAutoFill?: boolean
}

/**
 * 生成label在顶部的表单
 * @param Component
 * @returns
 */
export function withLabelFormItem<P extends Record<string, any>>(Component: React.FC<Record<string, any>>) {
  const LabelFormItem: React.FC<P> = (props) => {
    const [focus, setFocus] = useState(false)
    const [isAutoFill, setIsAutoFill] = useState(false)
    const dispatch = useDispatch()

    const { placeholder, onChange, value, className, inputRef, watchAutoFill, ...restProps } = props

    // 浏览器自动填充处理
    const handleOnAnimationStart = useCallback(
      (event: AnimationEvent) => {
        if (watchAutoFill) {
          setIsAutoFill(event.animationName === 'autofillFocus')
          dispatch(setCanSubmit(true))
        }
      },
      [watchAutoFill, dispatch]
    )

    return (
      <div className={cls(styles.labelInputBox, (focus || value || isAutoFill) && styles.focus)}>
        <span className={styles.label}>{placeholder}</span>
        <Component
          {...restProps}
          className={cls(styles.labelInput, className)}
          onChange={onChange}
          value={value}
          onFocus={() => {
            setFocus(true)
            props.onFocus && props.onFocus()
          }}
          onBlur={() => {
            setFocus(false)
            props.onBlur && props.onBlur()
          }}
          ref={inputRef}
          onAnimationStart={handleOnAnimationStart}
        />
      </div>
    )
  }

  LabelFormItem.displayName = 'LabelFormItem'
  return LabelFormItem
}
