import { Dropdown, Menu, Space } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Balance, ChevronDown, ChevronUp } from '@yy/one-icon'
import { OneButton } from '@yy/one-ui'
import { REF_OBJECT } from '@common/oneclub/constants'
import { useDispatch, useSelector } from '@common/oneclub/store'
import { translateCurrency } from '@common/oneclub/utils'
import { updateDefaultWallet } from '@common/oneclub/store/authSlice'
import styles from './index.module.less'

const Wallet: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [dropDownVisible, setDropDownVisible] = useState(false)

  const defaultWallet = useSelector((state) => state.auth.walletList.find((wallet) => wallet.isDefault) || REF_OBJECT)
  const walletList = useSelector((state) => state.auth.walletList)
  const hasMultiWallet = walletList.length > 1

  const handleOpen = () => {
    history.push('/admin/wallet')
  }

  const defaultWalletChange = useCallback(
    async (v) => {
      setDropDownVisible(false)
      if (v.key === defaultWallet.currencyCode) {
        return
      }
      // 更新默认钱包
      dispatch(updateDefaultWallet(v.key))
    },
    [defaultWallet.currencyCode, dispatch]
  )

  const menu = useMemo(
    () =>
      hasMultiWallet ? (
        <Menu selectedKeys={[defaultWallet.currencyCode]} onClick={defaultWalletChange}>
          {walletList.map((item) => (
            <Menu.Item key={item.currencyCode}>
              <Space size={4}>
                <span>{item.currencyCode}</span>
                <span>{translateCurrency(item.totalBalance)}</span>
              </Space>
            </Menu.Item>
          ))}
        </Menu>
      ) : (
        <></>
      ),
    [defaultWallet.currencyCode, defaultWalletChange, hasMultiWallet, walletList]
  )

  return (
    <Dropdown overlay={menu} placement="bottom" onVisibleChange={setDropDownVisible} overlayClassName={styles.dropDown}>
      <OneButton onClick={handleOpen} type="text" className={styles.walletBtn}>
        <Space className="color-grey-text-primary" size={6}>
          <Balance className={styles.icon} />
          <span>{defaultWallet.currencyCode}</span>
          <span>{translateCurrency(defaultWallet.totalBalance || 0)}</span>
          {hasMultiWallet ? dropDownVisible ? <ChevronUp className={styles.icon} /> : <ChevronDown className={styles.icon} /> : null}
        </Space>
      </OneButton>
    </Dropdown>
  )
}

Wallet.displayName = 'Wallet'
export default Wallet
