import React, { useState, useEffect, useRef, useCallback, useImperativeHandle, forwardRef } from 'react'
import { OneInput } from '@yy/one-ui'
import { InputProps } from 'antd'
import classnames from 'classnames'
import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { withLabelFormItem } from '@common/oneclub/components/'

import styles from './index.module.less'

type VerProps = React.ComponentProps<typeof VerificationCodeNormal>
interface VerificationCodeProps extends InputProps {
  onSend?: () => Promise<void>
  rightFixStyle?: string
}

export interface VerificationCodeRefType {
  resetCountDown: () => void
  sendMsgSuccess: () => void
  hadSendMsg: () => void
}

export const VerificationCodeNormal = forwardRef<VerificationCodeRefType, VerificationCodeProps>((props, ref) => {
  const { onSend, rightFixStyle, ...otherProps } = props
  const { t } = useTranslation()
  const [isSending, setIsSending] = useState(false)
  const [countdown, setCountdown] = useState(60)
  const [hasClick, setHasClick] = useState(false)
  const [loadingVerify, setLoadingVerify] = useState(false)

  const countdownRef = useRef(countdown)
  const timerRef = useRef<NodeJS.Timeout>()

  const sendMsgSuccess = () => {
    setHasClick(true)
    setIsSending(true)
  }
  const hadSendMsg = () => {
    sendMsgSuccess()
    setLoadingVerify(false)
  }

  useImperativeHandle(ref, () => ({
    resetCountDown,
    sendMsgSuccess,
    hadSendMsg,
  }))

  const sendCodeHandler = async () => {
    setLoadingVerify(true)
    onSend()
      .then(() => {
        sendMsgSuccess()
        setLoadingVerify(false)
      })
      .finally(() => {
        setLoadingVerify(false)
      })
  }

  const resetCountDown = useCallback(() => {
    setIsSending(false)
    setCountdown(60)
    setHasClick(false)
    clearInterval(timerRef.current)
  }, [])

  const right = (
    <div className={classnames([styles.verificationCode, rightFixStyle, { [styles.isSending]: isSending }])} onClick={sendCodeHandler}>
      {loadingVerify ? <LoadingOutlined></LoadingOutlined> : isSending ? `${t('udb.verify.button')} ${countdown}s` : t('udb.verify.button')}
    </div>
  )

  useEffect(() => {
    const updateFn = () => {
      setCountdown((countdown) => countdown - 1)
      if (countdownRef.current <= 0) {
        setIsSending(false)
        clearInterval(timerRef.current)
        setCountdown(60)
      }
    }

    if (isSending) {
      setCountdown(60)
      timerRef.current = setInterval(updateFn, 1000)
    }

    return () => {
      clearInterval(timerRef.current)
    }
  }, [isSending])

  useEffect(() => {
    countdownRef.current = countdown
  })

  return (
    <div className={styles.verInput}>
      <OneInput disabled={!hasClick} {...otherProps} addonAfter={right} />
    </div>
  )
})

const VerificationCodeWidh = withLabelFormItem<VerProps & { inputRef: VerProps['ref'] }>(VerificationCodeNormal)

export const VerificationCode = React.forwardRef<VerificationCodeRefType, VerificationCodeProps>((props, ref) => {
  return <VerificationCodeWidh {...props} inputRef={ref} />
})
