import React, { useEffect } from 'react'
import { Link as LinkIcon } from '@yy/one-icon'
import { OneNotification } from '@yy/one-ui'
import { useTranslation } from 'react-i18next'
import { useDispatch } from '@common/oneclub/store'
import { readDownload, readMessage } from '@common/oneclub/store/notificationSlice'
import type { DownloadItemType, MessageItemType } from '@common/oneclub/store/notificationSlice/type'
import { sleep } from '@common/oneclub/utils'
import Parabola, { uuid } from '@common/oneclub/utils/parabola'
import { type StatusTypes, useGetDownLoadInfo, useGetMessageInfo } from '@common/oneclub/hooks'
import styles from './index.module.less'
import type { ArgsProps } from 'antd/lib/notification'

const NotificationFnMap: Record<StatusTypes, (arg: ArgsProps) => void> = {
  success: OneNotification.success,
  fail: OneNotification.error,
  normal: OneNotification.info,
}

/**
 * 抛物线动画
 * @param target 目标元素 id
 * @returns
 */
export function getParabola(target: string) {
  const uuidString = uuid()
  const originId = `o-${uuidString}`

  const getParabolaIns = () => {
    const config = {
      origin: `.${originId}`,
      element: `.${originId}`,
      target,
      defaultStyle: { borderRadius: '4px' },
    }
    const parabolaIns = new Parabola(config)
    return parabolaIns
  }

  /** 开始抛物线动画 */
  const startParabolaAnimation = async () => {
    const parabolIns = getParabolaIns()
    await parabolIns.start()
    parabolIns.destroy()
    parabolIns.removeElement()
  }

  return {
    startParabolaAnimation,
    originId,
    uuid: uuidString,
  }
}

interface MessageNotificationProps {
  /** 通知消息内容项 */
  messageItem: MessageItemType
}

/**
 * 消息中心通知
 * messageItem 改变，证明有新消息
 */
export const MessageNotification: React.FC<MessageNotificationProps> = React.memo(
  ({ messageItem }) => {
    const dispatch = useDispatch()

    const time = 5000

    const { messageInfo, messageStatus, btnConfigs } = useGetMessageInfo({ messageItem })

    const { title, content, content1 } = messageInfo

    useEffect(() => {
      ;(async () => {
        if (!messageItem) return

        const key = messageItem.msgSeq

        if (messageItem.read) {
          OneNotification.close(key)
          return
        }

        const { originId, startParabolaAnimation } = getParabola('#notify-message-button')

        let hasClosed = false
        // 已读
        const hasRead = () => {
          dispatch(readMessage(messageItem.msgSeq))
          OneNotification.close(key)
          hasClosed = true
        }

        const config: ArgsProps = {
          key,
          duration: 0,
          className: originId,
          message: <div className={styles.title}>{title}</div>,
          onClose: hasRead,
          description: (
            <>
              <div className={styles.content}>{content}</div>
              {content && content1 && <div className={styles.content}>{content1}</div>}
              {btnConfigs.length > 0 && (
                <div className={styles.btn}>
                  {btnConfigs.map((btn) => (
                    <span
                      key={btn.btnText}
                      onClick={() => {
                        btn.onClick()
                        hasRead()
                      }}
                    >
                      {btn.btnText}
                      <LinkIcon style={{ marginLeft: 4 }} />
                    </span>
                  ))}
                </div>
              )}
            </>
          ),
        }

        const Notification = NotificationFnMap[messageStatus]

        // 通知
        Notification(config)

        await sleep(+time)

        if (!hasClosed) {
          await startParabolaAnimation()
          OneNotification.close(key)
        }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, messageItem])

    return <></>
  },
  (preProps, nextProps) => {
    if (preProps.messageItem?.msgSeq !== nextProps.messageItem?.msgSeq) {
      return false
    }
    return true
  }
)

interface DownLoadNotificationProps {
  /** 下载消息内容项 */
  messageItem: DownloadItemType
}

/**
 * 下载中心通知
 */
export const DownLoadNotification: React.FC<DownLoadNotificationProps> = React.memo(
  ({ messageItem }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const time = 5000

    const { messageInfo, messageStatus, btnConfigs } = useGetDownLoadInfo({ item: messageItem })

    const { title, content } = messageInfo

    useEffect(() => {
      ;(async () => {
        if (!messageItem) return

        const key = `open_${Date.now()}`

        const { originId, startParabolaAnimation } = getParabola('#parabola-export')

        let hasClosed = false
        // 已读
        const hasRead = () => {
          dispatch(readDownload(messageItem.id))
          OneNotification.close(key)
          hasClosed = true
        }

        const config: ArgsProps = {
          key,
          duration: 0,
          className: originId,
          message: <div className={styles.title}>{t(title)}</div>,
          onClose: hasRead,
          description: (
            <>
              <div className={styles.content}>{t(content)}</div>
              {btnConfigs.length > 0 && (
                <div className={styles.btn}>
                  {btnConfigs.map((btn) => (
                    <span
                      key={btn.btnText}
                      onClick={() => {
                        btn.onClick()
                        hasRead()
                      }}
                    >
                      {btn.btnText}
                      <LinkIcon style={{ marginLeft: 4 }} />
                    </span>
                  ))}
                </div>
              )}
            </>
          ),
        }

        const Notification = NotificationFnMap[messageStatus]

        // 通知
        Notification(config)

        await sleep(+time)

        if (!hasClosed) {
          await startParabolaAnimation()
          OneNotification.close(key)
        }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, messageItem])

    return <></>
  },
  (preProps, nextProps) => {
    if (preProps.messageItem?.taskId !== nextProps.messageItem?.taskId) {
      return false
    }
    return true
  }
)
