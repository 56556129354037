import React from 'react'
import { Spin } from 'antd'
import styles from './index.module.less'
import { LoadingOutlined } from '@ant-design/icons'

export interface Iprops {
  size?: number
}
export const CircleLoading: React.FC<Iprops> = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: props?.size || 20 }} spin />
  return (
    <div className={styles.container}>
      <Spin indicator={antIcon} />
    </div>
  )
}

CircleLoading.displayName = 'CircleLoading'

export const PageLoading: React.FC = () => {
  return (
    <div className={styles.container}>
      <svg width="302" height="50" viewBox="0 0 302 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M190.212 42.7369C184.497 42.7369 179.447 39.8795 176.392 35.5258C174.459 32.7826 173.327 29.4472 173.327 25.8417C173.327 22.2361 174.47 18.9111 176.392 16.1575C179.447 11.8038 184.497 8.94639 190.212 8.94639C195.927 8.94639 200.976 11.8038 204.031 16.1575L209.528 12.313C205.257 6.23442 198.192 2.2444 190.212 2.2444C182.232 2.2444 175.166 6.23442 170.895 12.313C168.204 16.1471 166.614 20.8126 166.614 25.8417C166.614 30.8708 168.204 35.5362 170.895 39.3703C175.166 45.4489 182.232 49.4389 190.212 49.4389C198.192 49.4389 205.257 45.4489 209.528 39.3703L204.031 35.5258C200.976 39.8795 195.927 42.7369 190.212 42.7369Z"
          fill="#FF7700"
        />
        <path
          d="M78.3352 0C64.526 0 53.3352 11.1908 53.3352 25V48.8882H64.4429V25C64.4429 17.3317 70.6669 11.1076 78.3352 11.1076C86.0036 11.1076 92.2276 17.3317 92.2276 25V48.8882H103.335V25C103.335 11.1908 92.1445 0 78.3352 0Z"
          fill="#00112F"
        />
        <path
          d="M25 0C11.1908 0 0 11.1908 0 25C0 38.8092 11.1908 50 25 50C38.8092 50 50 38.8092 50 25C50 11.1908 38.8092 0 25 0ZM25 38.8924C17.3317 38.8924 11.1076 32.6683 11.1076 25C11.1076 17.3317 17.3317 11.1076 25 11.1076C32.6683 11.1076 38.8924 17.3317 38.8924 25C38.8924 32.6683 32.6683 38.8924 25 38.8924Z"
          fill="#00112F"
        />
        <path
          d="M144.68 29.8732C142.705 35.1413 137.624 38.8924 131.67 38.8924C128.584 38.8924 125.737 37.8741 123.431 36.17L155.559 17.6226C152.41 7.41895 142.903 0 131.67 0C117.861 0 106.67 11.1908 106.67 25C106.67 38.8092 117.861 50 131.67 50C143.807 50 153.927 41.3446 156.193 29.8732H144.68ZM131.67 11.1076C134.757 11.1076 137.614 12.1155 139.921 13.8196L117.872 26.5482C117.82 26.0391 117.778 25.5195 117.778 25C117.778 17.3317 123.992 11.1076 131.67 11.1076Z"
          fill="#00112F"
        />
        <path d="M213.009 3.17959H219.67V48.7739H213.009V3.17959Z" fill="#FF7700" />
        <path
          d="M252.888 30.7878C252.888 37.2924 247.609 42.5709 241.105 42.5709C236.564 42.5709 232.896 38.8926 232.896 34.3622V13.6848H226.236V34.3622C226.236 42.5813 232.896 49.2417 241.115 49.2417C245.76 49.2417 249.937 47.2883 252.898 44.1607V48.691H259.559V13.6848H252.898V30.7878H252.888Z"
          fill="#FF7700"
        />
        <path
          d="M301.621 30.9745C301.527 21.3111 293.682 13.4246 284.019 13.383C283.967 13.383 283.915 13.383 283.863 13.383C283.821 13.383 283.78 13.383 283.728 13.383C279.395 13.4038 275.498 15.2845 272.766 18.2563V3.18977H266.105V48.7841H272.766V44.3681C275.498 47.3398 279.395 49.2205 283.728 49.2413C283.769 49.2413 283.811 49.2413 283.863 49.2413C283.915 49.2413 283.967 49.2413 284.019 49.2413C293.682 49.1997 301.527 41.3132 301.621 31.6499C301.621 31.5356 301.621 31.4213 301.621 31.3174C301.621 31.1927 301.621 31.0784 301.621 30.9745ZM294.347 31.5875C294.285 37.6141 289.391 42.5497 283.354 42.5705C283.323 42.5705 283.291 42.5705 283.26 42.5705C283.229 42.5705 283.198 42.5705 283.156 42.5705C277.13 42.5497 272.225 37.6141 272.163 31.5875C272.163 31.494 272.163 31.4005 272.163 31.307C272.163 31.2135 272.163 31.1199 272.163 31.0264C272.225 24.9998 277.119 20.0642 283.156 20.0435C283.187 20.0435 283.219 20.0435 283.26 20.0435C283.291 20.0435 283.323 20.0435 283.354 20.0435C289.38 20.0642 294.285 24.9998 294.347 31.0264C294.347 31.1199 294.347 31.2135 294.347 31.307C294.347 31.4005 294.347 31.494 294.347 31.5875Z"
          fill="#FF7700"
        />
      </svg>
    </div>
  )
}
