import React, { useCallback, useRef, useMemo } from 'react'
import { Row, FormInstance, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import Udb, { ThirdLoginResponse } from '@yy/oneship-udb'
import { OneMessage, OneModal, OneSecondaryButton } from '@yy/one-ui'
import { getUrlParam, stringifyQuery } from '@common/oneclub/utils'
import classname from 'classnames'
import { useHistory } from 'react-router-dom'
import { CountDown } from '@common/oneclub/components/'
import { UDB_CONF } from '@common/oneclub/constants'
import { useIsPc } from '@common/oneclub/components/Device'
import MobileIcon from './img/mobile.svg'
import EmailIcon from './img/email.svg'
import FaceBookIcon from './img/facebook.svg'
import GoogleIcon from './img/google.svg'

import { useSelector } from '@common/oneclub/store'
import { registryUser } from '@common/oneclub/utils'
import styles from './index.module.less'
import { SOURCE_SYSTEM } from '@common/oneclub/constants'
import { FormatConfigType, getSellerBasic } from '@common/oneclub/services/api'
import { trackerReport } from '@common/oneclub/utils/trace'
import { useThirdLogin } from './hooks'

export type AccountType = 'email' | 'tel' | 'facebook' | 'google'

const thirdSigninIns = Udb.thirdSignin(UDB_CONF)

export interface AccountTypeChooseProps {
  changeAccountType: (param: AccountType) => void
  accountType: AccountType
  loginSuccessCb: (ticket: string) => void
  /**
   * 登录成功回调页面
   */
  hasReturnUrl?: boolean
  /**
   * 注册页才有
   */
  form?: FormInstance
  pageType: 'login' | 'resgister'
  thirdRegisterMethods?: FormatConfigType['thirdRegisterMethods']
  normalRegisterMethods?: FormatConfigType['normalRegisterMethods']
}

export const AccountTypeChoose: React.FC<AccountTypeChooseProps> = ({
  changeAccountType,
  accountType,
  loginSuccessCb,
  form,
  pageType,
  thirdRegisterMethods,
  normalRegisterMethods,
}) => {
  const isPc = useIsPc()
  const { t } = useTranslation()
  const history = useHistory()
  const modalRef = useRef<any>()
  const language = useSelector((state) => state.language)
  const app = getUrlParam('app')
  const service = getUrlParam('service')

  const handleChangeLoginType = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { type } = (e.target as HTMLDivElement).dataset
      if (type) {
        changeAccountType(type as AccountType)
      }
    },
    [changeAccountType]
  )
  // /**
  //  * 去注册页倒计时
  //  */
  const toRegisterModal = useCallback(() => {
    modalRef.current = OneModal.warn({
      onOk: () => {
        history.replace('/user/register')
      },
      title: t('user.email.repeat'),
      content: (
        <CountDown
          countDownfinish={() => {
            modalRef.current?.destroy()
          }}
          propHistory={history}
        />
      ),
      okText: t('user.toRegister'),
      cancelText: t('common.cancelBtn'),
    })
  }, [history, t])

  /**
   * udb文档：https://shopline.yuque.com/docs/share/18d40557-210e-4309-ba32-21f3ef49365a?#
   */
  const handleLoginAndResgister = useCallback(
    async (data: ThirdLoginResponse['data'], stoken, platForm, extinfo) => {
      const { processCode, casTicket: ticket } = data
      let path = ''
      const countryCode = form ? form.getFieldValue('countryCode') : ''
      const disAcceptMarketing = form?.getFieldValue('disAcceptMarketing')
      const target = thirdRegisterMethods?.find((item) => item.registerSource === platForm)
      const bindType = countryCode ? (target?.requirePhone ? 'tel' : 'email') : ''
      const thirdpart_account = data.uinfo?.email
      const query = {
        app,
        stoken: encodeURIComponent(stoken),
        platForm,
        email: data.uinfo?.email,
        uid: data.uinfo?.uid,
        countryCode,
        pageType,
        processCode,
        bindType,
        service,
        registryPlatform: isPc ? 'pc' : 'mobile',
        keyword: getUrlParam('keyword'),
        channel: getUrlParam('channel'),
        disAcceptMarketing: Number(!!disAcceptMarketing),
      } as Record<string, string | number>

      if (processCode === 0) {
        // 当processCode = 0，说明是非首次登录，登录成功
        loginSuccessCb(ticket)
      } else if (processCode === 1) {
        // 当processCode = 1时，代表首次三方登录授权有返回邮箱，邮箱已注册用户👎
        // 直接去合并账号判断是否可以合并，无需走绑定流程
        path = `/user/thirdMerge`
        query.bindType = 'email'
      } else if (pageType === 'login') {
        path = `/user/thirdBindCountry`
      } else if (pageType === 'resgister') {
        if (processCode === 2) {
          // 当processCode = 2，代表未获取到授权邮箱
          path = `/user/thirdBindEmail`
        } else if (processCode === 3) {
          // 当processCode = 3，代表获取到授权邮箱，且邮箱未注册用户
          if (bindType === 'email') {
            // 配置为绑定邮箱，已经有授权邮箱，可以直接登录
            const { data } = await thirdSigninIns.thirdLoginAndBindToken({ stoken, extinfo: JSON.stringify(extinfo), cas: 1 })
            // 注册成功 避免延迟
            pageType === 'resgister' && (await registryUser(extinfo))

            const res = await getSellerBasic()
            // 发送数据到聚数平台
            trackerReport({
              app,
              register_status: true,
              email: '-',
              phone: '-',
              event_name: `${app}_thirdpart_signup_success`,
              thirdpart_account,
              device: isPc ? 'pc' : 'mobile',
              seller_id: res.data?.sellerId,
            })

            // 首次登录成功
            loginSuccessCb(data.casTicket)
          } else if (bindType === 'tel') {
            // 配置为手机绑定
            path = `/user/thirdBindEmail`
          }
        }
      }

      if (path) {
        query.r_url = window.location.href
        const url = stringifyQuery(path, query as Record<string, string>)
        history.push(url)
      }
    },
    [service, form, thirdRegisterMethods, app, pageType, isPc, loginSuccessCb, history]
  )

  const getExtinfo = useCallback(
    () => ({
      countryCode: form?.getFieldValue('countryCode'),
      language: language.languageType,
      sourceSystem: SOURCE_SYSTEM[getUrlParam('app')],
      adChannel: getUrlParam('channel'),
      fromKeyword: decodeURIComponent(getUrlParam('keyword')),
      registryPlatform: isPc ? 'pc' : 'mobile',
      acceptMarketing: (() => {
        const disAcceptMarketing = form?.getFieldValue('disAcceptMarketing')
        return !disAcceptMarketing
      })(),
    }),
    [form, language.languageType, isPc]
  )
  const onThirdLoginError = useCallback(
    (error) => {
      const { code } = error as any
      switch (code) {
        case '1018':
          // 提示 授权邮箱存在账号，且已绑定该三方登录
          toRegisterModal()
          break
        case '-999':
          OneMessage.error(t('udb.thirdTimeout'))
          break
        default:
          OneMessage.error(t(`udb.rescode.${code}`))
          break
      }
    },
    [toRegisterModal, t]
  )
  /**
   * 第三方登录
   */
  const { googleImgRef, faceBookLogin } = useThirdLogin({
    onSuccess: handleLoginAndResgister,
    getExtinfo,
    onError: onThirdLoginError,
  })

  const normalRegister = useMemo(() => {
    const common = [
      {
        'data-type': 'tel',
        src: MobileIcon,
        registerSource: 'tel',
        text: 'register.phoneType',
        style: { display: accountType !== 'tel' && pageType !== 'login' ? '' : 'none' },
      },
      {
        'data-type': 'email',
        src: EmailIcon,
        registerSource: 'email',
        text: 'register.emailType',
        style: { display: accountType !== 'email' && pageType !== 'login' ? '' : 'none' },
      },
    ]
    if (normalRegisterMethods && pageType === 'resgister') {
      return normalRegisterMethods.map((item) => common.find((el) => el.registerSource === item.registerSource))
    }
    return common
  }, [accountType, normalRegisterMethods, pageType])

  const thirdRegister = useMemo(() => {
    const common = [
      {
        onClick: faceBookLogin,
        src: FaceBookIcon,
        registerSource: 'facebook',
      },
      {
        ref: googleImgRef,
        src: GoogleIcon,
        id: 'google-account-icon',
        registerSource: 'google',
      },
    ]
    if (thirdRegisterMethods && pageType === 'resgister') {
      return thirdRegisterMethods.map((item) => common.find((el) => el.registerSource === item.registerSource))
    }
    return common
  }, [faceBookLogin, pageType, thirdRegisterMethods, googleImgRef])

  return (
    <>
      <Row gutter={[12, 12]}>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {normalRegister.map(({ registerSource, ...item }) => {
          return (
            <Col {...item} span={24} key={item['data-type']}>
              <OneSecondaryButton onClick={() => handleChangeLoginType({ target: { dataset: { type: item['data-type'] } } } as any)} className={styles.normalButton}>
                {t(item.text)}
              </OneSecondaryButton>
            </Col>
          )
        })}
      </Row>
      <Row className={styles.lineWrap} align="middle">
        <span className={styles.line}></span>
        <span className={styles.or}>{t('common.or')}</span>
        <span className={styles.line}></span>
      </Row>
      <Row justify="center" className={classname(styles.iconWrap, { [styles.iconWrapH5]: !isPc })} onClick={handleChangeLoginType}>
        {thirdRegister?.map(({ registerSource, ...item }) => (
          <img {...item} alt="" key={registerSource} />
        ))}
      </Row>
    </>
  )
}

AccountTypeChoose.displayName = 'AccountTypeChoose'
