import http from '../http'

export type ResVerifyFieldItem = {
  address: '0' | '1'
  city: '0' | '1'
  cityVisible: boolean
  countryCode: string
  district: '0' | '1'
  districtVisible: boolean
  mobile: '0' | '1'
  name: '0' | '1'
  province: '0' | '1'
  provinceVisible: boolean
  town: '0' | '1'
  zipCode: '0' | '1'
}

export interface AddressLibraryListRequest {
  /**国家/地区 */
  countryCode: string
  language?: string
  /**查询层级，选填：-1 查询全部，>0 查询相应层级，默认只返回1级 */
  level?: number
  /**指定语言无数据，是否返回默认语言，true表示是，默认为false */
  useDefaultLanguage?: boolean
}

export interface AddressNodeItem {
  name: string
  // 编码
  code: string
  alias: string
  // 父级编码
  pCode: string
  zipCode: string
  children: AddressNodeItem[]
}

/**
 * 地址校验规则
 * @returns
 */
export const getAddressVerifyFields = () =>
  http.normal.post<ResVerifyFieldItem[]>('/api/address-service/country/country-rules', {
    pageSize: 999,
    pageNum: 1,
  })

/** 获取国家的所有省市区节点 */
export const getAllAddressList = (params: AddressLibraryListRequest) => http.normal.post<{ nodes: AddressNodeItem[] }>('/api/address-service/lib/list', params)
