export type OneclubApp = keyof typeof SOURCE_SYSTEM

export const ONECLUB_APP = ['os', 'oc', 'oscn', 'osvn', 'osth', 'ossg', 'osmy', 'oshk', 'ostw', 'osid', 'osau', 'ow', 'og', 'osus', 'osjp']

export enum ONECLUB_APP_ENUM {
  'os' = 'os',
  'ow' = 'ow',
  'osvn' = 'osvn',
  'og' = 'og',
}

export const SOURCE_SYSTEM = {
  os: 'ONESHIP',
  osau: 'ONESHIP',
  oscn: 'ONESHIP',
  osvn: 'ONESHIP',
  osth: 'ONESHIP',
  ossg: 'ONESHIP',
  osmy: 'ONESHIP',
  oshk: 'ONESHIP',
  ostw: 'ONESHIP',
  osid: 'ONESHIP',
  osus: 'ONESHIP',
  osjp: 'ONESHIP',
  ow: 'ONEWAREHOUSE',
  oc: 'ONECLUB',
  og: 'ONEGOODS',
}

export const APP_COUNTRY = {
  os: 'normal',
  osau: 'AU',
  oscn: 'CN',
  osvn: 'VN',
  osth: 'TH',
  ossg: 'SG',
  osmy: 'MY',
  oshk: 'HK',
  ostw: 'TW',
  osid: 'ID',
  osjp: 'JP',
  osus: 'US',
  ow: 'normal',
  oc: 'normal',
}

export const EVENT_IDS = {
  OS: '4359',
  OW: '4358',
}

export const APP_NAME = {
  os: 'OneShip',
  oscn: 'OneShip',
  osvn: 'OneClub',
  osth: 'OneShip',
  ossg: 'OneShip',
  osmy: 'OneShip',
  oshk: 'OneShip',
  ostw: 'OneShip',
  osid: 'OneShip',
  osjp: 'OneShip',
  osus: 'OneShip',
  ow: 'OneWarehouse',
}

// scene
export enum SCENE {
  'logout' = 'logout',
}

export const GOOGLE_CDN_OPTIONS = {
  src: 'https://apis.google.com/js/api:client.js',
  checkForExisting: true,
}

export const FACEBOOK_CDN_OPTIONS = {
  src: 'https://connect.facebook.net/en_US/sdk.js',
  checkForExisting: true,
}
