import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type * as Types from './types'
import { createSetReducers } from '@common/oneclub/utils/store'
import { AppDispatch } from '..'
import { Area } from './types'
import { getAddressVerifyFields, getAllAddressList } from '@common/oneclub/services/api/address'

// 配置没有该国家，省市必填
const verifyFieldMaphandler = {
  get: function (target: any, name: string) {
    return target.hasOwnProperty(name) ? target[name] : { province: '1', city: '1', provinceVisible: true, cityVisible: true, districtVisible: true }
  },
}

const initialState: Types.AddressState = {
  areaData: {},
  verifyFieldMap: new Proxy({}, verifyFieldMaphandler),
  addressNodeList: [],
}

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    ...createSetReducers(initialState),
    setCountryAreaData(state, action: PayloadAction<{ countryCode: string; data: Area[] }>) {
      const { data, countryCode } = action.payload
      return {
        ...state,
        areaData: {
          ...state.areaData,
          [countryCode]: data,
        },
      }
    },
  },
})

export const {
  setCountryAreaData,
  setVerifyFieldMap,

  setAddressNodeList,
} = addressSlice.actions

export const addressVerifyCreator = () => async (dispatch: AppDispatch) => {
  const { data: verifyFieldCountries } = await getAddressVerifyFields()
  const obj = new Proxy({}, verifyFieldMaphandler)

  const vertifyMap = verifyFieldCountries.reduce((sum: any, { countryCode, ...data }) => {
    sum[countryCode] = data
    return sum
  }, obj)
  dispatch(setVerifyFieldMap(vertifyMap))
}
export const fetchAllAddress = (countryCode: string) => async (dispatch: AppDispatch) => {
  try {
    if (!countryCode) return
    const { data } = await getAllAddressList({ countryCode, level: 3, useDefaultLanguage: true })
    dispatch(setAddressNodeList(data.nodes))
  } catch (error) {}
}

export default addressSlice.reducer
