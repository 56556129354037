import { withAsync } from '@/components/Async'
import type { RouteConfig } from 'react-router-config'
import BlankLayout from '@/layouts/Blank'

const routes: RouteConfig[] = [
  {
    path: '/blank',
    component: BlankLayout,
    routes: [
      {
        name: 'register',
        path: '/register',
        component: withAsync(() => import(/* webpackChunkName: "register" */ '@/containers/Register/IframeRegister')),
        exact: true,
      },
    ],
  },
]

export default routes
