import Api from '../../http'
import * as Types from './type'

export const queryDictionary = async (params: Types.DictionaryQueryListReq) => {
  const res = await Api.admin.post<Types.DictionaryQueryListRes>('/api/dictionary_query/list', params)
  return res.data
}

export const queryProviderList = async (params?: Types.ProviderListRequest) => {
  const res = await Api.admin.post<Types.ProviderListRes>('/api/provider/list', params)
  return res.data
}
