import * as Sentry from '@sentry/react'

import type { AxiosResponse } from 'axios'

/** admin 域 sentry 上报 */
export function adminSentryReport(rejection: Error | Record<string, any>, response: AxiosResponse<any>) {
  const { config, data, status } = response || {}
  const { url, method } = config || {}
  const { traceparent: traceId } = config?.headers || {}
  const { code, message, bizMessage, bizCode } = data || {}
  const error = rejection instanceof Error ? rejection : new Error(rejection.bizMessage || rejection.message)

  Sentry.captureException(error, (scope) => {
    scope.setTag('errorType', 'xhr')

    if (!response) {
      return scope
    }

    scope.setExtras({ traceId, bizMessage, bizCode, code, message })
    scope.addBreadcrumb({
      type: 'error',
      category: 'xhr',
      level: Sentry.Severity.Error,
      message: `${method.toUpperCase()} ${url} [${status}]`,
      data: {
        traceId: traceId.split('-')[1],
      },
    })

    return scope
  })
}

/** udb 域 sentry 上报 */
export function udbSentryReport(rejection: string, response: AxiosResponse<any>) {
  const { config, status } = response || {}
  const { url, method } = config || {}

  Sentry.captureException(new Error(rejection), (scope) => {
    scope.setTag('errorType', 'xhr')
    scope.addBreadcrumb({
      type: 'error',
      category: 'xhr',
      level: Sentry.Severity.Error,
      message: `${method.toUpperCase()} ${url} [${status}]`,
    })

    return scope
  })
}

// 手动上报错误
export const sentryReport = (messageOrError: Error | string) => {
  const error = messageOrError instanceof Error ? messageOrError : new Error(messageOrError)
  Sentry.captureException(error, (scope) => {
    scope.setTag('errorType', 'scriptReport')
    scope.addBreadcrumb({
      type: 'error',
      level: Sentry.Severity.Error,
      category: 'stack',
      message: error.stack.slice(0, 300),
    })
    return scope
  })
}
