import Cookies from 'js-cookie'
import type { SLSocketInitConfig, SLSocketInitHandle } from '@yy/sl-service-sdk-min'
import Service from '@yy/sl-service-sdk-min'
import { EVENT_TYPE } from './constants'

/** 消息回调 */
export type SocketListener<P = any> = (payload: P) => any

/** socket 配置 */
export interface SocketParams {
  /** web socket 地址 */
  wsDomain: string
  /** web socket app id */
  appId: string
  /** web socket 私钥 */
  privateKey: string
  /** 订阅广播组 */
  broadcasts: string[]
  /** udb app id */
  udbAppId: string
  /** udb sub app id */
  udbSubAppId: string
  /** 用户ID */
  uid: string
  /** udb用户认证地址 */
  udbAuthUrl: string
  /** 环境 */
  env: string
}

/** socket 选项 */
export type SocketOptions = Partial<SocketParams>

/** socket 获取配置函数 */
export type SocketOptionsGetter = (() => SocketOptions) | (() => Promise<SocketOptions>)

/** shopline web socket */
export abstract class Socket<T extends string = string> {
  /** 注册 topic 事件 */
  protected abstract connect(): void

  /** 是否初始化 */
  protected _inited: boolean

  /** 是否已连接 */
  protected _connected: boolean

  /** 监听器 */
  protected _listeners: Record<string, SocketListener[]>

  /** 动态配置获取 */
  protected _settingsGetter: SocketOptionsGetter

  /** 标记 */
  protected _symbols: [any, any][]

  /** 是否初始化 */
  public get inited() {
    return this._inited
  }

  /** 是否已连接 */
  public get connected() {
    return this._connected
  }

  constructor() {
    this._inited = false
    this._connected = false
    this._listeners = {}
    this._symbols = []
    this._settingsGetter = null
  }

  /** 获取配置 */
  protected async getSettings() {
    if (typeof this._settingsGetter !== 'function') {
      throw Error('[socket] please configure first')
    }

    const settings = await this._settingsGetter()
    this.validateSettings(settings, false)
    return settings
  }

  /**
   * 配置
   * @description
   * 因为绑定用户跟订阅广播组会在重连的时候自动触发(具体参考 _open 方法)，
   * 因此这里需要动态获取配置，否则会导致错误出现
   */
  public configure(optionsGetter?: (() => SocketOptions) | (() => Promise<SocketOptions>)) {
    if (typeof optionsGetter === 'function') {
      this._settingsGetter = optionsGetter
    }
  }

  /** 初始化SDK */
  public async open(force = false) {
    if (this._inited === true && force !== true) {
      return
    }

    const { appId, privateKey, wsDomain, udbAuthUrl, env } = await this.getSettings()
    const initParams: SLSocketInitConfig = {
      appid: appId,
      signPrv: privateKey,
      wsDomain: wsDomain,
      udbAuthUrl: udbAuthUrl,
      env: env,
    }

    const initHandle: SLSocketInitHandle = (event, payload) => {
      if (event === 'connectionStateChanged') {
        const { state } = payload
        switch (state) {
          /**
           * 连接已建立
           * 断网重连会重新触发该事件
           */
          case 'OPEN': {
            this._connected = true
            this._open()
            break
          }

          // 连接中断
          case 'CLOSED': {
            this._connected = false
            this.emit('closed', payload)
            break
          }

          // 重连中
          case 'CONNECTING': {
            this.emit('connection', payload)
            break
          }
        }
      }
    }

    if (typeof this.connect === 'function') {
      this.connect()
    }

    Service.init(initParams, initHandle)
    this._inited = true
  }

  /**
   * 连接已建立
   * @description
   * 断网重连会重新触发该事件
   */
  protected async _open() {
    const { udbAppId, udbSubAppId, uid, broadcasts } = await this.getSettings()
    // 绑定用户
    Service.bindUser(udbAppId, udbSubAppId, uid)
    // 订阅广播组
    Service.subscribe(broadcasts)
    // 完成连接
    this.emit('open')
  }

  /** 关闭连接 */
  public close() {
    if (!this._inited) return
    Service.close()
    this._inited = false
  }

  /** 连接已建立事件 */
  public onOpen<P = any>(handle: SocketListener<P>) {
    return this.on(EVENT_TYPE.OPEN, handle)
  }

  /** 连接中断事件 */
  public onClosed<P = any>(handle: SocketListener<P>) {
    return this.on(EVENT_TYPE.COLSED, handle)
  }

  /** 重连中事件 */
  public onConnecting<P = any>(handle: SocketListener<P>) {
    return this.on(EVENT_TYPE.CONNECTING, handle)
  }

  /** 错误事件 */
  public onError<P = any>(handle: SocketListener<P>) {
    return this.on(EVENT_TYPE.ERROR, handle)
  }

  /** 监听事件 */
  public on(topic: T | string, handle: SocketListener, symbol: any = handle) {
    if (typeof handle !== 'function') {
      throw Error(`handle is not a function`)
    }

    if (!Array.isArray(this._listeners?.[topic])) {
      this._listeners[topic] = []
    }

    const _listeners = this._listeners[topic]
    _listeners.push(handle)

    this._symbols.push([symbol, handle])
    return () => this.off(topic, handle)
  }

  /** 销毁监听事件 */
  public off(topic: T | string, handle: SocketListener): void
  public off(topic: T | string, symbol: any): void
  public off(topic: T | string, inSymbol: any) {
    if (!Array.isArray(this._listeners?.[topic])) {
      return
    }

    const symbolIndex = this._symbols.findIndex(([symbol]) => symbol === inSymbol)
    if (-1 === symbolIndex) {
      return
    }

    const [, handle] = this._symbols.splice(symbolIndex, 1).shift()
    if (typeof handle !== 'function') {
      return
    }

    const listenerIndex = this._listeners[topic].indexOf(handle)
    listenerIndex !== -1 && this._listeners[topic].splice(listenerIndex)
  }

  /** 事件触发 */
  public emit(topic: T | string, payload?: any) {
    if (!Array.isArray(this._listeners?.[topic])) {
      return
    }

    this._listeners[topic].forEach((handle) => {
      try {
        handle(payload)
      } catch (error) {
        Promise.reject(error)
      }
    })
  }

  /**
   * 注册 topics
   * @description
   * 如果断开连接这里需要重新注册
   */
  protected registerTopics(topics: string[]) {
    topics.forEach((topic) => {
      Service.registerTopic(topic, (payload: string) => {
        let data: any = null
        try {
          data = JSON.parse(payload)
        } catch (error) {
          this.emit('error', error)
          return
        }

        // if here have uid and subappid accepted message from the soket
        if (Cookies.get('osudb_uid') && Cookies.get('osudb_subappid')) {
          this.emit(topic, data)
        }
      })
    })
  }

  /** 校验配置 */
  protected validateSettings(settings: SocketOptions, partial = true) {
    const { appId, privateKey, udbAppId, udbSubAppId, uid, broadcasts } = settings
    const has = (name: string) => Object.prototype.hasOwnProperty.call(settings, name)

    if (partial === false || has('appId')) {
      if (!(typeof appId === 'string' && appId?.length > 0)) {
        throw Error('[socket] appId is empty')
      }
    }

    if (partial === false || has('privateKey')) {
      if (!(typeof privateKey === 'string' && privateKey?.length > 0)) {
        throw Error('[socket] privateKey is empty')
      }
    }

    if (partial === false || has('udbAppId')) {
      if (!(typeof udbAppId === 'string' && udbAppId?.length > 0)) {
        throw Error('[socket] udbAppId is empty')
      }
    }

    if (partial === false || has('udbSubAppId')) {
      if (!(typeof udbSubAppId === 'string' && udbSubAppId?.length > 0)) {
        throw Error('[socket] udbSubAppId is empty')
      }
    }

    if (partial === false || has('uid')) {
      if (!(typeof uid === 'string' && uid?.length > 0)) {
        throw Error('[socket] uid is empty')
      }
    }

    if (partial === false || has('broadcasts')) {
      if (!(Array.isArray(broadcasts) && broadcasts?.length > 0)) {
        throw Error('[socket] broadcasts is empty')
      }
    }
  }
}
