import React, { useContext } from 'react'
import loadable from '@loadable/component'

export interface AsyncConfig {
  process?: React.FC
  fallback?: React.FC
}

const AsyncConfigContext = React.createContext<AsyncConfig>({
  process: null,
  fallback: null,
})

const Fallback: React.FC = React.memo(() => {
  const { fallback: Component } = useContext(AsyncConfigContext)
  return <Component />
})

Fallback.displayName = 'Fallback'

const Progress: React.FC = React.memo(() => {
  const { process: Component } = useContext(AsyncConfigContext)
  return Component ? <Component /> : null
})

Progress.displayName = 'Progress'

const fallback = (error: Error) => () => {
  Promise.reject(error)
  return Fallback ? <Fallback /> : null
}

export function withAsync(component: () => Promise<React.FC | { default: React.FC }>) {
  const fn = () => component().catch(fallback)
  return loadable(fn, { fallback: <Progress /> })
}

export const { Provider: AsyncProvider } = AsyncConfigContext
