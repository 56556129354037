// store通用工具函数

import { IsNever } from '@common/oneclub/types'
import { PayloadAction } from '@reduxjs/toolkit'
import { ValuesType } from 'utility-types'
import { upperFirst } from './string'

type KeysType<Keys> = IsNever<Keys> extends true ? string | number | bigint : Exclude<Keys, symbol>

type SetReducers<S, Keys extends keyof S> = Omit<
  {
    [K in keyof S as K extends KeysType<Keys> ? `set${Capitalize<`${K}`>}` : '']: (state: S, action: PayloadAction<S[K]>) => S
  },
  ''
>

/**
 * 生成通用的reducers setter
 * @param state
 * @param keys
 */
export const createSetReducers = <S, K extends (keyof S)[]>(state: S, ...keys: K) => {
  if (!keys.length) keys = Object.keys(state) as K
  const reducers = {} as SetReducers<S, ValuesType<K>>
  keys.forEach((key) => {
    reducers[`set${upperFirst(key as string)}`] = (state, action) => ({
      ...state,
      [key]: action.payload,
    })
  })
  return reducers
}

/**
 * thunk节流，使用相同参数调用，且上一次未执行完，则不会执行第二次
 * @param func
 */
export const thunkThrottle = () => {
  // TODO
}
