import { create } from './create'

import type { AxiosRequestConfig, AxiosInstance } from 'axios'
import type { HttpDomainConfigs, InterceptHttp, AxiosKeys, HttpDomainConfig, AssignDefined } from './types'

/**
 * http 服务特性整合
 * @param Instance Axios/Http 实例, 通过 create 生成的实例
 * @param Body 不同域的返回格式(返回值 body)
 * @param Data 不同域的动态返回值所在位置(返回值 data 所在 body 的键值链式数组)
 * @description
 * 此处包含拦截拦截器与扩展方法的特性整合;
 * 其中 `InterceptHttp` 处理拦截 `interceptors` 器导致返回结果的修改,
 * 之后的表示扩展能力 `extensions`
 */
// prettier-ignore
export type HttpCompose<
  Instance extends AxiosInstance,
  Body extends Record<AxiosKeys<Instance>, any>,
  DataChain extends Record<AxiosKeys<Instance>, string[]>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  Extension extends Partial<Record<AxiosKeys<Instance>, any>> = {}
> = AssignDefined<Instance, {
  [K in AxiosKeys<Instance>]: Instance[K] extends AxiosInstance
    ? AssignDefined<
      InterceptHttp<Instance[K], Body[K], DataChain[K]>,
      Extension[K]
    >
    : never
}>

/**
 * 因为每个域都可能有所不同,
 * 因此这里使用可以根据配置来对
 * 不同的域名进行不同的操作
 *
 * 如果出现结果处理或请求数据`在该特定域名下`需要特殊处理
 * 这里可以引入拦截器 (inteceptor) 配置;
 *
 * @example
 * export default {
 *   api: {
 *     interceptors ({ request, response }) {
 *        // 方式跟 ajax 添加拦截器一样
 *        request.use(...)
 *        response.use(...)
 *     }
 *   }
 * }
 *
 * * (注意) 如果是默认配置的修改可以直接在
 * * `@/services/http/create.js` 进行全局修改
 */
export function compose<Domains extends Record<string, HttpDomainConfig<any>>>(domains: Domains | HttpDomainConfigs<Domains>) {
  return function generate<Body extends Record<keyof Domains, any>, DataChain extends Record<keyof Domains, string[]>, Extension extends Record<keyof Domains, any>>(
    axiosConfig?: AxiosRequestConfig
  ) {
    const http = create<Domains>(domains, axiosConfig)
    type Http = HttpCompose<typeof http, Body, DataChain, Extension>
    return http as any as Http
  }
}
