/**
 * 生成唯一id, 做为选择器的时候不能没有prefix，不然会出现数字开头， querySelector会报错
 */
export const uuid = (prefix = 'parabola') => prefix + Math.random().toString(36).slice(-8)

interface IConfigProps {
  /** 起点元素选择符号 */
  origin: string
  /** 目标点元素选择符号 */
  target: string
  /** 要运动的元素选择符号 */
  element: string
  /** 动画执行时间 ms */
  duration?: number
  /** 定制要运动的元素的样式 例如： { background: '#fff', border: '1px solid #15bbb9', zIndex: 999999} */
  defaultStyle?: React.CSSProperties
}
const BACKGROUND_COLOR = '#ff7700'
const TEMP_ANIMATION_STRING = 'temp 2s'

export default class Parabola {
  private $: FnType<HTMLElement> // 元素选择器
  private timer: ReturnType<typeof setTimeout> | null // 轮训时间
  // private origin: HTMLElement
  // private target: HTMLElement
  // private element: HTMLElement
  private duration: number
  private defaultStyle: React.CSSProperties
  private hasAnimationStyle: boolean
  private animationName: string
  private config: IConfigProps

  constructor(config: IConfigProps) {
    this.$ = (selector: string) => {
      return document.querySelector(selector)
    }
    this.timer = null
    // this.origin = this.$(config.origin) || null
    // this.target = this.$(config.target) || null
    // this.element = this.$(config.element) || null
    this.defaultStyle = config.defaultStyle
    this.duration = config.duration || 600
    this.hasAnimationStyle = false
    this.animationName = uuid('run-')
    this.config = config
  }

  get origin() {
    return this.$(this.config.origin)
  }

  get target() {
    return this.$(this.config.target)
  }

  get element() {
    return this.$(this.config.element)
  }

  // 设置初始样式保证运动元素在出事位置及解决外层可能有overflow问题
  private initElementStyle() {
    const rect = this.origin?.getBoundingClientRect()
    if (!rect) return
    const { left, top, width, height } = rect
    const parabolaStyle = {
      opacity: '1',
      position: 'fixed',
      top: `${top}px`,
      left: `${left}px`,
      width: `${width}px`,
      height: `${height}px`,
      background: BACKGROUND_COLOR,
      zIndex: 999,
    }

    // 运动目标是自身，不用默认背景色
    if (this.config.origin === this.config.element) {
      delete parabolaStyle.background
    }

    const style = Object.assign({}, parabolaStyle, this.defaultStyle)
    for (const key in style) {
      this.element.style[key] = style[key]
    }
  }

  // 将keyframes样式存放到head标签
  private setAnimationStyle() {
    const { left: originX, top: originY } = this.origin.getBoundingClientRect()
    const { width, height } = this.element.getBoundingClientRect()
    const { left: targetX, top: targetY, width: targetWidth, height: targetHeight } = this.target.getBoundingClientRect()

    // 元素距离屏幕左上角的差值计算  (width - targetWidth) / 2为了让两个元素最终保持居中
    const diffX = targetX - originX - (width - targetWidth) / 2
    const diffY = targetY - originY - (height - targetHeight) / 2

    const keyframes = ` @keyframes ${this.animationName} {
      0% {
        transform: translate(0, 0) scale(0.9, 0.9);
        opacity: 0.9;
      }
      100% {
        transform: translate(${diffX}px, ${diffY}px) scale(0.2, 0.2);
        opacity: 0.1;
      }
    }`
    // 创建style标签
    const style = document.createElement('style')
    // 将 keyframes样式写入style内
    style.innerHTML = keyframes
    document.getElementsByTagName('head')[0].appendChild(style)
  }

  // 修改运动元素的初始位置和动画路径
  private changeStyle() {
    const { left, top } = this.origin.getBoundingClientRect()
    this.element.style.top = `${top}px`
    this.element.style.left = `${left}px`
    this.setAnimationStyle()
  }

  private handleResize = this.changeStyle.bind(this)

  public start() {
    return new Promise((resolve, reject) => {
      if (this.timer) {
        reject()
        return
      }
      if (!this.hasAnimationStyle && this.origin && this.element) {
        this.initElementStyle()
        this.setAnimationStyle()
        window.addEventListener('resize', this.handleResize)
        this.hasAnimationStyle = true
      }
      if (this.element) {
        // 设置临时animation名称后再替换为正式的名称
        this.element.style.animation = TEMP_ANIMATION_STRING
      }
      setTimeout(() => {
        if (this.element) {
          this.element.style.animation = `${this.animationName} ${this.duration / 1000}s`
        }
      }, 1)
      // 延时到动画结束
      this.timer = setTimeout(() => {
        if (this.element) {
          this.element.style.animation = TEMP_ANIMATION_STRING
          this.element.style.opacity = '0'
          this.timer = null
          resolve(this)
        }
      }, this.duration)
    })
  }

  public removeElement() {
    this.element?.remove()
  }

  public destroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}
