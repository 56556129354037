import React, { useContext, useMemo } from 'react'
import { useRequest } from 'ahooks'
import { getTargetOmsConfig } from '@common/oneclub/services/api'

interface Country {
  value: string
  label: string
  areaCode: string
}

export interface SupportedCountry {
  label: string
  code: string
  countryCode: string
}

interface Configs {
  /** 国家列表 */
  country_list: Country[]
  /** arrange of the supported register phone list */
  supported_register_country_list: SupportedCountry[]
}

const SettingContext = React.createContext<Configs>(null)

export const useOneclubConfigs = () => {
  return useContext(SettingContext)
}

export interface OneclubConfigurationProps {
  block?: boolean
  loadingElement?: React.ReactNode
}

export const OneclubConfiguration: React.FC<OneclubConfigurationProps> = (props) => {
  const { block = true, loadingElement } = props
  const { children } = props
  const { data, loading } = useRequest(
    async () => {
      const [country_list, supported_register_country_list] = await Promise.all([getTargetOmsConfig('oneclub_country_list'), getTargetOmsConfig('supported_register_country_list')])
      return { country_list, supported_register_country_list }
    },
    {
      manual: false,
      retryCount: 10,
      retryInterval: 1e3,
    }
  )

  const configs = useMemo(() => data || ({} as Configs), [data])

  if (block && loading) {
    return <>{loadingElement}</>
  }

  return (
    <SettingContext.Provider value={configs}>
      <>{children}</>
    </SettingContext.Provider>
  )
}

OneclubConfiguration.displayName = 'OneclubConfiguration'
