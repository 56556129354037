import React, { useEffect, useCallback, useState, useRef } from 'react'
import { OneButton, OneMessage } from '@yy/one-ui'
import { Form } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import Udb, { LoginVerifyParams } from '@yy/oneship-udb'
import RULES from '@common/oneclub/constants/rules'
import { useDispatch } from '@common/oneclub/store'
import { useRedirect } from '@common/oneclub/hooks'
import { useOneclubConfigs } from '@common/oneclub/configuration'
import { setLogin, setCanSubmit, getRegisterConfig } from '@common/oneclub/store/authSlice'
import { useValidateError, useCaptchaLanguageChange } from '@common/oneclub/hooks'
import { UdbResCodeEnum, UDB_CONF, SOURCE_SYSTEM } from '@common/oneclub/constants/'
import { getCountryByIp } from '@common/oneclub/services/api'
import { useIsPc } from '@common/oneclub/components/Device'
import { getUrlParam } from '@common/oneclub/utils/'
import { dataLayerPush } from '@common/oneclub/utils/dataLayer'
import { AccountType, AccountTypeChoose } from '../AccountTypeChose'
import { Captcha, PasswordInput, MobileEmailInput } from '@common/oneclub/components'

import styles from './index.module.less'
import { useMount } from 'ahooks'
import { useSelector } from 'react-redux'

const signinInstance = Udb.signin(UDB_CONF)

interface LoginBoxProps {
  loginSuccessCb: (ticket: string) => void
  hasReturnUrl?: boolean
  dfptoken: string
}
const accLableMap = {
  email: 'udb.label.email',
  mobile: 'common.mobile',
}
export const LoginBox: React.FC<LoginBoxProps> = ({ loginSuccessCb, hasReturnUrl, dfptoken }) => {
  const isPc = useIsPc()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { historyPush } = useRedirect()
  const { country_list } = useOneclubConfigs()

  const captchaTokenRef = useRef('')
  const stokenRef = useRef('')
  const canSubmit = useSelector((state: any) => state?.auth.canSubmit)

  const [accountType, setAccountType] = useState<AccountType>('email')
  const [loading, setLoading] = useState(false)
  const [captchaVisble, setCaptchaVisble] = useState(false)
  const [inputType, setInputType] = useState('')
  const captchaLang = useCaptchaLanguageChange()

  useValidateError(form)

  /**
   * 初始化
   */
  useEffect(() => {
    dfptoken &&
      signinInstance.init({
        type: 'acct',
        dfptoken,
      })
  }, [dfptoken])

  /**
   * 自动选择区号
   */
  useEffect(() => {
    getCountryByIp().then(({ data }) => {
      const item = country_list?.find((item) => item.value === data.countryCode)
      if (item) {
        form.setFieldsValue({
          areaCode: item.areaCode.substring(1),
        })
      }
    })
  }, [form, country_list])

  /**
   * 设置按钮可否提交
   */
  const onFieldsChange = (changedFields, allFields) => {
    const isValid = allFields.every(({ errors, value }) => !errors.length && !!value)
    dispatch(setCanSubmit(isValid))
    if (changedFields.find((item) => item.name[0] === 'account')) {
      // 账号框输入中，文案要提示为请输入邮箱或手机
      if (inputType) {
        setInputType('')
      }
    }
  }

  /** 失焦判断输入是邮箱还是手机号 */
  const onBlurValidateAccType = useCallback(async () => {
    const { account } = await form.validateFields(['account'])

    try {
      if (await RULES.email().validator('', account)) {
        setInputType('email')
        return { inputType: 'email' }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
    try {
      if (await RULES.mobile().validator('', account)) {
        setInputType('mobile')
        return { inputType: 'mobile' }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }, [form])

  /**
   * 表单提交
   * needInit 出现风控滑块，不需要调用init接口获取stoken
   */
  const onSubmit = useCallback(
    async (values, needInit = true) => {
      const { inputType } = await onBlurValidateAccType()
      const { areaCode, pwd, account } = values
      setLoading(true)
      try {
        const acct = inputType === 'email' ? account : '00' + areaCode + account
        const params: LoginVerifyParams = {
          acct,
          pwd,
          cas: 1,
          extinfo: JSON.stringify({ sourceSystem: SOURCE_SYSTEM[getUrlParam('app')] }),
        }
        if (!needInit) {
          params.captcha = captchaTokenRef.current
          ;(params.stoken = stokenRef.current), (params.cas = 1)
        }

        const res = needInit ? await signinInstance.verify(params) : await signinInstance.verifyWithNoInit(params)
        dispatch(
          setLogin({
            isLogined: true,
            osudb_castgc: Cookies.get('osudb_castgc'),
          })
        )
        loginSuccessCb(res?.data?.casTicket)
        dataLayerPush({ pageCategory: 'Login', event: 'login', action: 'click', loginStatus: 'success' })
      } catch (error) {
        if ([UdbResCodeEnum.CAPTCHA_CODE].includes(error.code)) {
          // 显示风控滑块
          stokenRef.current = error.extras.response.stoken
          setCaptchaVisble(true)
        } else {
          OneMessage.error(t(`udb.rescode.${error.code}`))
        }
        dataLayerPush({ pageCategory: 'Login', event: 'login', action: 'click', loginStatus: 'failure' })
      } finally {
        setLoading(false)
        captchaTokenRef.current = ''
      }
    },
    [dispatch, loginSuccessCb, onBlurValidateAccType, t]
  )

  const onEnter = useCallback(() => {
    const values = form.getFieldsValue()
    onSubmit(values)
  }, [form, onSubmit])

  /**
   * 忘记密码
   */
  const toForgotHanlder = useCallback(() => {
    historyPush('/user/forgot')
  }, [historyPush])

  const loginTypeChange = useCallback(
    (item: AccountType) => {
      form.resetFields(['pwd'])
      setAccountType(item)
    },
    [form]
  )

  /**
   * 滑块成功
   */
  const onCaptchaSuccess = useCallback(
    (token: string) => {
      captchaTokenRef.current = token
      const values = form.getFieldsValue()
      setCaptchaVisble(false)
      onSubmit(values, false)
    },
    [form, onSubmit]
  )
  const toRegister = useCallback(() => {
    historyPush('/user/register')
  }, [historyPush])

  useMount(() => {
    dispatch(getRegisterConfig())
  })

  return (
    <div className={classNames(styles.loginBox)}>
      <div className={classNames(styles.title, { [styles.titleh5]: !isPc })}>{t('login.title')}</div>
      <Form form={form} onFinish={onSubmit} onFieldsChange={onFieldsChange} className={styles.loginForm}>
        <Form.Item name="account" rules={[RULES.account()]}>
          <MobileEmailInput
            placeholder={inputType ? t(accLableMap[inputType]) : t('login.placeholder')}
            onBlurValidateAccType={onBlurValidateAccType}
            inputType={inputType}
            watchAutoFill
            onChangeAreaCode={(val) => form.setFieldsValue({ areaCode: val })}
            onCountryChange={(val) => form.setFieldsValue({ mobileCountryCode: val })}
          />
        </Form.Item>
        <Form.Item name="pwd" rules={[RULES.required()]} style={{ marginBottom: 16 }}>
          <PasswordInput autoComplete="current-password" placeholder={t('udb.label.pwd')} onPressEnter={onEnter} watchAutoFill />
        </Form.Item>
        <Form.Item name="areaCode" hidden noStyle initialValue={country_list?.[0]?.areaCode?.substring(1)} rules={[{ required: true }]} />
        <Form.Item name="countryCode" hidden noStyle initialValue={country_list?.[0]?.label} rules={[{ required: true }]} />
        <Form.Item name="mobileCountryCode" hidden noStyle initialValue={country_list?.[0]?.label} />
        <div className={styles.forgotPwd}>
          <span onClick={toForgotHanlder}>{t('login.forger')}</span>
        </div>
        <Form.Item className={styles.btnFormItem}>
          <OneButton htmlType="submit" type="primary" loading={loading} disabled={!canSubmit} color="primary" className={styles.normalButton}>
            {t('login.title')}
          </OneButton>
        </Form.Item>
      </Form>

      <AccountTypeChoose pageType="login" accountType={accountType} changeAccountType={loginTypeChange} loginSuccessCb={loginSuccessCb} hasReturnUrl={hasReturnUrl} />

      <div className={styles.registerTips}>
        {t('login.hasNoAccount')}？
        <div className={styles.registerButton} onClick={toRegister}>
          {t('login.signUpTitle')}
        </div>
      </div>
      {/* 验证滑块弹窗 */}
      {captchaVisble && <Captcha visible={captchaVisble} className={styles.captcha} lang={captchaLang} onSuccess={onCaptchaSuccess} />}
    </div>
  )
}

LoginBox.displayName = 'LoginBox'
export default LoginBox
