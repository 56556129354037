import React, { useCallback, useState, useEffect } from 'react'
import { ConfigProvider } from 'antd'
import { changeLanguage } from '@yy/one-ui'
import * as Sentry from '@sentry/react'
import { Switch, BrowserRouter } from 'react-router-dom'
import { AsyncConfiguration, SentryConfiguration } from '@/configuration'
import { OneclubConfiguration } from '@common/oneclub/configuration'
import Router from '@/routers'
import { CircleLoading, PageLoading } from '@common/oneclub/components'
import GlobalError from '@/components/GlobalError'
import { Locale } from 'antd/lib/locale-provider'
import { useSelector } from '@common/oneclub/store'
import './App.less'

window.onstorage = (e) => {
  if (e.key === 'loginSuccess') {
    window.location.reload()
  }
}

const App: React.FC = () => {
  const [antdLocale, setantdLocale] = useState<Locale>()

  const languageStore = useSelector((state) => state.language)

  const changeLanguageCallback = useCallback((lang) => {
    // antd组件加载locale文件
    import(`antd/lib/locale/${lang}.js`).then((res) => {
      const locale = res?.default || {}
      if (Object.keys(locale).length) {
        setantdLocale(locale)
      } else {
        return Promise.reject(`[antd] 语言包{${lang}}加载失败`)
      }
    })
  }, [])

  useEffect(() => {
    changeLanguageCallback(languageStore.antd)
    changeLanguage(languageStore.languageType as any)
  }, [changeLanguageCallback, languageStore])

  const isAdmin = window.location.pathname.startsWith('/admin')
  const loadingElement = isAdmin ? <PageLoading /> : <CircleLoading />

  return (
    <SentryConfiguration>
      <ConfigProvider locale={antdLocale}>
        <BrowserRouter>
          <AsyncConfiguration>
            <OneclubConfiguration loadingElement={loadingElement}>
              <GlobalError />
              <Switch>
                <React.Suspense fallback={loadingElement}>
                  <Router />
                </React.Suspense>
              </Switch>
            </OneclubConfiguration>
          </AsyncConfiguration>
        </BrowserRouter>
      </ConfigProvider>
    </SentryConfiguration>
  )
}

App.displayName = 'App'
export default Sentry.withProfiler(App)
