import { format } from 'date-fns'
import moment from 'moment'

export const formatDateFn = (date, format = 'YYYY-MM-DD HH:mm:ss', defaultString = '') => {
  try {
    return date ? moment(date).format(format) : defaultString
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('parse Date error，source date is ', date)
  }
  return ''
}

/** 格式化时间 */
export const formatTime = (timeStamp: Date | number | undefined, formatString = 'yyyy-MM-dd', defaultString = '') => {
  if (!timeStamp) {
    return defaultString
  }

  try {
    return format(timeStamp, formatString)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('parse timeStamp error，source timeStamp is ', timeStamp)
  }
  return ''
}
