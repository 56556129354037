import { LANGUAGE } from '../constants/language'
import { getUrlParam } from './getUrlParam'

/**
 * 默认多语言处理
 * @returns
 */
export function getLocalLang() {
  const browserLang = LANGUAGE?.map((it) => it.lang).includes(navigator.language) ? navigator.language : 'en'
  const urlLang = getUrlParam('lang')
  const local = urlLang || window.localStorage.i18nextLng || browserLang
  const localLang = LANGUAGE.filter((item) => item.lang === local)
  return localLang
}
