// 字符串处理工具函数

/**
 * 大写首字母
 * @param str
 * @returns
 */
export const upperFirst = (str: string) => {
  if (!str) return str
  return `${str[0].toUpperCase()}${str.slice(1)}`
}
