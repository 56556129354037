import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as Types from './type'
import Cookies from 'js-cookie'
import { AppDispatch, AppGetState } from '@common/oneclub/store'
import Api from '@common/oneclub/services/http'
import { userUrl } from '@common/oneclub/services/url'
import { SUB_APP_ID, APP_ID } from '../../constants'
import { fetchRegisterConfig, FormatConfigType, fetchCountryListAll, getTargetOmsConfig } from '@common/oneclub/services/api'
import isEmpty from 'lodash/isEmpty'

const initialState: Types.IAuthState = {
  isLogined: false,
  authResource: null,
  userInfo: {},
  isVnUser: false,
  isH5: false,
  countriesConfigMap: {},
  currentConfig: {} as FormatConfigType,
  canSubmit: false,
  countryList: [],
  applyKycConfigRes: {},
  walletList: [],
  defaultWalletCurrency: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * 设置登录信息
     * @param state
     * @param action
     * @returns
     */
    setLogin(state, action: PayloadAction<Types.IUserState>) {
      return {
        ...state,
        isLogined: action.payload.isLogined,
      }
    },
    setIsVn(state, action) {
      return {
        ...state,
        isVnUser: action.payload.isVnUser,
      }
    },
    setWalletListInfo(state, action: PayloadAction<Types.IWallInfo[]>) {
      return {
        ...state,
        walletList: action.payload,
      }
    },
    // 更新选中的钱包
    updateDefaultWallet(state, action: PayloadAction<string>) {
      const currencyCode = action.payload
      const walletList = state.walletList.map((item) => {
        return {
          ...item,
          isDefault: item.currencyCode === currencyCode, // 更新选择的钱包
        }
      })

      return {
        ...state,
        walletList,
        defaultWalletCurrency: currencyCode,
      }
    },
    // 更新钱包信息
    updateWalletListInfo(state, action: PayloadAction<Types.IWallInfo>) {
      const newWalletInfo = action.payload
      const walletList = [...state.walletList]
      const updateListInd = walletList.findIndex((item) => item.currencyCode === newWalletInfo.currencyCode)
      walletList[updateListInd] = {
        ...newWalletInfo,
        isDefault: walletList[updateListInd]?.isDefault,
      }

      return {
        ...state,
        walletList,
      }
    },
    /**
     * 设置商户信息
     * @param state
     * @param action
     * @returns
     */
    setUserInfo(state, action: PayloadAction<Types.IAuthState['userInfo']>) {
      return {
        ...state,
        userInfo: action.payload,
      }
    },
    setIsH5(state, action: PayloadAction<Types.IAuthState['isH5']>) {
      return {
        ...state,
        isH5: action.payload,
      }
    },
    setRegisterConfig(state, action) {
      return {
        ...state,
        countriesConfigMap: action.payload,
      }
    },
    setCurrentConfig(state, action) {
      return {
        ...state,
        currentConfig: action.payload,
      }
    },
    setCanSubmit(state, action) {
      return {
        ...state,
        canSubmit: action.payload,
      }
    },
    setCountryList(state, action) {
      return {
        ...state,
        countryList: action.payload,
      }
    },
    setApplyKycConfig(state, action) {
      return {
        ...state,
        applyKycConfigRes: action.payload,
      }
    },
  },
})

export const checkLogin = () => async (dispatch: AppDispatch) => {
  const osudb_uid = Cookies.get('osudb_uid') || ''
  const osudb_appid = Cookies.get('osudb_appid') || ''
  const isLogined = !!(osudb_uid && osudb_appid)
  if (!isLogined) {
    dispatch(authSlice.actions.setLogin({ isLogined: false }))
    return {
      isLogined: false,
      ticket: '',
    }
  }

  const res = await Api.udb.get<{ ticket: string; uid: string }>('/udb/lgn/cas/loginCheck.do', {
    params: {
      appid: APP_ID,
      subappid: SUB_APP_ID,
      tk: 1,
      timestamp: Date.now(),
    },
  })

  const { rescode, data } = res
  if (rescode === '0') {
    dispatch(authSlice.actions.setLogin({ isLogined: true }))
    return {
      isLogined: true,
      ticket: data.ticket,
      uid: data.uid,
    }
  }

  dispatch(authSlice.actions.setLogin({ isLogined: false }))
  return {
    isLogined: false,
    ticket: '',
    rescode,
  }
}

export const loginout = () => async (dispatch: AppDispatch) => {
  const res = await Api.udb.get<Types.UdbRes<{ ticket: string }>>('/udb/lgn/login/logout.do', {
    params: {
      subappid: process.env.SUB_APPID,
      appid: process.env.APPID,
    },
  })

  const { rescode } = res
  if (rescode === '0') {
    Cookies.remove('authorization')
    dispatch(authSlice.actions.setLogin({ isLogined: false }))
  }

  return res
}

export const setDeviceType = (isH5: boolean) => async (dispatch: AppDispatch) => {
  dispatch(authSlice.actions.setIsH5(isH5))
}

export const getWalletInfo = () => async (dispatch: AppDispatch, getStates: AppGetState) => {
  const { data } = await Api.admin.post<Types.walletInfoList>('/api/wallet/admin/info/list')
  const authStore = getStates().auth
  const currencyCode = authStore.defaultWalletCurrency || authStore.userInfo?.currencyCode
  const walletList = data?.list || []

  const finalWalletList = walletList.map((item) => {
    return {
      ...item,
      isDefault: item.currencyCode === currencyCode, // 用户注册国家为默认币种
    }
  })

  dispatch(authSlice.actions.setWalletListInfo(finalWalletList))
  return finalWalletList
}

export const getBaseUserInfo = (shouldUpdateWallet?: boolean) => async (dispatch: AppDispatch) => {
  const { data: userInfo } = await Api.normal.post<Types.UserInfo>('/api/user/info/get-basic')

  const { oneShipSettlementMethod, oneWarehouseSettlementMethod, countryCurrencyCode } = userInfo || {}

  // 其中一个预付，即为预付商家
  const isPrepaid = oneShipSettlementMethod === 'prepaid' || oneWarehouseSettlementMethod === 'prepaid'

  dispatch(
    authSlice.actions.setUserInfo({
      ...userInfo,
      isPrepaid,
      currencyCode: countryCurrencyCode,
    })
  )

  // 展示钱包
  if (userInfo.displayWallet && shouldUpdateWallet) {
    dispatch(getWalletInfo())
  }

  return userInfo
}

// UDB注册初始化
export const udbInviteInit = async (params: Types.InviteInit): Promise<any> => {
  const rst = await Api.udb.get(userUrl.enterpriseInit, {
    params: {
      ...params,
      subappid: process.env.SUB_APPID,
      appid: process.env.APPID,
    },
  })

  const { rescode } = rst
  if (rescode === '0') {
    return Promise.resolve(rst)
  }
  return Promise.reject(rst)
}
/** 企业注册 */
export const udbInvite = async (params: Types.EmailInvite): Promise<Types.UdbRes> => {
  const rst = await Api.udb.get(userUrl.enterpriseRegister, { params })
  const { rescode } = rst
  if (rescode === '0') {
    return rst
  }
  return Promise.reject(rst)
}

export const getRegisterConfig = () => async (dispatch: AppDispatch) => {
  const res = await fetchRegisterConfig()

  const methods = res.data.methods
  // 所有国家对应的注册配置
  const countriesConfigMap = methods.reduce((acc, item) => {
    const key = item.countryCode
    const registerMethods = item.registerMethods
    const normalRegisterMethods = registerMethods.filter((item) => ['email', 'tel'].includes(item.registerSource))
    const thirdRegisterMethods = registerMethods.filter((item) => ['facebook', 'google'].includes(item.registerSource))
    const registerMethodsMap = registerMethods.reduce((obj, el) => {
      const key = el.registerSource
      obj[key] = el
      return obj
    }, {})
    acc[key] = {
      normalRegisterMethods,
      thirdRegisterMethods,
      ...registerMethodsMap,
    }
    return acc
  }, {}) as Record<string, FormatConfigType>

  dispatch(authSlice.actions.setRegisterConfig(countriesConfigMap))
  return countriesConfigMap
}

export const getCountryListAll = () => async (dispatch: AppDispatch) => {
  const res = await fetchCountryListAll()
  const countryList = res?.data?.countries || []
  dispatch(
    authSlice.actions.setCountryList(
      countryList.map((item) => ({
        ...item.localeNameMap,
        countryCode: item.countryCode,
      }))
    )
  )
}

/** 获取商家入驻字段配置 */
export const getApplyKycConfigStore = () => async (dispatch: AppDispatch, getStates: AppGetState) => {
  const { auth } = getStates()
  if (isEmpty(auth.applyKycConfigRes)) {
    const data = await getTargetOmsConfig('kycServiceDefaultValues')
    dispatch(setApplyKycConfig(data || {}))
    return data
  }
  return auth.applyKycConfigRes
}

export const { setLogin, setUserInfo, setIsVn, setIsH5, setRegisterConfig, setCurrentConfig, setCanSubmit, setApplyKycConfig, updateWalletListInfo, updateDefaultWallet } =
  authSlice.actions

export default authSlice.reducer
