import React, { useEffect, useMemo } from 'react'
import { Dropdown, Menu, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useAdaptLanguage } from '@common/oneclub/hooks'
import { getUrlParam } from '@common/oneclub/utils'
import { useSelector, useDispatch } from '@common/oneclub/store'
import { changeLanguage } from '@common/oneclub/store/languageSlice'
import { setIsVn } from '@common/oneclub/store/authSlice'

import { Language as LanageIcon } from '@yy/one-icon'
import styles from './index.module.less'
import { ShowI18nKeyButton } from '@common/oneclub/components/ShowI18nKeyButton'
import { noLang } from '@common/oneclub/i18n'

interface SelectorProps {
  className?: string
}

export const LanageSelector: React.FC<SelectorProps> = ({ className }) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const languageStore = useSelector((state) => state.language)
  const { LANGUAGE_LIST } = useAdaptLanguage()
  const app = getUrlParam('app')

  const TARGET_LANGUAGE_LIST = LANGUAGE_LIST.map((item) => (item.lang === 'zh-TW' && app === 'ow' ? { ...item, label: '中文繁體' } : item))

  useEffect(() => {
    const hasCurLang = TARGET_LANGUAGE_LIST.find((item) => item.lang === languageStore.lang)
    if (languageStore.lang === noLang.lang) return
    if (!hasCurLang) {
      dispatch(changeLanguage(TARGET_LANGUAGE_LIST?.[0]))
      i18n.changeLanguage(TARGET_LANGUAGE_LIST?.[0].lang)
    }
  }, [dispatch, languageStore, TARGET_LANGUAGE_LIST, i18n])

  const lanageMenus = useMemo(() => {
    return (
      <Menu>
        {TARGET_LANGUAGE_LIST.filter((item) => !item.hidden).map((option) => (
          <Menu.Item
            key={option.lang}
            onClick={() => {
              dispatch(changeLanguage(option))
              dispatch(setIsVn({ isVnUser: option.lang === 'vi' }))
              i18n.changeLanguage(option.lang)
            }}
          >
            {option.label}
          </Menu.Item>
        ))}
      </Menu>
    )
  }, [dispatch, i18n, TARGET_LANGUAGE_LIST])

  return (
    <Row className={className}>
      <ShowI18nKeyButton />
      <Dropdown trigger={['click']} className={styles.lanageSelect} overlay={lanageMenus}>
        <div>
          {/* TODO 待优化 */}
          <LanageIcon /> {languageStore.lang === 'zh-TW' && app === 'ow' ? '中文繁體' : languageStore.label}
        </div>
      </Dropdown>
    </Row>
  )
}

LanageSelector.displayName = 'LanageSelector'
