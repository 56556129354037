import { useMemo } from 'react'
import { useLocation } from 'react-router'

export function useUrlSearch<T extends Record<string, any>>() {
  const location = useLocation()
  const params = useMemo(() => {
    try {
      const search = location.search.indexOf('?') > -1 ? location.search.slice(1) : location.search
      const params = {}
      if (!search) {
        return params
      }
      search.split('&').forEach((item) => {
        const [key, vallue] = item.split('=')
        params[key] = vallue
      })
      return params
    } catch (err) {
      throw new Error(err)
    }
  }, [location.search])

  return params as T
}
