import { Modal } from 'antd'
import { OneModal } from '@yy/one-ui'
import store from '@common/oneclub/store'
import i18n from '@common/oneclub/i18n'
import Cookies from 'js-cookie'

/**
 * 检查超级管理登入多个商家
 */
function handleSuperManagerLoginTip() {
  const state = store.getState()
  const adminTicket = state.user.adminTicket
  const isSuperManage = state.user.info?.accountType === 'OP'
  const storageAdminTicket = Cookies.get('authorization')

  if (isSuperManage && adminTicket && adminTicket !== storageAdminTicket) {
    Modal.destroyAll()
    OneModal.confirm({
      centered: true,
      maskClosable: false,
      closable: false,
      title: i18n.t('user.super.manage.loginTitle'),
      okText: i18n.t('user.super.manage.changeSeller'),
      cancelButtonProps: {
        style: {
          display: 'none',
        },
      },
      onOk: () => {
        window.location.reload()
      },
    })
  }
}

/**
 * 检查超级管理员有没有同时登入多个商家
 */
export function multiAccountLoginCheck() {
  window.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      handleSuperManagerLoginTip()
    }
  })
}
