import React, { useEffect, useMemo, useState } from 'react'
import { OnePopover, OneInput } from '@yy/one-ui'
import { ChevronDown, Search, ChevronUp } from '@yy/one-icon'
import { useTranslation } from 'react-i18next'
import { useOneclubConfigs } from '@common/oneclub/configuration'
import classNames from 'classnames'
import empty from './empty.svg'

import styles from './index.module.less'

const defaultValueFormat = (value: string) => value

type ICountrySelectPlusProps = {
  onSelect?: (value?: string) => void
  onChange?: (value?: string) => void
  onCountryChange?: (value?: string) => void
  value?: string
  disabled?: boolean
  className?: string
  valueFormat?: (string: string) => string
  countryCode?: string
}

const toLower = (str) => String(str).toLowerCase()

const CountrySelectPlus: React.FC<ICountrySelectPlusProps> = (props) => {
  const { value, onSelect, onChange, disabled, className, valueFormat = defaultValueFormat, countryCode, onCountryChange } = props
  const { supported_register_country_list } = useOneclubConfigs()
  const [keyword, setKeyword] = useState<string>()
  const [visible, setVisible] = useState(false)
  const { t } = useTranslation()

  const filterList = useMemo(() => {
    if (!keyword) return supported_register_country_list

    return supported_register_country_list?.filter((item) => {
      const { label, code } = item
      const k = toLower(keyword)
      return toLower(label).includes(k) || toLower(code).includes(k)
    })
  }, [supported_register_country_list, keyword])

  const searchPanel = useMemo(() => {
    return (
      <div className={styles['country-list-panel']}>
        <div className={styles['input-wrap']}>
          <OneInput value={keyword} allowClear onChange={(e) => setKeyword(e.target.value)} placeholder={t('oneclub.mobile.search.tips')} suffix={<Search fontSize={18} />} />
        </div>
        {filterList?.length > 0 ? (
          <div className={styles['country-list']}>
            {filterList.map((item, index) => {
              // make the area code unique, because there lots of it equal to value "+1"
              const uniqueCode = `${countryCode ? `${item.label}-` : ''}${valueFormat?.(item.code)}`
              const uniqueSelectedCode = `${countryCode ? `${countryCode}-` : ''}${valueFormat?.(value)}`
              const checked = uniqueSelectedCode === uniqueCode

              return (
                <div
                  key={`${uniqueCode}_${index}`}
                  className={classNames(styles['country-item'], {
                    [styles.selected]: checked,
                  })}
                  onClick={() => {
                    onSelect?.(valueFormat?.(item.code))
                    onChange?.(valueFormat?.(item.code))
                    onCountryChange?.(item.label)
                    setVisible(false)
                  }}
                >
                  <span className={styles.name}>{item.label}</span>
                  <span className={styles.code}>{item.code}</span>
                </div>
              )
            })}
          </div>
        ) : (
          <div className={styles.empty}>
            <img src={empty} />
            <p>{t('oneclub.empty.text')}</p>
          </div>
        )}
      </div>
    )
  }, [countryCode, filterList, keyword, onChange, onCountryChange, onSelect, t, value, valueFormat])

  const visibleChange = (visible) => {
    setVisible(visible)
  }

  useEffect(() => {
    if (!visible) {
      setKeyword('')
    }
  }, [visible])

  return (
    <OnePopover
      overlayClassName={styles.popover}
      content={searchPanel}
      trigger={disabled ? '' : 'hover'}
      placement="bottomRight"
      autoAdjustOverflow={false}
      visible={disabled ? false : visible}
      onVisibleChange={visibleChange}
    >
      <div
        className={classNames(
          className,
          styles.label,
          {
            ['active']: visible,
          },
          {
            [styles.disabled]: disabled,
          }
        )}
      >
        {value ? `${value.startsWith('+') ? value : `+${value}`}` : ''}
        {visible ? <ChevronUp origin /> : <ChevronDown origin />}
      </div>
    </OnePopover>
  )
}

export default CountrySelectPlus
