import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Dropdown } from 'antd'
import { ChevronDown, ChevronUp, Profile as ProfileIcon } from '@yy/one-icon'
import { OneButton } from '@yy/one-ui'
import { useSelector } from '@common/oneclub/store'
import { ProfileMenu } from '../ProfileMenu'
import { LanguageMenu } from '../LanguageMenu'
import styles from './index.module.less'

export const Profile: React.FC = () => {
  const user = useSelector((state) => state.user.info)
  const [visible, setVisible] = useState(false)
  const [overlayType, setOverlayType] = useState<'lang' | 'default'>('default')

  const openOverlay = useCallback(
    (type: typeof overlayType) => {
      setOverlayType(type)
      setVisible(true)
    },
    [setVisible, setOverlayType]
  )
  const overlay = useMemo(() => {
    switch (overlayType) {
      case 'lang': {
        return <LanguageMenu onBackClick={() => openOverlay('default')} onClick={() => setVisible(false)} />
      }

      case 'default':
      default:
        return <ProfileMenu onLangClick={() => openOverlay('lang')} onClick={() => setVisible(false)} />
    }
  }, [overlayType, openOverlay])

  useEffect(() => {
    if (!Boolean(visible)) {
      // 异步设置，否则会出现抖动
      setTimeout(() => setOverlayType('default'), 500)
    }
  }, [visible])

  return (
    <div className={styles.userLogin}>
      <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay} trigger={['click']}>
        <OneButton type="text" className={styles.btn} id="user-dropdown">
          <ProfileIcon origin className={styles.iconUser} />
          {user ? <p className={styles.user}>{user?.sellerName}</p> : null}
          {visible ? <ChevronUp style={{ fontSize: 18 }} /> : <ChevronDown style={{ fontSize: 18 }} />}
        </OneButton>
      </Dropdown>
    </div>
  )
}

Profile.displayName = 'Profile'
