import { useEffect } from 'react'
import { useDispatch } from '@common/oneclub/store'
import { useSelector } from '@common/oneclub/store'
import { getCountryByIp } from '@common/oneclub/services/api'
import { setIsVn } from '@common/oneclub/store/authSlice'

export default function useCheckIsVn() {
  const dispatch = useDispatch()
  const lang = useSelector((state: any) => state.language.lang)
  // 设置是否是越南用户
  useEffect(() => {
    getCountryByIp().then(({ data }) => {
      dispatch(
        setIsVn({
          isVnUser: data.countryCode === 'VN' || lang === 'vi',
        })
      )
    })
  }, [dispatch, lang])
}
