import { useCallback, useState } from 'react'
import { getDeviceToken, getUrlParam } from '@common/oneclub/utils'
import { useSelector, useDispatch } from '@common/oneclub/store'
import { delParam, getQuery } from '@common/oneclub/utils'
import { SCENE } from '@common/oneclub/constants'
import _ from 'lodash'
import { useHistory } from 'react-router'
import { loginout as logout, checkLogin } from '@common/oneclub/store/authSlice'

export function useUserLogin() {
  const app = getUrlParam('app')
  const service = getUrlParam('service')
  const history = useHistory()
  const dispatch = useDispatch()
  const scene = getUrlParam('scene')
  const [verifyLoading, setVerifyLoading] = useState(false)
  const [dfpToken, setDfpToken] = useState('')
  const lang = useSelector((state) => state.language.lang)
  const adminTicket = useSelector((state) => state.user.adminTicket)

  const loginSuccessCb = useCallback(
    (ticket = '', curApp = '', redirectMethod: 'location' | 'open' = 'location', extinfo?: Record<string, any>) => {
      const queryObj = _.omit(getQuery(), ['service', 'app', 'channel', 'keyword'])
      const serviceQueryObj = getQuery(decodeURIComponent(service))
      let newQueryObj = {}
      newQueryObj = { ...(adminTicket ? { adminTicket } : {}), ...(extinfo || {}), ...queryObj, ...serviceQueryObj, lang, ticket }
      const appStr = app || curApp
      const queryStr = !_.isEmpty(newQueryObj)
        ? Object.keys(newQueryObj).reduce((pre, cur, idx) => {
            return idx === 0 ? `?${cur}=${newQueryObj[cur]}` : `${pre}&${cur}=${newQueryObj[cur]}`
          }, '')
        : ''
      let href = ''

      // 其他场景，以service为准
      if (service) {
        const urlArr = decodeURIComponent(service).split('?')
        const hostStr = urlArr?.[0]
        // 跳转到OC不用带上参数
        href = appStr !== 'oc' ? `${hostStr}${queryStr}` : `${hostStr}`
      } else {
        switch (appStr) {
          case 'ow': {
            href = `${process.env.OW_ADMIN}${queryStr}`
            break
          }
          case 'osvn': {
            href = `${process.env.OS_ADMIN_VN}${queryStr}`
            break
          }
          case 'oscn': {
            href = `${process.env.OS_ADMIN_CN}${queryStr}`
            break
          }
          case 'osth':
          case 'ossg':
          case 'osmy':
          case 'oshk':
          case 'ostw':
          case 'osid':
          case 'osus':
          case 'osau':
          case 'osjp':
          case 'os': {
            href = `${process.env.OS_ADMIN_IO}/dashboard${queryStr}`
            break
          }
          case 'oc':
            href = '/middle'
            break
        }
      }
      if (redirectMethod === 'location') {
        if (href.startsWith('/')) {
          history.push(href)
          return
        }
        window.location.href = href
      } else if (redirectMethod === 'open') window.open(href)
    },
    [service, lang, app, history, adminTicket]
  )

  const loginCheck = useCallback(async () => {
    if (scene === SCENE['logout']) {
      setVerifyLoading(true)
      await dispatch(logout())
        .then(() => {
          const path = delParam('logout')
          history.replace(path)
        })
        .finally(() => {
          setVerifyLoading(false)
        })
    } else {
      setVerifyLoading(true)
      await dispatch(checkLogin())
        .then(({ isLogined, ticket }) => {
          isLogined && loginSuccessCb(ticket)
        })
        .finally(() => {
          setVerifyLoading(false)
        })
    }
  }, [dispatch, loginSuccessCb, scene, history])

  const getDfpToken = useCallback(() => {
    getDeviceToken().then((token) => {
      setDfpToken(token)
    })
  }, [])

  return {
    loginSuccessCb,
    loginCheck,
    verifyLoading,
    getDfpToken,
    dfpToken,
  }
}
