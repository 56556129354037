import { APP_CODE } from '@common/oneclub/constants'

interface Options {
  env: 'dev' | 'test' | 'pre' | 'pro' // env：环境信息
  appCode: string // appCode：接入的应用ID，联系风控@俞卓超提供（appCode以业务线划分）
}

const ENV_MAP: Record<string, 'dev' | 'test' | 'pre' | 'pro'> = {
  develop: 'dev',
  test: 'test',
  preview: 'pre',
  product: 'pro',
}

const options: Options = {
  env: ENV_MAP[process.env.APP_ENV],
  appCode: APP_CODE,
}

/**
 * 获取浏览器指纹token
 * @returns
 */
export const getDeviceToken = async () => {
  const w = window as any
  const df = w?.DeviceFingerprint(options)
  const token = await df.getTokenAsync()
  return token
}
