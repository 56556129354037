import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from '@common/oneclub/store'
import { changeLanguage } from '@common/oneclub/store/languageSlice'
import { useAdaptLanguage } from '@common/oneclub/hooks'
import { canShowI18nKey, noLang } from '@common/oneclub/i18n'

const style = {
  marginRight: '10px',
  padding: '2px 6px',
  background: '#fff',
  borderRadius: '4px',
  border: '1px solid #ccc',
}
let lastLang = null

/**
 * 展示i18n key，只在测试和预发布环境展示
 */
export const ShowI18nKeyButton = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const { LANGUAGE_LIST } = useAdaptLanguage()
  const lang = useSelector((s) => s.language.lang)
  if (!canShowI18nKey) return null
  const lanageChange = async () => {
    const langObj = lang === noLang.lang ? lastLang : noLang
    dispatch(changeLanguage(langObj))
    i18n.changeLanguage(langObj.lang)
  }

  if (lang !== noLang.lang) {
    lastLang = LANGUAGE_LIST.find((l) => l.lang === lang)
  }
  const text = lang === noLang.lang ? '不显示多语言key' : '显示多语言key'
  return (
    <button onClick={lanageChange} style={style}>
      {text}
    </button>
  )
}
