import React, { useEffect } from 'react'
import { debounce } from 'lodash'
import { unmountComponentAtNode, render } from 'react-dom'
import c from 'classnames'
import IconCloseCircle from '@common/oneclub/assets/icons/iconCloseCircle.svg'
import IconCheckCircle from '@common/oneclub/assets/icons/iconCheckCircle.svg'
import styles from './index.module.less'
import './index.css'
const DEFAULT_CLOSE_TIME = 3000

interface MessageProps {
  type: 'error' | 'warning' | 'success' | 'info'
}
const iconMap = {
  success: IconCheckCircle,
  error: IconCloseCircle,
}
const Message = (props) => {
  const { type = 'error', onClose, messageProp } = props
  const content = messageProp?.content || messageProp
  useEffect(() => {
    const t = setTimeout(onClose, DEFAULT_CLOSE_TIME)
    return () => {
      t && clearTimeout(t)
    }
  }, [onClose])

  return (
    <div className={c(styles.messageItem, { [styles.error]: type === 'error' }, { [styles.success]: type === 'success' })}>
      <img src={iconMap[type]} />
      <span className={styles.content}>{content}</span>
    </div>
  )
}
const getContainer = () => {
  const container = document.querySelector('#messageWrapper')
  if (!container) {
    const _container = document.createElement('div')
    _container.id = 'messageWrapper'
    _container.className = `wrapper`

    document.body.appendChild(_container)
    return _container
  }
  return container
}

const _message = (type: MessageProps['type']) => (props) => {
  const container = getContainer()
  const _dom = document.createElement('div')

  container.appendChild(_dom)

  const hanldeClose = () => {
    _dom.classList.add('dispear')
    setTimeout(() => {
      unmountComponentAtNode(_dom)
      container.removeChild(_dom)
    }, 800)
  }

  render(<Message messageProp={props} type={type} onClose={debounce(hanldeClose, 500)} />, _dom)
}

const error = _message('error')
const warning = _message('warning')
const success = _message('success')
const info = _message('info')

export default {
  error,
  warning,
  success,
  info,
}
