import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export const initSentry = (SENTRY_DSN: string = process.env.SENTRY_DSN) => {
  if (!(typeof SENTRY_DSN === 'string' && SENTRY_DSN.length > 0)) {
    return
  }

  if (
    process.env.SENTRY_DEBUG !== 'true' ||
    (process.env.NODE_ENV === 'production' && process.env.BUILD_ENV === 'CI' && process.env.APP_ENV && process.env.SENTRY_DSN && process.env.GIT_COMMIT_HASH)
  ) {
    try {
      type SentryHijackXMLHttpRequest = XMLHttpRequest & {
        __sentry_headers__?: Record<string, string>
      }

      const noConflitSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader
      XMLHttpRequest.prototype.setRequestHeader = function (this: SentryHijackXMLHttpRequest, name, value) {
        const response = noConflitSetRequestHeader.call(this, name, value)
        if (typeof this.__sentry_headers__ !== 'object') {
          this.__sentry_headers__ = {}
        }

        this.__sentry_headers__[name] = value
        return response
      }

      Sentry.init({
        /** 告诉 SDK 上报错误/异常的地方 */
        dsn: SENTRY_DSN || '',
        /** 开启 DEBUG 模式 */
        debug: process.env.SENTRY_DEBUG === 'true' && process.env.APP_ENV !== 'product',
        /** 代码的版本号 Release 版本号, 可以确定当前的错误/异常属于哪一个发布的版本 */
        release: `${process.env.APP_ENV}@${process.env.GIT_COMMIT_HASH}`,
        /** 上报当前错误/异常发生的环境 */
        environment: process.env.APP_ENV,
        autoSessionTracking: false,
        /** 需要过滤的错误类型，具体可以参考 [Filtering and Sampling Events](https://docs.sentry.io/platforms/javascript/configuration/filtering/) */
        integrations: [new Integrations.BrowserTracing()],
        /** 过滤错误 */
        ignoreErrors: ['ReportingObserver [deprecation]', 'Uncaught (in promise)'],
        /** 黑名单 */
        blacklistUrls: [/^localhost/, /extensions\//i, /^chrome:\/\//i, /^chrome-extensions:\/\//i, /^file:\/\//i],
        /** 随机上报事件的概率 */
        tracesSampleRate: 1,
        beforeBreadcrumb(breadcrumb, hint) {
          switch (breadcrumb.category) {
            // 过滤 CONSOLE LOG WARN
            case 'console': {
              if (hint.level !== 'error') {
                return null
              }

              break
            }

            // XHR 添加数据
            case 'xhr': {
              // 设置 traceid
              const xhr: SentryHijackXMLHttpRequest = hint.xhr
              const { traceparent: traceId } = xhr.__sentry_headers__ || {}

              if (traceId) {
                breadcrumb.data = Object.assign(breadcrumb.data || {}, {
                  traceId: traceId.split('-')[1],
                })
              }

              break
            }

            // 用户操作
            case 'ui.click': {
              const { event } = hint
              const target: HTMLElement = event.target
              const content = target.innerText || ''
              if (content) {
                breadcrumb.data = Object.assign(breadcrumb.data || {}, {
                  content: content.slice(0, 6),
                })
              }

              break
            }
          }

          return breadcrumb
        },
      })
    } catch (exception) {
      // eslint-disable-next-line no-console
      console?.error(exception)
    }
  }
}
