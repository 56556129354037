import { useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguageType, LANGUAGE_MAP } from '@common/oneclub/constants/language'
import { useSelector, useDispatch } from '@common/oneclub/store'
import { setLanguage } from '@common/oneclub/store/languageSlice'

const config = {
  countryMap: {
    CN: ['zh-CN', 'en'],
    HK: ['zh-HK', 'en'],
    TW: ['zh-TW', 'en'],
    MY: ['en', 'zh-CN'],
    SG: ['en', 'zh-CN'],
    VN: ['vi', 'en'],
    TH: ['th', 'en'],
    ID: ['id', 'en'],
    JP: ['ja', 'en'],
  },
  other: ['en', 'zh-CN', 'zh-HK', 'zh-TW', 'ja'],
}

const countryLangMap = {
  CN: 'zh-CN',
  HK: 'zh-HK',
  TW: 'zh-TW',
  VN: 'vi',
  TH: 'th',
  ID: 'id',
  JP: 'ja',
}

const countrySet = new Set(Object.keys(config.countryMap))

/**
 * 获取语种列表
 * 这里不能使用oneship-config是因为目前oneship-config是给os那边使用的，并不能应用于所有系统，因此先写死，如果需要读取跟os一致的话，可以跟产品确定
 */
export const getLanguageList = (code?: string) => {
  const upperCode = code?.toUpperCase()

  // 作弊功能
  if (localStorage.getItem('os-show-me-language') || code === 'ALL') {
    return config.other.map((code) => LANGUAGE_MAP[code]).filter(Boolean)
  }

  if (!upperCode) {
    return []
  }

  if (countrySet.has(upperCode)) {
    const list = config.countryMap[upperCode].map((code) => LANGUAGE_MAP[code])
    return list
      .map((item) => {
        if (item?.label?.includes('中文繁體')) {
          return {
            ...item,
            label: '中文繁體',
          }
        }

        return item
      })
      .filter(Boolean)
  }

  return config.other.map((code) => LANGUAGE_MAP[code]).filter(Boolean)
}

const showMeLanguage = () => {
  localStorage.setItem('os-show-me-language', '1')
  location.reload()
}

;(window as unknown as any).showMeLanguage = showMeLanguage

export const useLanguage = () => {
  const languageList = useSelector((state) => state.user?.languageList)
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  // 国家Code / lang object
  const handleChangeLanguage = useCallback(
    async (inputLang: LanguageType | string) => {
      let lang: LanguageType
      if (typeof inputLang === 'string') {
        const countryCode = inputLang?.toUpperCase()
        const matchLang = countryLangMap[countryCode]
        const languageList = getLanguageList(countryCode)
        lang = languageList.find((item) => item.lang === matchLang)
      } else {
        lang = inputLang
      }

      // 兜底，匹配不到
      lang = lang || LANGUAGE_MAP.en

      await dispatch(setLanguage(lang))
      i18n.changeLanguage(lang.lang)
    },
    [dispatch, i18n]
  )

  useEffect(() => {
    const run = async () => {
      if (!languageList.length) return

      const localLang = languageList.find((item) => item.lang === window.localStorage.i18nextLng)
      const lang = localLang || languageList[0]

      // 只可以 fixed 一些选择了原本不支持的语言的用户
      if (lang && window.localStorage.i18nextLng !== lang.lang) {
        handleChangeLanguage(lang)
      }
    }

    run()
  }, [dispatch, handleChangeLanguage, i18n, languageList])

  const localLanguageLang = useMemo(() => {
    const selfLanguageInfo = languageList.find((item) => item.lang === window.localStorage.i18nextLng)

    const defaultLang = selfLanguageInfo || LANGUAGE_MAP.en

    return {
      defaultLang,
      lang: defaultLang.lang,
      languageType: defaultLang.languageType,
    }
  }, [languageList])

  const handleFixedLocalLanguage = useCallback(
    (countryCode: string) => {
      handleChangeLanguage(countryCode)
    },
    [handleChangeLanguage]
  )

  return {
    languageList,
    localLanguageLang,
    handleChangeLanguage,
    handleFixedLocalLanguage,
  }
}
