//404页面
import React from 'react'
import { Helmet } from 'react-helmet'
import css from './index.module.less'
import { Typography } from 'antd'
import ocIcon from '@common/oneclub/assets/imgs/logo-oc.svg'
import notFount from '@common/oneclub/assets/imgs/notFount.png'
import { OneButton } from '@yy/one-ui'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

export const NotFound: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <>
      <Helmet>
        <title>{t('frame.page404Title')}</title>
      </Helmet>
      <div className={css.root}>
        <img alt="title" className={css.img} src={ocIcon} />
        <div className={css.content}>
          <Typography.Title level={1}>{t('frame.page404Title')}</Typography.Title>
          <Typography.Paragraph>{t('frame.page404Desc')}</Typography.Paragraph>
          <img alt="title" className={css.notFountImg} src={notFount} />
          <OneButton
            onClick={() => {
              history.push('/')
            }}
          >
            {t('frame.page404Back')}
          </OneButton>
        </div>
      </div>
    </>
  )
}
NotFound.displayName = 'NotFound'
