import { useCallback } from 'react'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { configureStore, Action } from '@reduxjs/toolkit'
import rootReducer, { RootState } from '@common/oneclub/store/rootReducer'
import { useSelector as useReduxSelector, TypedUseSelectorHook, useDispatch as useReduxDispatch } from 'react-redux'
import { FormInstance } from 'antd-mobile/es/components/form/form'
import UDB from '@yy/oneship-udb'

// 全局store
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

// 最原始的dispatch
export type AppDispatch = typeof store.dispatch
// 最原始的getState
export type AppGetState = typeof store.getState

// It is important to use Action as last type argument, does not work with any.
export type AppThunkDispatch = ThunkDispatch<RootState, undefined, Action>

// 给dispatch加上type
export const useDispatch = (form?: FormInstance): AppThunkDispatch => {
  const thunkDispatch = useReduxDispatch<AppThunkDispatch>()
  const dispatch = useCallback(
    (thunkAction) => {
      if (typeof thunkAction === 'object' && typeof thunkAction?.type === 'string') {
        return thunkDispatch(thunkAction)
      }

      if (typeof thunkAction === 'function') {
        return thunkDispatch((_, getState) => {
          return thunkAction(dispatch, getState, form)
        })
      }
    },
    [form, thunkDispatch]
  )

  return dispatch
}

// 给useSelector加上rootState的默认type
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector

// 同步action的type
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>

// 异步action的type
export type AsyncAppThunk<ReturnType = void> = ThunkAction<Promise<ReturnType>, RootState, unknown, Action<string>>

/**
 * 让udb里面语言保持同步
 */
store.subscribe(() => {
  UDB.setBaseReqParams({ lang: store.getState().language.languageType })
})

export default store
