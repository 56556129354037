import { Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

export interface Iprops {
  size?: number
}
const CircleLoading: React.FC<Iprops> = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: props?.size || 20 }} spin />
  return <Spin indicator={antIcon} />
}

CircleLoading.displayName = 'CircleLoading'
export default CircleLoading
