import { withAsync } from '@/components/Async'
import type { RouteConfig } from 'react-router-config'
import Blank from '../../layouts/Blank'
const routes: RouteConfig[] = [
  {
    path: '/',
    component: Blank,
    routes: [
      {
        name: 'middle',
        path: '/middle',
        component: withAsync(() => import(/* webpackChunkName: "middle" */ '@/containers/middle')),
        exact: true,
      },
      {
        name: 'appeal',
        path: '/appeal',
        component: withAsync(() => import(/* webpackChunkName: "Appeal" */ '@/containers/Appeal')),
        exact: true,
      },
      {
        path: '/privacy',
        component: withAsync(() => import(/* webpackChunkName: "User/Privacy" */ '@common/oneclub/containers/Privacy')),
        exact: true,
      },
      {
        path: '/cookie',
        component: withAsync(() => import(/* webpackChunkName: "User/Cookie" */ '@common/oneclub/containers/Cookie')),
        exact: true,
      },
      {
        path: '/terms',
        component: withAsync(() => import(/* webpackChunkName: "User/Terms" */ '@/containers/Terms')),
        exact: true,
      },
      {
        path: '/terms/:app',
        component: withAsync(() => import(/* webpackChunkName: "User/Terms" */ '@common/oneclub/bizComponents/AppTermsPage')),
        exact: true,
      },
      {
        path: '/unsubscribe/balanceMonitor',
        component: withAsync(() => import('@/containers/admin/Wallet/UnSubscribe')),
        exact: true,
      },
    ],
  },
]

export default routes
