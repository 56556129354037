import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './index.module.less'

const Empty: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.root}>
      <h4>{t('message.noNewMessageTitle')}</h4>
      <p>{t('message.noNewMessageContent')}</p>
    </div>
  )
}

Empty.displayName = 'Empty'
export default Empty
