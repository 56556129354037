import { useHistory } from 'react-router-dom'
import { useSelector } from '@common/oneclub/store'
import { getQueryWithoutDecode, stringifyQuery } from '@common/oneclub/utils'

function useRedirect() {
  const history = useHistory()
  const lang = useSelector((state: any) => state.language.lang)
  interface ParamsProps {
    // 系统内跳转的路径
    r_path?: string
    [x: string]: string
  }
  const historyPush = (url: string, params?: ParamsProps) => {
    // 合并参数
    const query = {
      ...getQueryWithoutDecode(),
      ...(params || {}),
      lang,
    }

    history.push(stringifyQuery(url, query))
  }

  return {
    historyPush,
  }
}

export default useRedirect
