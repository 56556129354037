import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '@common/oneclub/store'
import { LanguageType } from '@common/oneclub/constants'
import { getLocalLang } from '@common/oneclub/utils'
const localLang = getLocalLang()

const initialState: LanguageType = {
  ...localLang?.[0],
}

const languageSlice = createSlice({
  name: 'language',
  initialState: initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<LanguageType>): LanguageType {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const changeLanguage = (lang: LanguageType) => (dispatch: AppDispatch) => {
  dispatch(languageSlice.actions.setLanguage(lang))
  window.localStorage.setItem('oc-lang', JSON.stringify(lang))
}

export const { setLanguage } = languageSlice.actions
export default languageSlice.reducer
