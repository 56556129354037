import { Divider, List, Menu } from 'antd'
import { uniqBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import { OneButton } from '@yy/one-ui'
import CircleLoading from '@/components/CircleLoading'
import { queryUnRead } from '@common/oneclub/hooks/useSocket'
import { useDispatch, useSelector } from '@common/oneclub/store'
import { queryDownloadList, queryMessageList, readMessage } from '@common/oneclub/store/notificationSlice'
import type { DownloadItemType, MessageItemType } from '@common/oneclub/store/notificationSlice/type'
import Empty from './Empty'
import ListDownloadItem from './ListDownloadItem'
import ListMessageItem from './ListMessageItem'
import styles from './styles/index.module.less'
import type * as Types from './type'

const MessageCenter: React.FC<Types.ImessageType> = (props) => {
  const { type, count, onClose } = props
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const notification = useSelector((store) => store.notification)

  const source = useMemo(() => {
    if (type === 'notify') {
      return notification.messageInfo
    }
    return notification.downloadInfo
  }, [notification.downloadInfo, notification.messageInfo, type])

  const loadingRef = useRef(false)
  const handleLoadMore = useCallback(
    async (page: number) => {
      if (loadingRef.current) return
      // 执行fetch的操作
      // 加载下一页
      loadingRef.current = true
      if (type === 'notify') {
        await dispatch(queryMessageList({ pageNum: page, pageSize: 20 }))
        loadingRef.current = false
      }

      if (type === 'download') {
        await dispatch(queryDownloadList({ pageNum: page, pageSize: 20 }))
        loadingRef.current = false
      }
    },
    [dispatch, type]
  )

  const loading = useMemo(() => {
    return (
      <div className={styles.loading} key={0}>
        <CircleLoading size={24} />
      </div>
    )
  }, [])

  const listHasMore = useMemo(() => {
    if (loadingRef.current) return false
    return source.hasNext
  }, [source.hasNext])

  const handleMessageItem = useCallback(
    (dataList, fromSocket = false) => {
      // 不是socket插入的数据做去重处理
      if (!fromSocket) {
        const key = type === 'notify' ? 'msgSeq' : 'taskId'
        dataList = uniqBy(dataList, key)
      }

      return type === 'notify'
        ? (dataList as MessageItemType[])?.map((item: MessageItemType) => {
            return <ListMessageItem key={item.msgSeq} item={item} onCheck={onClose} />
          })
        : (dataList as DownloadItemType[])?.map((item: DownloadItemType) => {
            return <ListDownloadItem key={item.taskId} item={item} />
          })
    },
    [type, onClose]
  )

  // 打开时收到新消息处理
  const [hasNewMessage, setHasNewMessage] = useState(false)
  const timer = useRef(null)
  useEffect(() => {
    if (source.socketData.length > 0) {
      setHasNewMessage(true)
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        setHasNewMessage(false)
      }, 5 * 1000)
    }
  }, [source.socketData])

  const handleReadAll = useCallback(() => {
    dispatch(readMessage()).then(() => {
      queryUnRead()
    })
  }, [dispatch])

  return (
    <Menu>
      <div id={`${type}-scroll`} className={styles.popoverWrap}>
        <div className={styles.header}>
          <p className={styles.title}>{type === 'notify' ? t('message.title') : t('download.title')}</p>
          <div className={styles.btnWrapper}>
            {hasNewMessage ? (
              <OneButton className={styles.button} size="small">
                {t('message.newMsgBtn')}
              </OneButton>
            ) : null}
            {type === 'notify' && count > 0 && !hasNewMessage ? (
              <OneButton className={styles.button} size="small" onClick={handleReadAll} data-id="message_read_all">
                {t('message.readAll')}
              </OneButton>
            ) : null}
          </div>
          <Divider className={styles.line}></Divider>
        </div>
        <InfiniteScroll pageStart={1} loadMore={handleLoadMore} hasMore={listHasMore} threshold={100} loader={loading} useWindow={false}>
          {source.data?.length > 0 ? (
            <List className={styles.list} key="list">
              {/* socket插入的新消息 */}
              {source.socketData?.length > 0 ? handleMessageItem(source.socketData, true) : null}
              {handleMessageItem(source.data)}
            </List>
          ) : (
            <Empty />
          )}
        </InfiniteScroll>
      </div>
    </Menu>
  )
}

export default React.memo(MessageCenter)
