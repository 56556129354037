import * as Types from '@common/oneclub/typings'

/** 语言种类 */
export type LangType = 'zh-hans-cn' | 'zh-hant-tw' | 'zh-hant-hk' | 'en' | 'ja'

/** 语言 */
export type LanguageType = {
  label: string
  lang: string
  /** 传给后端接口的字段 */
  languageType: LangType
  hidden?: boolean
  /** antd的语言码 */
  antd: string
}

/** 语言 */
export const LANGUAGE: Types.Dict<Omit<LanguageType, 'label'>> = [
  {
    label: 'English',
    lang: 'en',
    languageType: 'en',
    antd: 'en_US',
  },
  {
    label: '中文繁體（香港）',
    lang: 'zh-HK',
    languageType: 'zh-hant-hk',
    antd: 'zh_HK',
  },
  {
    label: '中文繁體（台灣）',
    lang: 'zh-TW',
    languageType: 'zh-hant-tw',
    antd: 'zh_TW',
  },
  {
    label: '中文简体',
    lang: 'zh-CN',
    languageType: 'zh-hans-cn',
    antd: 'zh_CN',
  },
  {
    label: 'Japanese',
    lang: 'ja',
    languageType: 'ja',
    antd: 'ja_JP',
  },
]

export const LANGUAGE_MAP = LANGUAGE.reduce((acc, next) => {
  acc[next.lang] = next
  return acc
}, {} as Record<string, LanguageType>)

export const APP_LANG = {
  oc: ['en', 'zh-TW', 'zh-HK', 'zh-CN'],
  os: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  oscn: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  ossg: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  osmy: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  oshk: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  ostw: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  osid: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  osau: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  osus: ['en', 'zh-TW', 'zh-HK', 'zh-CN', 'ja'],
  osjp: ['en', 'ja'],
  ow: ['en', 'zh-TW', 'zh-CN'],
  og: ['en', 'zh-TW', 'zh-HK', 'zh-CN'],
}
