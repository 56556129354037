import { useEffect } from 'react'
import http from '@common/oneclub/services/http'
import { useTranslation } from 'react-i18next'
import { loginout as logout } from '@common/oneclub/store/authSlice'
import { useDispatch } from '@common/oneclub/store'
import { useHistory } from 'react-router'
import { OneMessage } from '@yy/one-ui'

const adminWhiteCode = ['SELLER_APP_APPLY_ACCOUNT_REPEAT']
const udbWhiteCode = []
const GlobalError = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  // admin HTTP 拦截器
  useEffect(() => {
    const id = http.normal.interceptors.response.use(
      (response) => {
        return response
      },
      (rejection) => {
        const shouldToast = !adminWhiteCode.includes(rejection?.code) && rejection?.code
        if (shouldToast) {
          const i18nResult = t([`system.error.${rejection.code}`, rejection.code /*fallback*/], { systemError: rejection.message })
          // 只有匹配到指定的多语言code，才全局提示
          if (i18nResult === t(`system.error.${rejection.code}`, { systemError: rejection.message })) {
            if (
              !['LST-133242-B9001' /*no login*/, 'LST-133242-B9003'] /*cookie expired*/
                .includes(rejection.code)
            ) {
              OneMessage.error(i18nResult)
            }
            switch (rejection.code) {
              case 'LST-133242-B9001' /*no login*/:
                dispatch(logout())
                window.location.href = '/user/login'
                break
              case 'LST-133242-B9003' /*cookie expired*/:
                dispatch(logout())
                window.location.href = '/user/login'
                break
            }

            // 修改数据，去掉message等，避免在发起请求者的catch中再次提示message
            return Promise.reject({
              data: null,
              code: rejection.code,
            })
          } else if (rejection?.bizMessage) {
            OneMessage.error(rejection.bizMessage)
          } else if (rejection?.message) {
            OneMessage.error(rejection.message)
          }
        }

        return Promise.reject(rejection)
      }
    )

    return () => {
      http.normal.interceptors.response.eject(id)
    }
  }, [dispatch, history, t])

  // udb拦截器
  useEffect(() => {
    const id = http.udb.interceptors.response.use(
      (response) => response,
      (rejection) => {
        const shouldToast = !udbWhiteCode.includes(rejection?.rescode) && rejection?.rescode
        if (shouldToast) {
          const i18nResult = t([`udb.rescode.${rejection.rescode}`, rejection.resmsg /*fallback*/])

          // 只有匹配到指定的多语言code，才全局提示
          if (i18nResult === t(`udb.rescode.${rejection.code}`)) {
            OneMessage.error(i18nResult)
            // 修改数据，去掉message等，避免在发起请求者的catch中再次提示message
            return Promise.reject({
              data: null,
              code: rejection.code,
            })
          }
        }

        return Promise.reject(rejection)
      }
    )

    return () => {
      http.udb.interceptors.response.eject(id)
    }
  }, [t])

  return null
}

GlobalError.displayName = 'GlobalError'
export default GlobalError
