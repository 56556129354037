import { useCallback, useEffect, useRef } from 'react'
import useScript from 'react-script-hook'
import { InitGoogle, InitFacebookLogin, InitGoogleLogin } from './init'
import { FACEBOOK_CDN_OPTIONS, GOOGLE_CDN_OPTIONS, SOURCE_SYSTEM, UDB_CONF } from '@common/oneclub/constants'
import { useMount, useRequest } from 'ahooks'
import UDB, { ThirdLoginParams, ThirdLoginResponse } from '@yy/oneship-udb'
import { getDeviceToken } from '@common/oneclub/utils'
import { ThirdInfoDO, postGetThirdInfo, postUnbindThirdInfo } from '@common/oneclub/services/api'
import { useTranslation } from 'react-i18next'
import { platformName } from '@common/oneclub/constants/thirdPlatform'
import { useSelector } from '@common/oneclub/store'

const thirdSigninIns = UDB.thirdSignin(UDB_CONF)

interface UseThirdLoginProps<Ext> {
  onSuccess: (data: ThirdLoginResponse['data'], stoken: string, platform: 'facebook' | 'google', extinfo: Ext, casTicket?: string) => any
  getExtinfo: () => Ext
  onError: (error: Error & { code?: string }, platform: string) => any
  withOperFrom?: boolean
  /**是否可以开始初始化谷歌sdk，不传默认为true */
  startInitGoogle?: boolean
  /**谷歌登录是否使用ref的方案，默认为true */
  refMode?: boolean
}

export const useThirdLogin = <Ext>({ onSuccess, getExtinfo, onError, withOperFrom, startInitGoogle, refMode = true }: UseThirdLoginProps<Ext>) => {
  /**
   * 第三方登录
   */
  const thirdLogin = useCallback<(token: string, platform: 'facebook' | 'google') => void>(
    async (token, platform) => {
      // 获取指纹
      const dfptoken = await getDeviceToken()
      const extinfo = getExtinfo()
      try {
        const params: ThirdLoginParams = {
          tokenType: 1 as const,
          channel: platform,
          thirdappid: platform === 'google' ? process.env.GOOGLE_CLIENT_ID : process.env.FACEBOOK_CLIENT_ID,
          token,
          extinfo: extinfo ? JSON.stringify(extinfo) : '',
          firstLoginCheck: true,
          cas: 1 as const,
          dfptoken,
        }
        if (withOperFrom) params.operFrom = platform === 'google' ? 'linkGG' : 'linkFB'
        const { data, stoken } = await thirdSigninIns.thirdLoginAndBindEmail(params)
        await onSuccess(data, stoken, platform, extinfo, token)
      } catch (error) {
        onError(error, platform)
      }
    },
    [onSuccess, getExtinfo, onError, withOperFrom]
  )

  /** 谷歌登录功能初始化 */
  const onGoogleSuccess = useCallback(
    (googleUser) => {
      const authProfile = googleUser.getAuthResponse()
      thirdLogin(authProfile.access_token, 'google')
    },
    [thirdLogin]
  )

  const onFacebookSuccess = useCallback(
    (response) => {
      if (response.authResponse) {
        thirdLogin(response.authResponse.accessToken, 'facebook')
      }
    },
    [thirdLogin]
  )

  const { googleImgRef, googleLogin } = useGoogleLogin({ onSuccess: onGoogleSuccess, startInitGoogle, refMode })
  const { faceBookLogin } = useFacebookLogin({
    onSuccess: onFacebookSuccess,
  })

  return { googleImgRef, faceBookLogin, googleLogin }
}

let googleAuth = null
/**
 * 加载并初始化google登录sdk
 * @param param0
 */
export const useGoogleLogin = ({ onSuccess, startInitGoogle = true, refMode = true }) => {
  /** 谷歌登录功能初始化 */
  const googleImgRef = useRef()

  // 载入google登录sdk
  const [googleLoading] = useScript(GOOGLE_CDN_OPTIONS)
  // sdk加载成功
  useEffect(() => {
    if (!document.querySelector(`script[src="${GOOGLE_CDN_OPTIONS.src}"]`) && googleLoading) return
    if (startInitGoogle) {
      if (refMode) InitGoogleLogin(googleImgRef.current, onSuccess)
      else
        InitGoogle().then((auth) => {
          googleAuth = auth
        })
    }
  }, [googleLoading, onSuccess, startInitGoogle, refMode])

  const googleLogin = useCallback(async () => {
    const options = new window.gapi.auth2.SigninOptionsBuilder()
    options.setPrompt('select_account')
    options.setScope('profile').setScope('email')
    const user = await googleAuth.signIn(options)
    onSuccess(user)
  }, [onSuccess])

  return { googleImgRef, googleLogin }
}

/**
 * 加载并初始化facebook登录sdk
 * @param param0
 */
export const useFacebookLogin = ({ onSuccess }) => {
  // 载入facebook登录sdk
  useScript(FACEBOOK_CDN_OPTIONS)
  // sdk预初始化
  useMount(() => InitFacebookLogin())

  /**
   * facebook获取登录token
   * https://developers.facebook.com/docs/javascript/
   */
  const faceBookLogin = useCallback(() => {
    window.FB &&
      window.FB.login(onSuccess, {
        scope: 'email',
        return_scopes: true,
      })
  }, [onSuccess])

  return { faceBookLogin }
}

/**
 * 第三方登录数据
 * @returns
 */
export const useThirdInfo = () => {
  const {
    data: thirdInfoMap,
    refresh: refreshThirdInfoDOList,
    loading: thirdInfoLoading,
  } = useRequest(async () => {
    const res = await postGetThirdInfo()
    const map = {} as Record<ThirdInfoDO['channel'], ThirdInfoDO>
    const thirdInfoDOList = res.data.thirdInfoDOList
    if (thirdInfoDOList && thirdInfoDOList.length) {
      thirdInfoDOList.forEach((c) => (map[c.channel] = c))
    }
    return map
  })
  return { thirdInfoMap, refreshThirdInfoDOList, thirdInfoLoading }
}

interface UseThirdLoginAndBindEmailProps {
  awaitConfirm: any
  message: { error: (string) => any; success: (string) => any }
  onSuccess?: () => any
}
/**
 * 第三方登录绑定
 */
export const useThirdLoginAndBindEmail = ({ awaitConfirm, message }: UseThirdLoginAndBindEmailProps) => {
  const { t } = useTranslation()
  const language = useSelector((state) => state.language)
  const userInfo = useSelector((state) => state?.auth?.userInfo)

  // 第三方登录数据
  const { thirdInfoMap, refreshThirdInfoDOList, thirdInfoLoading } = useThirdInfo()

  const success = useCallback(async () => {
    await refreshThirdInfoDOList()
    message.success(t('account.bindSuccess'))
  }, [message, t, refreshThirdInfoDOList])
  /**
   * 第三方绑定
   * udb文档：https://shopline.yuque.com/sczv0i/zbwfmf/lguorkd112b35rir#S6iY8 流程图账号连接场景
   */
  const handleLoginBind = useCallback(
    async (data: ThirdLoginResponse['data'], stoken, platform, extinfo, token) => {
      const { processCode } = data
      if (processCode === 0) {
        // 当processCode = 0，已被绑定
        if (data?.uinfo?.uid === userInfo.uid) success()
        else message.error(t('account.bindPlatform.exist', { platform: platformName[platform] }))
        return
      }
      await thirdSigninIns.thirdBindLoginAccount({ stoken, extinfo: JSON.stringify(extinfo), token, replaceOldChannel: true })
      success()
    },
    [t, message, success, userInfo?.uid]
  )
  const getExtinfo = useCallback(
    () => ({ countryCode: userInfo.countryCode, language: language.languageType, sourceSystem: SOURCE_SYSTEM.oc }),
    [language.languageType, userInfo.countryCode]
  )
  const onThirdLoginError = useCallback(
    (error, platform) => {
      const { code } = error as any
      if (!code) throw error
      switch (code) {
        case '1018':
          // 提示 该{第三方登录服务名称}账号已被其他账号绑定，请尝试更换{第三方登录服务名称}账号
          message.error(t('account.bindPlatform.exist', { platform: platformName[platform] }))
          break
        case '-999':
          message.error(t('udb.thirdTimeout'))
          break
        default:
          message.error(t(`udb.rescode.${code}`))
          break
      }
    },
    [t, message]
  )
  const { googleLogin, faceBookLogin } = useThirdLogin({
    onSuccess: handleLoginBind,
    getExtinfo,
    onError: onThirdLoginError,
    startInitGoogle: !!(thirdInfoMap && !thirdInfoLoading),
    withOperFrom: true,
    refMode: false,
  })
  const unbindThirdInfo = async (platform: ThirdInfoDO['channel']) => {
    const ok = await awaitConfirm({
      title: t('account.unbindPlatform.title', { platform: platformName[platform] }),
      content: t('account.unbindPlatform.content', { platform: platformName[platform] }),
      okText: t('frame.confirm'),
      cancelText: t('common.cancel'),
    })
    if (!ok) return
    await postUnbindThirdInfo({ channel: platform })
    await refreshThirdInfoDOList()
    message.success(t('account.unbindSuccess'))
  }

  return { unbindThirdInfo, googleLogin, faceBookLogin, thirdInfoMap }
}
