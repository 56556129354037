import { registryUser as registryUserApi } from '@common/oneclub/services/api'
import { getUrlParam } from '@common/oneclub/utils'
import { SOURCE_SYSTEM } from '@common/oneclub/constants'

function sleep(time: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}
/**
 * 如果注册接口报错，等待3秒再进入系统
 * @param params
 */
export async function registryUser(params) {
  let isSuccess = false
  try {
    await registryUserApi({
      sourceSystem: SOURCE_SYSTEM[getUrlParam('app')],
      fromKeyword: decodeURIComponent(getUrlParam('keyword')),
      adChannel: getUrlParam('channel'),
      ...params,
    })
    isSuccess = true
  } catch (error) {
    isSuccess = false
  }

  if (!isSuccess) {
    await sleep(3000)
  }
}
