import React from 'react'
import { renderRoutes } from 'react-router-config'
import TopBar from '@/components/TopBar'
import Carousel from './components/Carousel'
import { getUrlParam } from '@common/oneclub/utils'
import classNames from 'classnames'
import styles from './index.module.less'
import useAdaptApp from '@common/oneclub/hooks/useAdaptApp'
import useCheckIsVn from '@common/oneclub/hooks/useCheckIsVn'

import { useAnalytics } from '@common/oneclub/hooks/useAnalytics'
import { PROJECT_ID } from '@common/oneclub/constants/tracker'
import { useMount } from 'ahooks'

const PrimaryLayout = (props) => {
  const { route } = props
  const navBarProps = {}
  const app = getUrlParam('app')
  const { logo } = useAdaptApp()
  const { init: reportInit } = useAnalytics(PROJECT_ID)

  useCheckIsVn()

  // 为所有页面都加上“无埋点统计”
  useMount(() => {
    reportInit()
  })

  return (
    <div className={styles.box}>
      <div className={styles.left}>
        <div className={styles.header}>
          <TopBar leftLogo={logo} {...navBarProps} />
        </div>
        <div className={styles.leftBox}>
          <div className={styles.container}>{renderRoutes(route.routes)}</div>
        </div>
      </div>
      {/* 轮播图 */}
      <div className={classNames([styles.right, styles[`right${app}`]])}>
        <Carousel></Carousel>
      </div>
    </div>
  )
}

export default PrimaryLayout
