export const DOWNLOAD_TASK_TYPES = {
  /** 钱包流水导出 */
  ONE_CLUB_WALLET_FLOW_EXPORT: 'ONE_CLUB_WALLET_FLOW_EXPORT',
}

/** download center title and content of configs */
export const DOWNLOAD_SUCCESS_MAP = {
  [DOWNLOAD_TASK_TYPES.ONE_CLUB_WALLET_FLOW_EXPORT]: {
    title: 'download.task.complete.bill',
    content: 'download.task.complete',
  },
}
export const DOWNLOAD_FAILED_MAP = {
  [DOWNLOAD_TASK_TYPES.ONE_CLUB_WALLET_FLOW_EXPORT]: {
    title: 'download.task.failed.bill',
    content: 'download.task.failed',
  },
}
