import React, { useMemo } from 'react'
import { matchRoutes, MatchedRoute, renderRoutes } from 'react-router-config'
import { antRoutes, MenuDataItem } from '@common/oneclub/utils'
import { useAdminRoutes } from '@/routers/admin'
import { useCookieSdk } from '@common/oneclub/hooks'
import { PageLayout } from './components/PageLayout'
import { useSocket } from '@common/oneclub/hooks/useSocket'
import '@/styles/admin.less'

export interface AdminLayoutProps {
  route: {
    routes: MenuDataItem[]
  }
  location: Location
  children?: React.ReactNode
}

export const AdminLayout: React.FC<AdminLayoutProps> = (props) => {
  const { route, location } = props
  const routes = useMemo(() => [route], [route])
  const matchedRoutes: MatchedRoute<string>[] = matchRoutes(routes, location.pathname)
  const [adminRoute] = useAdminRoutes(routes) || []

  useCookieSdk()

  useSocket()

  return (
    <PageLayout routers={antRoutes(adminRoute?.routes as any)} matchedRoutes={matchedRoutes}>
      {adminRoute ? renderRoutes(adminRoute.routes) : null}
    </PageLayout>
  )
}

AdminLayout.displayName = 'AdminLayout'
