import { getEnv } from '../utils/trace'

/** 添加无埋点上报的脚本及初始化 */
export function useAnalytics(containerId: string) {
  const initHeadScript = () => {
    const head = document.getElementsByTagName('head')[0]

    const s1 = document.createElement('script')
    const s2 = document.createElement('script')

    s1.innerHTML = `!(function(window, b, e, v, n, t, s) {
      if (window.slq) return;
      n = window.slq = function() {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      n.queue = [];
    })(window, document);`

    s2.async = true
    s2.src = 'https://cdn.myshopline.com/sl/sdk/sl-slq.min.js'

    head.insertAdjacentElement('beforeend', s1)
    head.insertAdjacentElement('beforeend', s2)
  }
  const initScript = () => {
    ;(window as any)?.slq?.('init', {
      options: {
        // 是否是调试模式，如果是则不会上报到海度，只会在本地打log
        // 因此如果测试同学需要在测试环境验证的话，需要判断local或者dev环境开启
        debugMode: process.env.NODE_ENV === 'development', // 本地不会上报，测试会上报
      },
      params: {
        project_id: containerId, // 应用id，必填
        env: getEnv(), // 必填
        // store_id: 'store_id', // 商家id，选填
        // merchant_id: 'merchant_id', // 店铺id，选填
        // user_id: 'user_id', // 用户id，选填
        // app_id: 'app_id', // 插件标识，选填
        act: 'webpagetraceless', // act上报协议，必填
      },
    })
  }
  const init = () => {
    let isInit = false
    return (() => {
      if (!isInit) {
        initHeadScript()
        initScript()
        isInit = true
      }
    })()
  }

  return {
    init,
  }
}
