import * as Types from './type'
import Api from '@common/oneclub/services/http'
import { OneMessage } from '@yy/one-ui'
import { useState } from 'react'

interface IS3Config {
  scene: string
  fileName?: string
}

const parseUploadInfo = (infoArray: Record<string, any>[]) => {
  if (!infoArray) {
    return {}
  }

  const data = {}
  infoArray.forEach((info) => {
    data[info.key] = info.value
  })

  return data
}

/** 生成预授权上传URL */
export const getAmazonS3Api = async (params: IS3Config) => {
  if (!params) {
    return OneMessage.info('required params config, must be Object')
  }

  const response = await Api.normal.post<Types.S3ResponseType>('/api/media/s3/get_upload_pre_Url', params)
  const { bizCode, code, data } = response
  if (bizCode === 'SUCCESS' || code === 'SUCCESS') {
    const { actionUrl, fields, headers, fileId } = data
    const uploadFormData = new FormData()

    // 组装formdata 数据
    fields.forEach((item: any) => {
      uploadFormData.append(item.key, item.value)
    })

    return {
      actionUrl,
      data: uploadFormData,
      headers: { ...parseUploadInfo(headers || []), 'content-type': 'multipart/form-data' },
      fileId,
      fields,
    }
  }

  return Promise.reject(response)
}

/** 上传到亚马逊 */
export const uploadAmazon = async (params: { url: string; formData: any; headers: any; onUploadProgress?: (e: ProgressEvent) => void }) => {
  const response = await Api.post(params.url, params.formData, {
    headers: { ...params.headers },
    onUploadProgress: params?.onUploadProgress,
    timeout: 60e3,
    withCredentials: false,
  })
  const { status } = response
  if (status === 204) {
    return {
      bizCode: 'SUCCESS',
      data: response,
    }
  }

  return response
  // return Promise.reject({
  //   bizCode: 'UPLOAD_FAIL',
  //   bizMessage: 'batchOrder.uploadError',
  //   message: 'batchOrder.uploadError',
  // })
}

interface IUseUploadS3 {
  /** 获取预签名URL失败 */
  getS3PreUrlErrCallback?: (e) => void

  /** 上传成功 */
  uploadSuccessCallback?: (resp) => void

  /** 上传失败 */
  uploadErrCallback?: (e) => void
}
interface IDoUpload {
  scene: string
  file: any
  /** 上传进度事件 */
  onUploadProgress?: (e: ProgressEvent) => void
  fileName?: string
}
export function useUploadS3(option: IUseUploadS3 = {}) {
  const { getS3PreUrlErrCallback, uploadSuccessCallback, uploadErrCallback } = option

  const [uploadData, setUploadData] = useState<any>({})
  const doUpload = async (config: IDoUpload) => {
    if (!config) {
      OneMessage.info('required params config')
      return
    }

    let s3Resp
    try {
      s3Resp = await getAmazonS3Api({
        scene: config.scene,
      })

      setUploadData(s3Resp)
    } catch (e) {
      getS3PreUrlErrCallback && getS3PreUrlErrCallback(e)
    }

    if (s3Resp) {
      const { actionUrl, data: formData, headers, fileId } = s3Resp
      if (actionUrl && fileId) {
        formData.append('file', config.file)
        // (2) 获取上传文件到亚马逊服务器
        try {
          const respUploadS3Resp = await uploadAmazon({ url: actionUrl, formData, headers, onUploadProgress: config?.onUploadProgress })
          uploadSuccessCallback && uploadSuccessCallback(respUploadS3Resp)
        } catch (e) {
          uploadErrCallback && uploadErrCallback(e)
        }
      }
    }
  }

  return {
    doUpload,
    uploadData,
  }
}

interface IUseUploadImage {
  successCallback: FnType
  errorCallback: FnType
}

export function useUploadImage(option = {} as IUseUploadImage) {
  const { successCallback, errorCallback } = option

  const doUpload = async (config: IDoUpload) => {
    if (!config) {
      OneMessage.info('required params config')
      return
    }
    const { scene, fileName } = config
    try {
      const {
        actionUrl,
        data: formData,
        headers,
        fileId,
        fields,
      } = (await getAmazonS3Api({
        scene,
        fileName,
      })) as any
      if (actionUrl && fileId) {
        formData.append('file', config.file)
        await uploadAmazon({ url: actionUrl, formData, headers })
        successCallback(fields[0].value)
      }
    } catch (e) {
      errorCallback(e)
    }
  }

  return {
    doUpload,
  }
}
