import { Dropdown } from 'antd'
import React, { useCallback, useState } from 'react'
import { Bell } from '@yy/one-icon'
import { OneBadge } from '@yy/one-ui'
import { useDispatch, useSelector } from '@common/oneclub/store'
import { notificationActions, queryMessageList } from '@common/oneclub/store/notificationSlice'
import MessageCenter from '../MessageCenter'
import { MessageNotification } from '../Notification'
import styles from './index.module.less'
import { queryUnRead } from '@common/oneclub/hooks'

const Notify: React.FC = () => {
  const dispatch = useDispatch()

  const messageCount = useSelector((state) => state.notification.unReadMessageCount)

  const currentSocketData = useSelector((state) => state.notification.messageInfo.currentSocketData)

  const [visible, setVisible] = useState(false)

  const handleOpen = (visible: boolean) => {
    setVisible(visible)
    if (visible) {
      dispatch(notificationActions.setSocketMessageList([]))
      dispatch(queryMessageList({ pageSize: 20 }))
      queryUnRead()
    }
  }
  const handleClose = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <>
      <div data-id="notify-message-button" id="notify-message-button">
        <Dropdown
          trigger={['click']}
          placement="bottom"
          overlay={<MessageCenter count={messageCount} type="notify" onClose={handleClose}></MessageCenter>}
          onVisibleChange={handleOpen}
          overlayClassName={styles.overlay}
          destroyPopupOnHide
          visible={visible}
        >
          <OneBadge color="primary" count={messageCount} className={styles.notify}>
            <Bell className={styles.icon} />
          </OneBadge>
        </Dropdown>
      </div>
      {/* 消息通知 */}
      {currentSocketData && <MessageNotification messageItem={currentSocketData} />}
    </>
  )
}

Notify.displayName = 'Notify'
export default Notify
