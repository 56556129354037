import http from '@common/oneclub/services/http'
import { SellerAppApplyCreateRequest } from './serviceApply'
import { oneConfig } from '../one-config'
import { ValuesType } from 'utility-types'
import type * as UserType from '@common/oneclub/constants/userType'
import type { OneConfigRoot } from '@yy/one-config/libs/one-config'

export type CountryType = 'CN' | 'SG' | 'MY' | 'TH' | 'VN' | 'HK' | 'TW' | 'GB'

export interface SellerInfo {
  sellerId: string
  sellerName: string
  sellerWebsite: string
  sellerEmailList: string[]
  sellerCusServicePhone: string
  sellerKyc?: SellerAppApplyCreateRequest
  loginSources?: string[]
  oaContractUrls?: Array<{ phone: string; oaContractUrl: string }>
}
export interface SellerInfoReq {
  /** 是否需要查询商家的oa合同签约地址 */
  needOaContractUrl?: boolean
  /** 如果needOaContractUrl=true，则urlType不允许为空 CONTRACT_WEB_LIST( "CONTRACT_WEB_LIST","web页面" ),CONTRACT_H5_PROCESS( "CONTRACT_H5_LIST","h5页面" ) */
  urlType?: string
}

// 获取商家详情
export const getSellerDetail = (params?: SellerInfoReq) => http.normal.post<SellerInfo>('/api/seller/info/detail', params)

// 更新商家基础信息
export const updateSellerInfo = (params: SellerInfo) => http.normal.post('/api/seller/info/update', params)

// 申请注销
export const sellerInfoDelete = () => http.normal.post('/api/seller/info/cancellation')

export interface SellerAppApplyDetailReqDTO {
  /**服务申请类型 */
  appApplyType?: string
  companyCode?: string
}

/** 获取申请服务详情 */
export const getApplyDetail = (parmas: SellerAppApplyDetailReqDTO) => http.normal.post<SellerAppApplyCreateRequest>('/api/seller/appApply/detail', parmas)

export type IgetApplyKycConfigRes = Record<
  string,
  {
    visible: Record<string, boolean>
    defaultValue: Record<string, any>
  }
>

/** 获取oms配置 */
export const getTargetOmsConfig = async <T extends keyof OneConfigRoot>(key: T) => {
  return oneConfig.get(key)
}

interface VerificationSendReqDTO {
  /**手机号 */
  phone: string
  countryCode: string
}

/**
 * **接口** [发送验证码↗](https://api-doc-sl.inshopline.com/#/project/Java/226/feature%2F20230626_1510_contract/interface/5dca5ec877a9e7d678b253faa03e3ac7)
 * @请求头 `POST /api/message/send_code`
 * @更新时间 `2023-06-05 20:40:08`
 */
export function postSendCode(data?: VerificationSendReqDTO) {
  return http.normal.post(`/api/message/send_code`, data)
}

export interface ContractCompanyDetailReqDTO {
  phone: string
  /**验证码（约束：verificationCode must not be blank） */
  verificationCode: string
  /**公司名称（约束：companyName must not be blank） */
  companyName: string
  /**联系人（约束：contacts must not be blank） */
  contacts: string
  /**开户行 */
  bank?: string
  /**账号名称 */
  bankAccountName?: string
  /**银行账号 */
  bankAccountNo?: string
  /**签署邮箱 */
  signingEmail?: string
  /** 提交的页面类型 CONTRACT_WEB_LIST( "CONTRACT_WEB_LIST","web页面" ),CONTRACT_H5_PROCESS( "CONTRACT_H5_LIST","h5页面" )（约束：urlType must not be blank） */
  urlType: SIGN_URL_TYPE
}
export enum SIGN_URL_TYPE {
  CONTRACT_WEB_LIST = 'CONTRACT_WEB_LIST',
  CONTRACT_H5_PROCESS = 'CONTRACT_H5_LIST',
}
/**
 * **接口** [提交对公合同↗](https://api-doc-sl.inshopline.com/#/project/Java/226/feature%2F20230626_1510_contract/interface/c066055a5ddc2a9d052507310a340227)
 * @请求头 `POST /api/contract/commit_company`
 * @更新时间 `2023-06-05 20:40:07`
 */
export function postCommitCompany(data: ContractCompanyDetailReqDTO) {
  return http.normal.post<{ oaContractUrl: string }>(`/api/contract/commit_company`, data)
}

export interface ContractPersonDetailReqDTO {
  /**姓名（约束：name must not be blank） */
  name: string
  /**手机号（约束：phone must not be blank） */
  phone: string
  /**验证码（约束：verificationCode must not be blank） */
  verificationCode: string
  /**开户行 */
  bank?: string
  /**账号名称 */
  bankAccountName?: string
  /**银行账号 */
  bankAccountNo?: string
  /**签署邮箱 */
  signingEmail?: string
  urlType: 'CONTRACT_WEB_LIST' | 'CONTRACT_H5_LIST'
}
/**
 * **接口** [提交对私合同↗](https://api-doc-sl.inshopline.com/#/project/Java/226/feature%2F20230626_1510_contract/interface/d315296e1deca4dc0ac1281cc8bd0dce)
 * @请求头 `POST /api/contract/commit_person`
 * @更新时间 `2023-06-05 20:40:07`
 */
export function postCommitPerson(data: ContractPersonDetailReqDTO) {
  return http.normal.post<{ oaContractUrl: string }>(`/api/contract/commit_person`, data)
}

export interface BaseUserInfo {
  /** 是否激活 */
  activated: boolean
  /** 是否禁用 */
  banned: boolean
  /** 国家 */
  countryCode: CountryType
  /** 国家对应币种 */
  countryCurrencyCode: string
  /** 区号 */
  areaCode: number
  /** 手机号 */
  phone: string
  /** email */
  email: string
  /** 是否为主账号 */
  mainAccount?: boolean
  /** 名字 */
  nickname: string
  /** 账户名称 */
  oneshipName: string
  /** uid */
  uid: string
  /** 用户类型 */
  userType: ValuesType<typeof UserType>
  /** 商家 ID */
  sellerId?: string
  /** 注册类型 */
  registrySource?: string
  /** 账号类型 MAIN-母账号，SUB-子账号，OP-运营超管 */
  accountType?: 'MAIN' | 'SUB' | 'OP'
  /** 商家名称 */
  sellerName: string
}

/**
 * 获取seller基础信息
 * @returns
 */
export function getSellerBasic() {
  return http.normal.post<BaseUserInfo>('/api/user/info/get-seller-basic')
}
