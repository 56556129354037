/**
 * 获取url中指定参数的值
 * @param name 需要获取的参数名
 * @param url 需要被处理的url，默认为当前url
 * @return 对应的参数值
 */
export function getUrlParam<T extends string>(name: string, url?: string): T {
  const paramReg = new RegExp(`[\\?&#]${name}=([^&#]+)`, 'gi')
  const paramMatch = (url || window.location.href).match(paramReg)
  let paramResult = []
  if (paramMatch && paramMatch.length > 0) {
    paramResult = paramMatch[paramMatch.length - 1].split('=')
    if (paramResult && paramResult.length > 1) {
      return paramResult[1]
    }
    return '' as T
  }
  return '' as T
}

export function getQuery<T extends Record<string, any>>(url?: string): T {
  const curUrl = url ?? location.href
  if (!/\?/.test(curUrl)) return {} as T
  const queryStr = curUrl.replace(/^.*\?/, '')
  return queryStr
    .split('&')
    .map((str) => str.split('='))
    .reduce((acc, [k, v]) => {
      acc[k] = decodeURIComponent(v)
      return acc
    }, {} as any) as T
}

export function getQueryWithoutDecode<T extends Record<string, any>>(url?: string) {
  const curUrl = url ?? location.href
  if (!/\?/.test(curUrl)) return {}
  const queryStr = curUrl.replace(/^.*\?/, '')
  return queryStr
    .split('&')
    .map((str) => str.split('='))
    .reduce((acc, [k, v]) => {
      acc[k] = v
      return acc
    }, {} as any) as T
}

export function getQueryStr(url: string, otherQueryString?: string) {
  const queryStr = decodeURIComponent(url ?? location.href).split('?')?.[1]
  if (queryStr) {
    return otherQueryString ? `?${queryStr}&${otherQueryString}` : `?${queryStr}`
  } else {
    return otherQueryString ? `?${otherQueryString}` : ''
  }
}

export function delParam(name) {
  const queryStr = location.href.split('?')?.[1]
  const newQueryArr = queryStr.split('&').filter((item) => !item.includes(name))
  const newQueryStr = newQueryArr?.length ? `?${newQueryArr.join('&')}` : ''
  return `${location.pathname}${newQueryStr}`
}

export function stringifyQuery(url: string, query: Record<string, string>) {
  return (
    url +
    '?' +
    Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&')
  )
}

export function getOneClubPath(path: string, lang: string, extraQuery?: Record<string, any>) {
  const service = window.location.href
  const url = stringifyQuery(path, {
    ...extraQuery,
    app: 'oc',
    lang,
    service: encodeURIComponent(service),
  })
  return url
}

/**
 * 删除url上的query参数
 * @param param 需要删除的key
 */
export function deleteUrlQuery(param: string | string[]) {
  try {
    const paramArr = Array.isArray(param) ? param : [param]
    const url = new URL(window.location.href)
    paramArr.forEach((p) => {
      url.searchParams.delete(p)
    })
    history.replaceState(null, null, url)
  } catch (error) {}
}
