import { createContext } from 'react'

type Routes = {
  route: {
    path?: any
    menuTitle?: string
  }
}[]

const routes: Routes = []

export const BreadcrumbContext = createContext(routes)
