import { useSelector } from '@common/oneclub/store'
import { useMemo } from 'react'
export function useCaptchaLanguageChange() {
  const language = useSelector((state) => state.language)
  // 语言转换
  const captchaLanguage = useMemo(() => {
    switch (language.lang) {
      case 'zh':
      case 'zh-CN':
        return 'zh-hans-cn'
      case 'zh-TW':
        return 'zh-hant-tw'
      case 'zh-HK':
        return 'zh-hant-hk'
      default:
        return language.lang
    }
  }, [language.lang])

  return captchaLanguage
}
