import Api from '@common/oneclub/services/http'
import socket from '@common/oneclub/services/socket'
import store, { useSelector } from '@common/oneclub/store'
import { notificationActions } from '@common/oneclub/store/notificationSlice'
import type { ResponseUnReadType } from '@common/oneclub/store/notificationSlice/type'
import { updateWalletListInfo } from '@common/oneclub/store/authSlice'
import type { IWallInfo } from '@common/oneclub/store/authSlice/type'
import Cookies from 'js-cookie'
import { useEffect, useRef } from 'react'

/** 异步任务消息事件 */
enum MessageTypeEnum {
  /** 用户消息 */
  USER = 1,
}

/** 未读通知消息 */
export const queryUnRead = async () => {
  const params = {
    appId: process.env.WEBSOCKET_APP_ID,
  }

  const [msgRes, taskRes] = await Promise.all([
    Api.admin.post<ResponseUnReadType>('/api/user/message/countUnRead', params),
    Api.admin.post<ResponseUnReadType>('/api/user/task/countUnRead', params),
  ])

  const { unReadCount: messageCount } = msgRes?.data
  const { unReadCount: exportCount } = taskRes?.data

  store.dispatch(notificationActions.setUnReadMessageCount({ messageCount }))
  store.dispatch(notificationActions.setUnReadDownloadCount({ exportCount }))
}

export const updateBalance = (data: IWallInfo) => {
  store.dispatch(updateWalletListInfo(data))
}

/** socket 配置与初始化 */
export const useSocket = () => {
  const { isLogined, userInfo } = useSelector((state) => state.auth)
  const uid = userInfo?.uid
  const isInit = useRef(false)

  // 用户消息
  useEffect(() => {
    return socket.onUserMessage((payload) => {
      // 后端没法保证用户消息都有messageType字段，兼容设置默认值1
      const { messageCount, userMessage, messageType = 1 } = payload
      switch (messageType) {
        case MessageTypeEnum.USER:
          store.dispatch(notificationActions.setUnReadMessageCount({ messageCount }))
          store.dispatch(notificationActions.setSocketMessageList([userMessage]))
          break
        default:
          break
      }
    })
  }, [])

  // 导出任务消息
  useEffect(() => {
    return socket.onExportListMessage((payload) => {
      const { exportCount, exportMessage } = payload
      store.dispatch(notificationActions.setUnReadDownloadCount({ exportCount }))
      store.dispatch(notificationActions.setSocketDownloadList([exportMessage]))
    })
  }, [])

  // 异步任务消息
  // useEffect(() => {
  //   return socket.onAsyncResult((payload) => {
  //     return updateAsyncTask(payload)
  //   })
  // }, [])

  // 断开连接，重新获取未读消息
  useEffect(() => {
    return socket.onClosed(queryUnRead)
  }, [])

  // 监听余额更新
  useEffect(() => {
    return socket.onBalanceUpdate((payload) => {
      return updateBalance(payload)
    })
  }, [])

  // 获取未读信息
  useEffect(() => {
    if (isLogined) {
      queryUnRead()
    }
  }, [isLogined])

  useEffect(() => {
    const udbSubAppId = Cookies.get('osudb_subappid')
    if (uid && udbSubAppId && !isInit.current) {
      isInit.current = true

      // 配置
      socket.configure(() => ({
        env: process.env.APP_ENV.toUpperCase(),
        /**
         * @todo
         * 此处根据打包环境，不能根据用户真正所在地区进行就近 websocket 连接
         * 后面可以根据服务器环境确认 socket 连接地址
         */
        wsDomain: process.env.WEBSOCKET_URL,
        appId: process.env.WEBSOCKET_APP_ID,
        privateKey: process.env.WEBSOCKET_PRIVATE_KEY,
        broadcasts: typeof process.env.WEBSOCKET_UDB_BOARDCASTS === 'string' ? process.env.WEBSOCKET_UDB_BOARDCASTS.split(',') : [],
        udbAuthUrl: `${window.location.origin}/udb/lgn/sdk/getCookieOtp.do`,
        udbAppId: process.env.WEBSOCKET_UDB_APP_ID,
        udbSubAppId,
        uid,
      }))

      socket.open()
    }

    return () => {
      if (isInit.current) {
        socket?.close()
      }
    }
  }, [uid])
}
