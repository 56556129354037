export function jsonParse<T, R extends string>(json: R): Partial<T>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function jsonParse<_, R>(json: R): R
export function jsonParse<T, R = any>(json: R): Partial<T> | R {
  if (typeof json !== 'string') return json
  try {
    return JSON.parse(json) as T
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    return {} as Partial<T>
  }
}
