export const MESSAGE_RESOURCE = {
  // 充值到账提醒
  walletDeposit: 'WALLET_DEPOSIT',
  // 充值失败
  walletDepositFail: 'WALLET_DEPOSIT_FAIL',
  /** 调账 */
  adjustAccount: 'ADJUST_ACCOUNT',
}

export const OPERATE_STATUS = {
  /** 成功 */
  success: 'SUCCESS',
  /** 失败 */
  fail: 'FAIL',
}
