import React, { useMemo } from 'react'
import { OneSelect } from '@yy/one-ui'
import { IOneSelectProps } from '@yy/one-ui/lib/OneSelect'
import { withLabelFormItem } from '@common/oneclub/components'
import { useOneclubConfigs } from '@common/oneclub/configuration'
/**
 * 注册国家下拉选择
 * @returns
 */
export const CountrySelectNormal: React.FC<IOneSelectProps<any>> = (props) => {
  const { country_list } = useOneclubConfigs()

  // 越南站点下线
  const list = useMemo(() => {
    return country_list.sort((a, b) => (a.label > b.label ? 1 : -1))
  }, [country_list])

  return (
    <OneSelect {...props} dropdownClassName="themeBlack">
      {list?.map((item) => (
        <OneSelect.Option value={item.value} key={item.value}>
          {item.label}
        </OneSelect.Option>
      ))}
    </OneSelect>
  )
}

export const CountrySelect = withLabelFormItem<IOneSelectProps<any>>(CountrySelectNormal)
