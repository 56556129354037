import React from 'react'
import { useMount } from 'ahooks'
import { renderRoutes, RouteConfig } from 'react-router-config'
import TopBar from '@/components/TopBar'
import styles from './index.module.less'
import { useAnalytics } from '@common/oneclub/hooks/useAnalytics'
import { PROJECT_ID } from '@common/oneclub/constants/tracker'
import { useDispatch, useSelector } from '@common/oneclub/store'
import { getUserInfo } from '@common/oneclub/store/userSlice'
import { getBaseUserInfo as getAuthUserInfo } from '@common/oneclub/store/authSlice'
import logoOw from '@common/oneclub/assets/imgs/logo-ow.svg'

const OWLayout: React.FC<{ route: RouteConfig }> = (props) => {
  const { route } = props
  const navBarProps = {}
  const { init: reportInit } = useAnalytics(PROJECT_ID)

  const dispatch = useDispatch()
  const info = useSelector((state) => state?.user?.info)

  // 为所有页面都加上“无埋点统计”
  useMount(() => {
    dispatch(getUserInfo())

    // 这个接口是获取子账号信息
    dispatch(getAuthUserInfo())
    reportInit()
  })

  return !info ? null : (
    <div className={styles.root}>
      <div className={styles.header}>
        <TopBar className={styles.topbar} leftLogo={logoOw} {...navBarProps} />
      </div>

      {renderRoutes(route.routes)}
    </div>
  )
}

export default OWLayout
