import { useBoolean } from 'ahooks'
import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { submitKYC } from '@common/oneclub/services/api'
import { OneMessage } from '@yy/one-ui'
import type { Rule } from 'antd/lib/form'
import type useForm from 'rc-field-form/es/useForm'
import { useSelector } from '@common/oneclub/store'

export const useOWKyc = (form: ReturnType<typeof useForm>[0]) => {
  const history = useHistory()
  const [isLoading, { setTrue: showLoading, setFalse: hideLoading }] = useBoolean(false)
  const { t } = useTranslation()
  const countryCode = useSelector((state) => state?.user?.info?.countryCode)

  const rules = useMemo(() => {
    return {
      /** 名称 */
      name: [
        {
          validator: async (_, value: string) => {
            const text = value
            if (!text) {
              throw Error(t('common.formValidRequired'))
            }

            if (text.length > 64) {
              throw Error(t('common.formValidLengthLessThan', { length: 64 }))
            }
          },
        },
      ] as Rule[],
      /** 联系电话 */
      phone: [
        {
          validator: async (_, value: string) => {
            const text = value
            if (!text) {
              throw Error(t('common.formValidRequired'))
            }

            if (text.length > 64) {
              throw Error(t('common.formValidLengthLessThan', { length: 64 }))
            }
          },
        },
      ] as Rule[],
      email: [
        {
          validator: async (_, value: string) => {
            const text = value
            if (!text) {
              throw Error(t('common.formValidRequired'))
            }

            // eslint-disable-next-line
            if (value && !/^([A-Za-z0-9_\-\+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(value)) {
              throw new Error(t('common.formValidEmail'))
            }

            if (length > 64) {
              throw new Error(t('common.formValidLengthLessThan', { length: 64 }))
            }
          },
        },
      ] as Rule[],
      /** 企业名称 */
      company: [
        {
          validator: async (_, value: string) => {
            const text = value
            if (!text) {
              throw Error(t('common.formValidRequired'))
            }

            if (text.length > 64) {
              throw Error(t('common.formValidLengthLessThan', { length: 64 }))
            }
          },
        },
      ] as Rule[],
      /** 统编号码/身份证字号 */
      idno: !['TW'].includes(countryCode)
        ? null
        : ([
            {
              validator: async (_, value: string) => {
                const text = value
                if (!text) {
                  throw Error(t('common.formValidRequired'))
                }

                if (text.length > 64) {
                  throw Error(t('common.formValidLengthLessThan', { length: 64 }))
                }
              },
            },
          ] as Rule[]),
      select: [
        {
          validator: async (_, value: string) => {
            const text = value
            if (!text) {
              throw Error(t('common.pleaseSelect'))
            }
          },
        },
      ] as Rule[],
      shopUrl: [
        {
          validator: async (_, value: string) => {
            const text = value
            if (!text) {
              return
            }

            if (text.length > 128) {
              throw Error(t('common.formValidLengthLessThan', { length: 128 }))
            }
          },
        },
      ] as Rule[],
      extInfo: [
        {
          validator: async (_, value: string) => {
            const text = value
            if (!text) {
              return
            }

            if (text.length > 256) {
              throw Error(t('common.formValidLengthLessThan', { length: 256 }))
            }
          },
        },
      ] as Rule[],
    } as const
  }, [t, countryCode])

  const submit = useCallback(
    async (params: { captchaToken: string }) => {
      const formdata = await form.validateFields()
      const { phone, areaCode, ...restParams } = (formdata || {}) as any
      const finalAreaCode = `00${areaCode}`
      try {
        showLoading()
        // 移动端和pc端提交的phone不一样
        await submitKYC({
          ...restParams,
          areaCode: finalAreaCode,
          phone: `${finalAreaCode}${phone}`,
          ...params,
        })
      } catch (error) {
        OneMessage.error(error.message)
        return false
      } finally {
        hideLoading()
      }

      history.push('/ow/kycApply/completed')
    },
    [form, history, showLoading, hideLoading]
  )

  return { isLoading, form: form as any, submit, rules }
}
