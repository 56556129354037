import http from '@common/oneclub/services/http'

export interface BasicUserInfo {
  email: string
  phone: string
  oneshipName: string
  countryCode: string
  areaCode: string
  acceptMarketing: boolean
}

interface EditBasicUserInfoParams {
  oneshipName?: string
  acceptMarketing?: boolean
}

// 账号信息编辑
export const editBasicUserInfo = (params: EditBasicUserInfoParams) => http.normal.post('/api/user/info/update-basic', params)

export interface ThirdInfoDO {
  ext: any
  uid: string
  bind: boolean
  trdid: string
  appid: string
  channel: 'google' | 'facebook'
  email: string
}
interface ThirdInfo {
  thirdInfoDOList: ThirdInfoDO[]
}

/**
 * **接口** [getAggUserInfo↗](https://apidoc.inshopline.com/#/project/Java/226/feature%2F20230914_3028_oneclub_account/interface/72e8ee062c3d1848eca0dde5618c88c0)
 * @请求头 `POST /api/user/info/get-third-info`
 * @更新时间 `2023-12-11 20:20:19`
 */
export function postGetThirdInfo() {
  return http.normal.post<ThirdInfo>(`/api/user/info/get-third-info`)
}

interface UnbindThirdInfoReqDTO {
  /**第三方渠道: google等 */
  channel: ThirdInfoDO['channel']
}

/**
 * **接口** [unbindThirdInfo↗](https://apidoc.inshopline.com/#/project/Java/226/feature%2F20230914_3028_oneclub_account/interface/bc8506caf34857e9fc48e5f338888d3a)
 * @请求头 `POST /api/user/info/unbind-third-info`
 * @更新时间 `2023-12-11 20:20:19`
 */
export function postUnbindThirdInfo(data?: UnbindThirdInfoReqDTO) {
  return http.normal.post(`/api/user/info/unbind-third-info`, data)
}

interface SyncUdbInfoReq {
  scene: 'changeMobile' | 'changeEmail' | 'unbindEmail' | 'unbindMobile'
  /**如果是修改或绑定手机，需要传入手机对应的区号 */
  areaCode?: string
}

/**
 * **接口** [syncUdbInfo↗](https://apidoc.inshopline.com/#/project/Java/226/feature%2F20230914_3028_oneclub_account/interface/b75d8963141b771e1766be65dcf3da45)
 * @请求头 `POST /api/user/info/sync-udb-info`
 * @更新时间 `2023-12-12 20:33:14`
 */
export function postSyncUdbInfo(data?: SyncUdbInfoReq) {
  return http.normal.post(`/api/user/info/sync-udb-info`, data)
}
