import { useTranslation } from 'react-i18next'
import logoOS from '@common/oneclub/assets/imgs/logo-os.svg'
import logoOw from '@common/oneclub/assets/imgs/logo-ow.svg'
import osBanner from '@common/oneclub/assets/imgs/middle/middle-os.png'
import owBanner from '@common/oneclub/assets/imgs/middle/middle-ow.png'

export default function useAppConf() {
  const { t } = useTranslation()
  const SOURCE_CONF = {
    os: {
      logo: logoOS,
      banner: osBanner,
      middle: {
        tip: t('middle.oneship.title'),
      },
      visible: true,
    },
    osvn: {
      logo: logoOS,
      banner: osBanner,
      middle: {
        tip: t('middle.oneship.title'),
      },
      visible: true,
    },
    ow: {
      logo: logoOw,
      banner: owBanner,
      middle: {
        tip: t('middle.onewarehouse.title'),
      },
      visible: true,
    },
    og: {
      logo: logoOS,
      banner: osBanner,
      middle: {
        tip: t('middle.oneship.title'),
      },
      visible: false,
    },
  }
  return {
    config: SOURCE_CONF,
  }
}
