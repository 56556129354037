import React from 'react'
import { AsyncProvider } from '@/components'

export const AsyncConfiguration: React.FC = React.memo((props) => {
  const { children } = props

  return <AsyncProvider value={{}}>{children}</AsyncProvider>
})

AsyncConfiguration.displayName = 'AsyncConfiguration'
