import * as Types from '@common/oneclub/typings'
import { options2Map } from '@common/oneclub/utils/options2map'
import logo711 from '@common/oneclub/assets/imgs/711.png'
import logofmt from '@common/oneclub/assets/imgs/fmt.png'
import logohct from '@common/oneclub/assets/imgs/hct.png'
import logoups from '@common/oneclub/assets/imgs/ups.png'

export const COMMODITY_TYPE_OPTIONS = [
  {
    label: 'kycInfo.options.apparel', //服饰
    value: 'costume',
  },
  {
    label: 'kycInfo.options.jewelry', // 饰品
    value: 'decoration',
  },
  {
    label: 'kycInfo.options.makeups', // 美妆
    value: 'makeup',
  },
  {
    label: 'kycInfo.options.food', // 食品
    value: 'food',
  },
  {
    label: 'kycInfo.options.3C', // 3C
    value: '3c',
  },
  {
    label: 'kycInfo.options.life', // 生活
    value: 'life',
  },
  {
    label: 'kycInfo.options.other', // 其他
    value: 'other',
  },
]

/** 周日期 */
export const WEEK_DAY_OPTIONS = [
  {
    label: 'kycInfo.subaccount.Mon',
    value: 1,
  },
  {
    label: 'kycInfo.subaccount.Tues',
    value: 2,
  },
  {
    label: 'kycInfo.subaccount.Wed',
    value: 3,
  },
  {
    label: 'kycInfo.subaccount.Thur',
    value: 4,
  },
  {
    label: 'kycInfo.subaccount.Fri',
    value: 5,
  },
]

export const RETURN_DAY_CODE = {
  TUES: 2,
  WED: 3,
  THUR: 4,
  FRI: 5,
} as const

/** 退货周期日编码 */
export const RETURN_DAY_OPTIONS = [
  {
    label: '周二：大榮 新竹貨運',
    value: RETURN_DAY_CODE.TUES,
  },
  {
    label: '週三：其他貨運或自取',
    value: RETURN_DAY_CODE.WED,
  },
  {
    label: '週四：黑貓宅急便',
    value: RETURN_DAY_CODE.THUR,
  },
  {
    label: '週五：大嘴鳥宅配通',
    value: RETURN_DAY_CODE.FRI,
  },
]

export const RETURN_CYCLE_CODE = {
  BY_WEEK: '0',
  BY_DAY: '1',
  TODAY: '2',
} as const

/** 退货周期编码 */
export const RETURN_CYCLE_OPTIONS = [
  {
    label: 'Byweek每週',
    value: RETURN_CYCLE_CODE.BY_WEEK,
  },
  {
    label: 'byday日退(隔天收退)',
    value: RETURN_CYCLE_CODE.BY_DAY,
  },
  {
    label: '當日退(進貨時收退)',
    value: RETURN_CYCLE_CODE.TODAY,
  },
]

export const RETURN_CYCLE_OPTIONS_MAP = {
  FMTB2C: {
    [RETURN_CYCLE_CODE.BY_WEEK]: WEEK_DAY_OPTIONS,
    [RETURN_CYCLE_CODE.BY_DAY]: WEEK_DAY_OPTIONS,
  },
  SEVEN_ELEVEN_B2C: {
    [RETURN_CYCLE_CODE.BY_WEEK]: RETURN_DAY_OPTIONS,
  },
}

/** 退货方式编码 */
export const RETURN_MODE_CODE = {
  SELF: '1', // 自行取货
  REYI: '2', // 全家日翊物流
  TCAT: '3', // 黑猫宅急便
  PELICAN: '4', // 大嘴鸟宅配通
  HCT: '5', // 新竹货运
  KERRY: '6', // 大荣货运
  OTHER: '9', // 其他
} as const

export type RETURN_DAY_CODE_TYPE = Types.ValueOf<typeof RETURN_MODE_CODE>

/** 退货方式(全家) */
export const RETURN_MODE_FMT_OPTIONS = [
  { value: RETURN_MODE_CODE.SELF, label: '自行取貨' },
  { value: RETURN_MODE_CODE.REYI, label: '全家日翊物流' },
  { value: RETURN_MODE_CODE.TCAT, label: '統一速達(宅急便)' },
  { value: RETURN_MODE_CODE.PELICAN, label: '宅配通' },
  { value: RETURN_MODE_CODE.HCT, label: '新竹貨運' },
  { value: RETURN_MODE_CODE.KERRY, label: '大榮貨運' },
  { value: RETURN_MODE_CODE.OTHER, label: '其他貨運' },
]

/** 退货方式（711） */
export const RETURN_MODE_711_OPTIONS = [
  { value: RETURN_MODE_CODE.SELF, label: '自行取貨' },
  { value: RETURN_MODE_CODE.TCAT, label: '黑貓宅急便' },
  { value: RETURN_MODE_CODE.PELICAN, label: '大嘴鳥宅配通' },
  { value: RETURN_MODE_CODE.HCT, label: '新竹貨運' },
  { value: RETURN_MODE_CODE.KERRY, label: '大榮貨運' },
  { value: RETURN_MODE_CODE.OTHER, label: '其他貨運' },
]

/** 服务申请类型 */
export const APPLY_TYPE_ENUM = {
  KYC: 'seller_kyc',
  SUBACCOUNT: 'express_account',
} as const

export const SERVICE_TYPE_MAP = {
  [APPLY_TYPE_ENUM.KYC]: 'service.seller.apply',
  [APPLY_TYPE_ENUM.SUBACCOUNT]: 'service.seller.subaccount.apply',
}

export type APPLY_TYPE_ENUM_TYPE = Types.ValueOf<typeof APPLY_TYPE_ENUM>

/** 711 */
export const SEVEN_ELEVEN_B2C = 'SEVEN_ELEVEN_B2C'

/** 全家 */
export const FMTB2C = 'FMTB2C'

/** 新竹 */
export const HCT = 'HCT'

export const UPS = 'UPS'

/** 服务商类型 */
export const SUBACCOUNT_COMPANY_OPTIONS = [
  {
    label: '全家B2C',
    value: FMTB2C,
    supportCountryCodes: ['TW'],
    logo: logofmt,
  },
  {
    label: '711B2C',
    value: SEVEN_ELEVEN_B2C,
    supportCountryCodes: ['TW'],
    logo: logo711,
  },
  {
    label: '新竹',
    value: HCT,
    supportCountryCodes: ['TW'],
    logo: logohct,
  },
  {
    label: 'UPS',
    value: UPS,
    logo: logoups,
  },
]

export const SUBACCOUNT_COMPANY_MAPS = options2Map(SUBACCOUNT_COMPANY_OPTIONS, 'value', 'label')

/** 申请服务menu支持的国家 */
export const KYC_MEMU_COUNTRY = ['CN', 'MY', 'SG', 'TH', 'VN', 'HK', 'TW', 'US', 'AU']

/** 能选kyc的国家 */
export const KYC_SELECT_SERVICE_TYPE_COUNTRY = ['CN', 'MY', 'SG', 'TH', 'VN', 'HK', 'TW']

/** 能申请服务子账号的国家 */
export const SUBACCOUNT_COUNTRIES = ['MY', 'SG', 'TH', 'VN', 'HK', 'TW', 'US', 'AU']
