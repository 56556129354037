import React from 'react'
import { Redirect } from 'react-router'
import { renderRoutes, RouteConfig } from 'react-router-config'
import { absRoutes } from '@common/oneclub/utils'
import user from './user'
import blankLayout from './user/blankLayout'
import universal from './universal'
import ow from './ow'
import admin from './admin'
import { NotFound } from '@/containers/NotFound'

export function addNotMatchRoute(routes: RouteConfig[]): RouteConfig[] {
  const newRoutes = routes.map((item) => {
    if (item.routes && item.routes.length > 0) {
      item.routes.push({
        path: '/*',
        component: () => <Redirect to="/middle" />,
        exact: true,
      })
      return { ...item, routes: addNotMatchRoute(item.routes) }
    } else {
      return item
    }
  })

  return newRoutes
}

export const routes = (
  [
    {
      name: '404',
      path: '/404',
      component: NotFound,
      exact: true,
    },
  ] as RouteConfig[]
).concat(...absRoutes([...admin]), ...addNotMatchRoute(absRoutes([...blankLayout, ...user, ...ow, ...universal])))

const Router: React.FC = () => {
  return renderRoutes(routes)
}

Router.displayName = 'Router'
export default Router
