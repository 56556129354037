import { Dropdown } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { DownloadCloud } from '@yy/one-icon'
import { OneBadge } from '@yy/one-ui'
import { useDispatch, useSelector } from '@common/oneclub/store'
import { notificationActions, queryDownloadList } from '@common/oneclub/store/notificationSlice'
import MessageCenter from '../MessageCenter'
import { DownLoadNotification } from '../Notification'
import styles from './index.module.less'

const Download: React.FC<{ hasWallet?: boolean }> = ({ hasWallet }) => {
  const dispatch = useDispatch()
  const downloadCount = useSelector((state) => state.notification.unReadDownloadCount)

  const currentSocketData = useSelector((state) => state.notification.downloadInfo.currentSocketData)

  const handleOpen = (visible: boolean) => {
    if (visible) {
      dispatch(notificationActions.setSocketDownloadList([]))
      dispatch(queryDownloadList({ pageSize: 20 }))
    }
  }

  return (
    <>
      <div id="parabola-export" data-id="download_icon">
        <Dropdown
          trigger={['click']}
          placement="bottom"
          overlay={<MessageCenter count={downloadCount} type="download"></MessageCenter>}
          onVisibleChange={handleOpen}
          overlayClassName={styles.overlay}
          destroyPopupOnHide
        >
          <OneBadge
            color="primary"
            count={downloadCount}
            className={classNames(styles.download, {
              [styles.withWallet]: hasWallet,
            })}
          >
            <DownloadCloud className={styles.icon} />
          </OneBadge>
        </Dropdown>
      </div>
      {/* 下载中心消息通知 */}
      {currentSocketData && <DownLoadNotification messageItem={currentSocketData} />}
    </>
  )
}

Download.displayName = 'Download'
export default Download
