import { withAsync } from '@/components/Async'
import type { RouteConfig } from 'react-router-config'
import OWLayout from '@/layouts/OWLayout'

const routes: RouteConfig[] = [
  {
    path: '/ow',
    component: OWLayout,
    routes: [
      {
        name: 'kycApply',
        path: '/kycApply',
        component: withAsync(() => import(/* webpackChunkName: "login" */ '@/containers/ow/KycApply')),
        exact: true,
      },
      {
        name: 'kycApplyCompleted',
        path: '/kycApply/completed',
        component: withAsync(() => import(/* webpackChunkName: "login" */ '@/containers/ow/kycApplyCompleted')),
        exact: true,
      },
    ],
  },
]

export default routes
