import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Menu } from 'antd'
import { ChevronLeft, Tick } from '@yy/one-icon'
import { useDispatch, useSelector } from '@common/oneclub/store'
import { changeLanguage } from '@common/oneclub/store/languageSlice'
import type { MenuProps } from 'antd'
import type { MenuInfo } from 'rc-menu/lib/interface'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import { LanguageChangedEvents } from '@common/oneclub/i18n'
import styles from './index.module.less'

export interface LanguageMenuProps extends Omit<MenuProps, 'items'> {
  onBackClick?(event: MenuInfo): void
}

export const LanguageMenu: React.FC<LanguageMenuProps> = (props) => {
  const { onBackClick, onClick, ...restProps } = props
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation()

  const languageStore = useSelector((state) => state.language)
  const languageList = useSelector((state) => state.user.languageList)

  const onActions = useCallback(
    (info: MenuInfo) => {
      const { key } = info
      const lang = languageList.find((item) => item?.languageType === key)
      if (lang) {
        dispatch(changeLanguage(lang))
        i18n.changeLanguage(lang.lang)

        try {
          Array.from(LanguageChangedEvents).forEach((trigger) => trigger(lang.lang))
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }
        return
      }

      try {
        if (typeof onClick === 'function') {
          onClick(info)
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }

      try {
        if (typeof onBackClick === 'function') {
          switch (key) {
            case 'back':
              onBackClick(info)
              break
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    [onClick, onBackClick, languageList, i18n, dispatch]
  )

  const items = useMemo<ItemType[]>(() => {
    return [
      {
        key: 'back',
        label: (
          <div className={styles.back}>
            <ChevronLeft className={styles.icon} />
            <span>{t('wallet.login.back')}</span>
          </div>
        ),
      },
      ...languageList.filter(Boolean).map((lang) => {
        return {
          key: lang.languageType,
          className: `${lang.languageType === languageStore.languageType ? styles.active : ''}`,
          label: (
            <>
              <span className={styles.text}>{lang.label}</span>
              {lang.languageType === languageStore.languageType ? <Tick style={{ fontSize: 16, marginRight: '16px' }} /> : null}
            </>
          ),
        }
      }),
    ]
  }, [languageStore, languageList, t])

  return <Menu className={styles.root} {...restProps} onClick={onActions} items={items} />
}

LanguageMenu.displayName = 'LanguageMenu'
