import React, { useRef, useEffect } from 'react'
import useScript from 'react-script-hook'
import { APP_CODE } from '@common/oneclub/constants'

interface ICaptchaProps {
  visible: boolean
  /** 多语言配置 */
  lang: string
  /** 验证成功回调 */
  onSuccess: FnType
  /** html 中容器元素的 ID，需保证存在 */
  id?: string
  className?: string
}
const CDN_OPTIONS = {
  src: `https://r2cdn.myshopline.com/static/rs/acuf/prod/latest/bundle.iife.js?t=${new Date().getTime()}`,
  checkForExisting: true,
}

const Captcha: React.FC<ICaptchaProps> = (props) => {
  const { visible, lang, onSuccess, id = 'captcha-default-id', className } = props
  const captchaInstants = useRef<IArmorCaptcha>(null)
  const [loading] = useScript(CDN_OPTIONS)
  const onSuccessRef = useRef(null)
  onSuccessRef.current = onSuccess
  // 初始化滑块验证码
  useEffect(() => {
    if (loading || !visible) return
    captchaInstants.current = new ArmorCaptcha({
      wrapId: id,
      origin: process.env.CAPTCHA_URL,
      appCode: APP_CODE,
      captchaScene: 'oneclub',
      onSuccess: onSuccessRef.current,
    })
    captchaInstants.current.changeLanguage(lang)
    return () => captchaInstants.current.destroy()
  }, [id, loading, visible, lang])

  // 切换语言
  useEffect(() => {
    if (visible) {
      captchaInstants.current && captchaInstants.current.changeLanguage(lang)
    }
  }, [lang, visible])

  return <>{visible && <div id={id} className={className}></div>}</>
}

Captcha.displayName = 'Captcha'
export default Captcha
