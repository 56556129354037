import React from 'react'
import { useTranslation } from 'react-i18next'

interface OptionalLabelProps {
  label: string
  required?: boolean
}

export const OptionalLabel: React.FC<OptionalLabelProps> = ({ label, required = false }) => {
  const { t } = useTranslation()
  return <span>{`${label}${required ? '' : t('kycInfo.optional')}`}</span>
}

export default OptionalLabel
