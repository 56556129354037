import type * as Types from '@common/oneclub/types'
import { getRecentDay } from '../utils/common'
import { options2Map } from '../utils/options2map'

export type DateSelect = 'all' | 1 | 3 | 7 | 30

/** 钱包账户对应地区的多语言的key*/
export const WALLET_COUNTRY_NAME = {
  SG: 'wallet.accountSG',
  MY: 'wallet.accountMY',
  TH: 'wallet.accountTH',
  VN: 'wallet.accountVN',
  HK: 'wallet.accountHK',
  CN: 'wallet.accountCN',
  TW: 'wallet.accountTW',
  ID: 'wallet.accountID',
  US: 'wallet.accountUS',
  AU: 'wallet.accountAU',
}

// 优惠方案: 满赠
export enum PROMOTION_TYPE_ENUM {
  GIVE = 'premium_zon',
}

// 订阅sdk参数
export const SETTLE_MENT_SDK = {
  BIZTYPE: 'ONECLUB',
  SUB_BIZTYPE: 'ONESHIP',
}

export const SETTLE_MENT_SDK_ENV_MAP: Record<string, 'dev' | 'test' | 'prev' | 'prod'> = {
  develop: 'dev',
  test: 'test',
  preview: 'prev',
  product: 'prod',
}

export type PayMethodType = {
  payMethod: 'Wechat' | 'CreditCard'
  channelId: 'wechat' | 'stripe'
}

export const PAY_CHANNEL_LIST: PayMethodType[] = [
  { payMethod: 'Wechat', channelId: 'wechat' },
  { payMethod: 'CreditCard', channelId: 'stripe' },
]

// 不支持线下充值的国家
export const NONSUPPORT_OFFLINE_TOPUP = ['CN', 'ID']

/**
 * 币种符号
 * 补充
 */
export const COUNTRY_SYMBOL_MAP = {
  MYR: 'RM',
  THD: '฿',
}

export const WALLET_FLOW_MAX_DAYS = 180

export const DATE_SELECT: Types.Dict<{ value: DateSelect; timeRange?: [number, number] }> = [
  {
    label: 'order.list.allTime',
    value: 'all',
  },
  {
    label: 'order.list.typeRangeLast1Day',
    value: 1,
    timeRange: getRecentDay(1),
  },
  {
    label: 'order.list.typeRangeLast3Day',
    value: 3,
    timeRange: getRecentDay(3),
  },
  {
    label: 'order.list.typeRangeLast7Day',
    value: 7,
    timeRange: getRecentDay(7),
  },
  {
    label: 'order.list.typeRangeLast30Day',
    value: 30,
    timeRange: getRecentDay(30),
  },
]

/**
 * 天数对应的时间戳范围
 */
export const DATE_SELECT_TIME_RANGE_MAP = options2Map<[number, number]>(
  DATE_SELECT.filter((item) => item.timeRange),
  'value',
  'timeRange'
)

/** 交易类型 */
type DealType =
  | 'deposit'
  | 'present'
  | 'pay'
  | 'correct'
  | 'refund'
  | 'compensate'
  | 'adjust_account'
  | 'froze'
  | 'unfroze'
  | 'unfreezepay'
  | 'credit_update'
  | 'priority_fee'
  | 'premium'
  | 'invalid'

export const DEAL_TYPE: Types.Dict<{ value: DealType }> = [
  {
    value: 'deposit',
    label: 'wallet.searchType.charge',
  },
  {
    value: 'present',
    label: 'wallet.searchType.present',
  },
  {
    value: 'correct',
    label: 'wallet.searchType.freightCorrect',
  },
  {
    value: 'refund',
    label: 'wallet.searchType.refund',
  },
  {
    value: 'compensate',
    label: 'wallet.searchType.compensate',
  },
  {
    value: 'adjust_account',
    label: 'wallet.searchType.adjust',
  },
  {
    value: 'unfreezepay',
    label: 'wallet.searchType.unfrozepay',
  },
  {
    value: 'credit_update',
    label: 'wallet.preCredit',
  },
  {
    value: 'invalid',
    label: 'wallet.invalid',
  },
  // {
  //   value: 'priority_fee',
  //   label: 'wallet.priorityFee',
  // },
  // {
  //   value: 'premium',
  //   label: 'insurance.amount.name',
  // },
]

const optionsMap = options2Map(DEAL_TYPE, 'value', 'label') // 选项的map

export const DEAL_LIST_TYPE = {
  ...optionsMap,
  auto_correct: 'wallet.searchType.freightCorrect',
  priority_fee: 'wallet.priorityFee',
  premium: 'insurance.amount.name',
}

/**
 * 钱包流水导出天数
 */
export const WALLET_EXPORT_DAYS = 180

/** 不能小数的币种 */
export const NO_DECIMALS_CURRENCY = ['TWD', 'VND', 'IDR']
