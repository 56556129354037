import React, { useState } from 'react'
import { Form, InputProps } from 'antd'
import classNames from 'classnames'
import { OneInput } from '@yy/one-ui'
import { withLabelFormItem, LabelHocProps } from '@common/oneclub/components'
import styles from './index.module.less'
import CountrySelectPlus from '../CountrySelectPlus'
import MobileStyleCountrySelect from '../MobileStyleCountrySelect'
import { useIsPc } from '../../Device/index'
import { Assign } from 'utility-types'

export interface MobileEmailInputWithoutValue {
  email?: string
  mobile?: string
}

export type MobileEmailInputWithoutLabelProps = Assign<
  InputProps,
  {
    inputType: string
    onBlurValidateAccType?: () => Promise<any>
    onChangeAreaCode?: (value: string) => void
    onCountryChange?: (value: string) => void
  }
>

export const valueFormat = (str: string) => str?.replace(/\+/, '')

export const MobileEmailInputWithoutLabel: React.FC<MobileEmailInputWithoutLabelProps> = (props) => {
  const [mouseOn, setMouseOn] = useState(false)
  const [focurs, setFocurs] = useState(false)
  const { className, inputType, onBlurValidateAccType, onChangeAreaCode, onCountryChange, ...restProps } = props
  const isPc = useIsPc()

  return (
    <>
      <OneInput
        {...restProps}
        className={classNames(
          {
            [styles.mobileInput]: true,
            [styles.focus]: !mouseOn && !focurs,
          },
          className
        )}
        onBlur={() => {
          setFocurs(false)
          onBlurValidateAccType?.()
        }}
        onMouseEnter={() => {
          setMouseOn(true)
        }}
        onMouseLeave={() => {
          setMouseOn(false)
        }}
        onFocus={() => {
          setFocurs(true)
        }}
        addonAfter={
          inputType === 'mobile' ? (
            <Form.Item shouldUpdate={(pre, next) => pre.areaCode !== next.areaCode}>
              {({ getFieldValue }) => {
                const areaCode = getFieldValue('areaCode')
                const mobileCountryCode = getFieldValue('mobileCountryCode')

                return isPc ? (
                  <CountrySelectPlus onCountryChange={onCountryChange} countryCode={mobileCountryCode} value={areaCode} onSelect={onChangeAreaCode} valueFormat={valueFormat} />
                ) : (
                  <MobileStyleCountrySelect
                    onCountryChange={onCountryChange}
                    countryCode={mobileCountryCode}
                    value={areaCode}
                    onSelect={onChangeAreaCode}
                    valueFormat={valueFormat}
                  />
                )
              }}
            </Form.Item>
          ) : null
        }
      />
    </>
  )
}

MobileEmailInputWithoutLabel.displayName = 'MobileEmailInput'

export const MobileEmailInput = withLabelFormItem<Assign<MobileEmailInputWithoutLabelProps, LabelHocProps>>(MobileEmailInputWithoutLabel)
