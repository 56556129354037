import type { Any, Options } from 'currency.js'
import currency from 'currency.js'
import { divide } from 'number-precision'
import { getTime, set, subDays } from 'date-fns'

/**
 * @param prefix 唯一ID前缀
 * @returns
 */
export const uuid = (prefix = '') => prefix + Math.random().toString(36).slice(-8)

/**
 * @param time 毫秒 默认2000
 * @returns
 */
export const sleep = (time = 2000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, time)
  })
}

/**
 * format currency without symbol
 */
export const formatCurrency = (value: Any, options?: Options) => {
  const precision = value?.toString().includes('.') ? 2 : 0
  const precisionNum = typeof options?.precision === 'number' ? options?.precision : precision

  return currency(value, {
    symbol: '',
    ...options,
    precision: precisionNum,
  }).format()
}

/**
 * return the currency format
 */
export const unFormatCurrency = (value: Any) => {
  return currency(value).value
}

/**
 *
 * @param amount
 * @param precision 小数点
 * @returns
 */
export function translateCurrency(amount: number, precision?: number) {
  return formatCurrency(divide(amount || 0, 100), { precision })
}

/**
 * 计算最近{day}天的时间范围
 * @param day 天数
 * @returns
 */
export function getRecentDay(day: number): [number, number] {
  const start = set(subDays(new Date(), Number(day) - 1), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  const end = set(new Date(), { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
  return [getTime(start), getTime(end)]
}
