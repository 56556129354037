import Cookies from 'js-cookie'
import sdk from '@yy/shopline-hiido-sdk'
import { traceState, genTraceParent, appId, appVer } from '@common/oneclub/utils/trace'

import type { AxiosRequestConfig, AxiosResponse } from 'axios'

/** hiido上报 Act */
const hiidoAct = 'apptracedetail'

/** hiido 上报通道域名 */
// const hiidoDomain = 'mlog.hiido.com';
const hiidoShoplineDomain = 'eclytics.shoplineapp.cn'

/** hiido上报 appId */
const hiidoAppId = appId

/** 默认概率 0 */
const chance = Cookies.get('trace_chance') || '0'

/**
 * trace上报
 * header中增加 traceParent 和 traceState
 * 配置中记录traceInfo response拦截上报数据使用
 */
export function setTraceInfo(options: any) {
  const hit = Math.random() < Number(chance)
  const traceFlag = hit ? '01' : '00'
  const traceParent = `${genTraceParent()}-${traceFlag}`
  options.traceInfo = {
    traceStart: Date.now(),
    traceParent,
    traceHit: hit,
  }

  options.headers = Object.assign(options.headers, { traceparent: traceParent, tracestate: traceState })
}

// 海度上报
const hiido = sdk(
  {
    domain: hiidoShoplineDomain,
    wait: 'nextTick',
  },
  {
    act: hiidoAct,
    prodid: hiidoAppId,
    eventid: 123, // 这个随意
  }
)

interface TraceInfo {
  traceHit?: string
  traceParent?: string
  traceStart?: number
}

interface TraceConfig {
  traceInfo: TraceInfo
}

// 对每个请求response进行上报
export function hiidoReport(response: AxiosResponse) {
  if (process.env.NODE_ENV === 'production') {
    const { status, config } = response
    const { traceInfo } = config as AxiosRequestConfig & TraceConfig

    if (traceInfo.traceHit) {
      hiido.report({
        appid: hiidoAppId,
        appver: appVer,
        traceparent: traceInfo.traceParent,
        tracestate: traceState,
        retCode: status,
        startTime: traceInfo.traceStart,
        totalDur: (Date.now() - traceInfo.traceStart) * 1000,
      })
    }
  }
}
