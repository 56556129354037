import { omit } from 'lodash'

export interface MenuDataItem {
  authority?: string[] | string
  children?: MenuDataItem[]
  hideChildrenInMenu?: boolean
  hideInMenu?: boolean
  icon?: React.FC<any>
  locale?: string
  name?: string
  key?: string
  path?: string
  [key: string]: any
  parentKeys?: string[]
}

/**
 * 将地址转成 ant layout 的配置
 * @param routes 路由配置
 */
export function antRoutes(routes: MenuDataItem[] = []): MenuDataItem[] {
  if (!Array.isArray(routes)) {
    return []
  }

  const validRoutes = routes.filter((route) => route.menuTitle)
  if (validRoutes.length === 0) {
    return []
  }

  return validRoutes.map((route) => {
    const antRoute = {
      ...omit(route, ['routes']),
      name: route.menuTitle,
      path: route.path,
    }

    if (Array.isArray(route.routes) && route.routes.length > 0) {
      return {
        ...antRoute,
        children: antRoutes(route.routes),
      }
    }

    return antRoute
  })
}
