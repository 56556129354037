import { useEffect, useState } from 'react'
import { getUrlParam } from '@common/oneclub/utils'
import { LANGUAGE, APP_LANG } from '../constants'

export default function useAdaptLanguage() {
  const app = getUrlParam('app')
  const [langList, setLangList] = useState(LANGUAGE)

  useEffect(() => {
    if (app) {
      const list = LANGUAGE.filter((item) => !item.hidden).filter(({ lang }) => APP_LANG[app]?.includes(lang))
      setLangList(list)
    } else {
      setLangList(LANGUAGE)
    }
  }, [app])

  return {
    LANGUAGE_LIST: langList,
  }
}
