import Cookies from 'js-cookie'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getLanguageList } from '@common/oneclub/hooks/useLanguage'
import type { AppThunkDispatch } from '@common/oneclub/store'
import type * as Types from './types'
import { getSellerBasic } from '@common/oneclub/services/api'

const initialState: Types.UserInitState = {
  osudb_uid: Cookies.get('osudb_uid') || '',
  osudb_subappid: Cookies.get('osudb_subappid') || '',
  languageList: [],
  adminTicket: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserInfo(state, action: PayloadAction<Types.UserInfo>) {
      const newState = {
        ...state,
        info: {
          ...action.payload,
        },
      }

      return newState
    },
    setLanguageList(state, action: PayloadAction<Types.UserInitState['languageList']>) {
      return {
        ...state,
        languageList: action.payload,
      }
    },
    /** 保存超管登录ticket */
    setAdminTicket(state, action: PayloadAction<string>) {
      return {
        ...state,
        adminTicket: action.payload,
      }
    },
  },
})

/** 获取用户信息 */
export const getUserInfo = () => async (dispatch: AppThunkDispatch) => {
  const { data: userInfo } = await getSellerBasic()
  const { countryCode } = userInfo || {}

  dispatch(
    userSlice.actions.setUserInfo({
      ...userInfo,
    })
  )

  dispatch(setLanguageList(getLanguageList(countryCode)))
  return userInfo
}

export const { setUserInfo, setLanguageList, setAdminTicket } = userSlice.actions
export default userSlice.reducer
