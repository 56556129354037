import { map, cloneDeep } from 'lodash'
import { RouteConfig } from 'react-router-config'

/**
 * 遍历路由配置
 * @param routes 路由
 * @param handle 每个路由配置的处理方法
 */
export function travelRoutes(routes: RouteConfig[], handle: (routes: RouteConfig) => RouteConfig): RouteConfig[] {
  if (typeof handle !== 'function') {
    return []
  }

  const nextRoutes = map(routes, (route) => {
    const clonedRoute: RouteConfig = cloneDeep(route)
    if (clonedRoute.routes && clonedRoute.routes.length > 0) {
      clonedRoute.routes = travelRoutes(clonedRoute.routes, handle).filter(Boolean)
    }

    return handle(clonedRoute)
  })

  return nextRoutes.filter(Boolean)
}
