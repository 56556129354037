import { getUrlParam } from './getUrlParam'
const dataLayer = (window as any).dataLayer
const customDataLayer = (window as any).customDataLayer
const NEED_GTA = ['os', 'osvn']
/**
 * 没有 code 的情况不推送
 * @param data
 * @returns
 */
export function dataLayerPush(data: Record<string, any>) {
  const needGta = getUrlParam('app') && NEED_GTA.includes(getUrlParam('app'))
  if (!needGta || typeof process.env.GTA_CODE !== 'string') {
    return
  }
  const pushData = { ...data, custom: true }
  customDataLayer?.push(pushData)
  dataLayer?.push(pushData)
}
