import { useSelector } from '@common/oneclub/store'
import { useEffect, useCallback, useMemo } from 'react'

export interface Locale {
  area: string
  language: string
}

let isCookieMaangerReady = false
let isInited = false
type CookieManagerReadyCallback = (sdk: OSCookieManager) => void

/** 就绪回调 */
export const onCookieManagerReady = (() => {
  const readyCallbacks = new Set<CookieManagerReadyCallback>()
  const SCRIPT_ID = 'cookiesdk'

  return (handle: CookieManagerReadyCallback) => {
    if (isCookieMaangerReady) {
      handle(window.OSCookieManager)
      return
    }

    if (typeof handle === 'function') {
      readyCallbacks.add(handle)
    }

    if (!document.getElementById(SCRIPT_ID)) {
      window.onOSCookieManagerReady = (instance) => {
        isCookieMaangerReady = true
        Array.from(readyCallbacks).forEach((fn) => fn(instance))
      }

      const node = document.createElement('script')
      node.id = SCRIPT_ID
      node.src = process.env.COOKIE_MANAGER_SDK
      document.body.appendChild(node)
    }
  }
})()

/**
 * 初始化 SDK
 * @description
 * 初始化完不会再次执行
 */
export const initCookieManager = (() => {
  let latestOptions: Locale

  return (options: Locale) => {
    if (isCookieMaangerReady) {
      return
    }

    latestOptions = options
    onCookieManagerReady(({ init }) => {
      isInited = true
      init(latestOptions)
      latestOptions = undefined
    })
  }
})()

/**
 * 修改本土化
 * @description
 * 未初始化只会执行最后一次
 */
export const changeCookieManagerLocal = (() => {
  let latestLocale: Locale

  return (options: Locale) => {
    if (isCookieMaangerReady) {
      window.OSCookieManager.changeLocale(options)
      return
    }

    latestLocale = options
    onCookieManagerReady(({ changeLocale }) => {
      changeLocale(latestLocale)
      latestLocale = undefined
    })
  }
})()

export const useCookieManagerChangeLocale = (locale: Locale) => {
  const { area, language } = locale || {}
  useEffect(() => {
    if (area === 'uk' && language) {
      !isInited && initCookieManager({ area, language })
      changeCookieManagerLocal({ area, language })
    }
  }, [area, language])
}

/** SDK */
export const useCookieSdk = () => {
  const countryCode = useSelector((state) => state?.user?.info?.countryCode)
  const language = useSelector((state) => state?.language?.languageType)
  const area = countryCode === 'GB' ? 'uk' : countryCode?.toLowerCase()
  useCookieManagerChangeLocale({ area, language })

  const openCookie = useCallback(() => {
    if (area === 'uk') {
      window.OSCookieManager.openCookieSettingsModal()
    }
  }, [area])

  return useMemo(() => ({ openCookie }), [openCookie])
}
