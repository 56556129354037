import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '@common/oneclub/store'
import * as Types from './type'
import { queryDictionary } from '@common/oneclub/services/api/dictionary'
import { GroupKeyType } from '@common/oneclub/services/api/dictionary/type'

const initialState: Types.InitialStateType = {
  dictionary: {},
}

const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState: initialState,
  reducers: {
    setDictionary(state, action: PayloadAction<Types.InitialStateType['dictionary']>) {
      const dictionary = state.dictionary
      return {
        ...state,
        dictionary: {
          ...dictionary,
          ...action.payload,
        },
      }
    },
  },
})

/** 获取字典配置 */
export const fetcDictionary = (group: GroupKeyType) => async (dispatch: AppDispatch) => {
  const { dictionaryInfos } = await queryDictionary({ group })
  dispatch(
    setDictionary({
      [group]: dictionaryInfos,
    })
  )
}
export const { setDictionary } = dictionarySlice.actions
export default dictionarySlice.reducer
