import React, { useState } from 'react'
import { Form, FormInstance, InputProps } from 'antd'
import classNames from 'classnames'
import { OneInput } from '@yy/one-ui'
import { withLabelFormItem, LabelHocProps, valueFormat } from '@common/oneclub/components'
import { useOneclubConfigs } from '@common/oneclub/configuration'
import CountrySelectPlus from '../CountrySelectPlus'
import MobileStyleCountrySelect from '../MobileStyleCountrySelect'
import styles from './index.module.less'
import { useIsPc } from '../../Device/index'

type IInputProps = InputProps & {
  formInstance?: FormInstance<any>
}

export const MobileInputNormal: React.FC<IInputProps> = ({ disabled, formInstance, ...props }) => {
  const [mouseOn, setMouseOn] = useState(false)
  const [focurs, setFocurs] = useState(false)
  const { country_list } = useOneclubConfigs()
  const isPc = useIsPc()

  const defaultAreaCode = valueFormat(country_list?.[0]?.areaCode)
  const defaultCountryCode = country_list?.[0]?.label

  return (
    <>
      <OneInput
        {...props}
        disabled={disabled}
        className={classNames({ [styles.mobileInput]: true, [styles.focus]: !mouseOn && !focurs })}
        onBlur={() => {
          setFocurs(false)
        }}
        onMouseEnter={() => {
          setMouseOn(true)
        }}
        onMouseLeave={() => {
          setMouseOn(false)
        }}
        onFocus={() => {
          setFocurs(true)
        }}
        addonAfter={
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const mobileCountryCode = getFieldValue('mobileCountryCode')

              return isPc ? (
                <Form.Item noStyle name="areaCode" initialValue={defaultAreaCode} rules={[{ required: true }]}>
                  <CountrySelectPlus
                    valueFormat={valueFormat}
                    countryCode={mobileCountryCode}
                    onCountryChange={(mobileCountryCode) => {
                      formInstance?.setFieldsValue({
                        mobileCountryCode,
                      })
                    }}
                  />
                </Form.Item>
              ) : (
                <Form.Item name="areaCode" noStyle initialValue={defaultAreaCode} rules={[{ required: true }]}>
                  <MobileStyleCountrySelect
                    valueFormat={valueFormat}
                    countryCode={mobileCountryCode}
                    onCountryChange={(mobileCountryCode) => {
                      formInstance?.setFieldsValue({
                        mobileCountryCode,
                      })
                    }}
                  />
                </Form.Item>
              )
            }}
          </Form.Item>
        }
      />
      <Form.Item name="mobileCountryCode" noStyle initialValue={defaultCountryCode} />
    </>
  )
}

MobileInputNormal.displayName = 'MobileInputNormal'
export const MobileInput = withLabelFormItem<IInputProps & LabelHocProps>(MobileInputNormal)
