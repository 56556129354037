import { List } from 'antd'
import { isEqual } from 'lodash'
import React, { useMemo, useState } from 'react'
import { ChevronDown, ChevronUp, FailureXCross, NotifySuccess, OneShip } from '@yy/one-icon'
import { OneSecondaryButton } from '@yy/one-ui'
import { useDispatch } from '@common/oneclub/store'
import { readMessage } from '@common/oneclub/store/notificationSlice'
import { useGetMessageInfo } from '@common/oneclub/hooks'
import styles from '../styles/listItem.module.less'
import type * as Types from '../type'
import { formatDateFn } from '@common/oneclub/utils'

// 站内信
const ListItem: React.FC<Types.IlistMessageItemType> = (props) => {
  const { onCheck, item } = props
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const { messageInfo, messageStatus, btnConfigs } = useGetMessageInfo({ messageItem: item, onClose: onCheck })
  const isReadClass = useMemo(() => (item.read ? styles.isRead : ''), [item.read])

  const itemIcon = useMemo(() => {
    if (messageStatus === 'normal') {
      return <OneShip origin />
    }

    if (messageStatus === 'success') {
      return <NotifySuccess origin />
    }

    return <FailureXCross origin />
  }, [messageStatus])

  const handleClick = () => {
    setOpen(!open)
    if (!item.read) {
      dispatch(readMessage(item.msgSeq))
    }
  }
  const createTime = useMemo(() => formatDateFn(item?.createTime), [item?.createTime])

  return (
    <div className={`${styles.itemWrap} ${isReadClass}`}>
      <List.Item onClick={handleClick}>
        <div style={{ width: '100%' }}>
          <div className={styles.item}>
            <div className={styles.left}>
              <span className={styles.itemIcon}>{itemIcon}</span>
              <span className={styles.title}>{(!!messageInfo?.suffix ? `【${messageInfo.suffix}】` : '') + messageInfo.title}</span>
            </div>
            {open ? <ChevronUp className={styles.icon} /> : <ChevronDown className={styles.icon} />}
          </div>
          <div className={styles.content} style={{ display: open ? 'block' : 'none' }}>
            <div className={styles.desc}>{messageInfo.content}</div>
            {messageInfo.content && messageInfo.content1 ? <div className={styles.desc}>{messageInfo.content1}</div> : null}
            {/* 操作按钮 */}
            <div className={styles['button-wrap']}>
              {btnConfigs.map((btn, ind) => (
                <OneSecondaryButton key={ind} className={styles.button} onClick={btn.onClick}>
                  {btn.btnText}
                </OneSecondaryButton>
              ))}
            </div>
          </div>
          {createTime && <div className={styles.timeline}>{createTime}</div>}
        </div>
      </List.Item>
    </div>
  )
}

export default React.memo(ListItem, (prev, next) => {
  const result = Object.keys(prev).every((key) => {
    if (key === 'onCheck' || key === 'item') {
      return isEqual(prev[key], next[key])
    }

    return true
  })

  return result
})
