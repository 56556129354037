import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AppDispatch } from '@common/oneclub/store'

import type * as Types from './types'
import { getSettleMentSdkToken } from '@common/oneclub/services/api'

const initialState: Types.IInitialState = {
  accessTokenInfo: {},
}

const subscribeSlice = createSlice({
  name: 'subscribeSlice',
  initialState,
  reducers: {
    seAccessTokenInfo(state, action: PayloadAction<Types.IInitialState['accessTokenInfo']>) {
      return {
        ...state,
        accessTokenInfo: action.payload,
      }
    },
  },
})

export const { seAccessTokenInfo } = subscribeSlice.actions

/** 获取订阅sdk token */
export const getSdkToken = () => async (dispatch: AppDispatch, getState) => {
  const { subscribeSlice } = getState()
  const { accessTokenInfo } = subscribeSlice

  // 还在有效时间内，不用请求
  if (accessTokenInfo.expireTime && accessTokenInfo.expireTime > new Date().getTime()) {
    return
  }

  const { data } = await getSettleMentSdkToken()
  dispatch(seAccessTokenInfo(data))
}

export default subscribeSlice.reducer
