/**
 * https://developers.google.com/identity/sign-in/web/reference
 * @param element
 * @param onSuccess
 * @param onError
 */
export function InitGoogleLogin(element: HTMLElement, onSuccess) {
  // 没翻墙可能加载不到google sdk
  if (!window.gapi) return
  window.gapi.load('auth2', function () {
    const auth2 = window.gapi.auth2.init({
      client_id: process.env.GOOGLE_CLIENT_ID,
      cookiepolicy: 'single_host_origin',
      fetch_basic_profile: false,
      scope: 'https://www.googleapis.com/auth/userinfo.email',
    })
    auth2.attachClickHandler(element, {}, onSuccess)
  })
}

/**
 * 初始化谷歌登录，await 返回GoogleAuth对象
 * 参考 https://developers.google.com/identity/sign-in/web/reference?hl=zh-cn#gapiauth2initparams
 * @returns
 */
export function InitGoogle() {
  return new Promise((res, ret) => {
    // 没翻墙可能加载不到google sdk
    if (!window.gapi) return ret('gapi is undefined')
    window.gapi.load('auth2', function () {
      const auth2 = window.gapi.auth2.init({
        client_id: process.env.GOOGLE_CLIENT_ID,
        cookiepolicy: 'single_host_origin',
        fetch_basic_profile: false,
        scope: 'https://www.googleapis.com/auth/userinfo.email',
      })
      auth2.then(() => res(auth2), ret)
    })
  })
}

// https://accounts.google.com/o/oauth2/auth?scope=https://www.googleapis.com/auth/userinfo.email&response_type=code&redirect_uri=http://localhost:3000/user/signIn&client_id=219086120507-762fs7bufad2n07jo1k1qasqlv176a42.apps.googleusercontent.com

/**
 * https://developers.facebook.com/docs/javascript/
 */
export function InitFacebookLogin() {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.FACEBOOK_CLIENT_ID,
      cookie: true,
      xfbml: true,
      version: 'v13.0',
    })

    window.FB.AppEvents.logPageView()
  }
}
