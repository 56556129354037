import { tracker } from '@yy/jdp-tracker'
import { PROJECT_ID, ACT } from '@common/oneclub/constants/tracker'
import { EVENT_IDS } from '../constants'
import { getQuery, getQueryWithoutDecode } from './getUrlParam'
import { getDeviceToken } from './deviceFingerprint'

export const appId = 'shopline'
export const appVer = '3.4'
export const traceState = `appid=${appId},appver=${appVer}`

const sessionStorageKey = 'jdp-tracker-commonData'
const urlQuery = getQuery() as any

/**从环境获取的公共字段 */
const commonData: { utm_source?: string; shop_id?: string; device_id?: string; referral_url?: string } = {
  ...getQueryWithoutDecode(window.sessionStorage.getItem(sessionStorageKey) || ''),
}
if (urlQuery.utm_source) commonData.utm_source = urlQuery.utm_source
if (urlQuery.shop_id) commonData.shop_id = urlQuery.shop_id
if (document.referrer) commonData.referral_url = document.referrer
getDeviceToken().then((id) => {
  commonData.device_id = id
  window.sessionStorage.setItem(
    sessionStorageKey,
    '?' +
      Object.keys(commonData)
        .map((key) => `${key}=${commonData[key]}`)
        .join('&')
  )
})

interface ParamsType {
  act?: string
  /** 客户端时间(Unix时间戳) */
  time?: number
  key?: string // 数据完整校验码
  register_status: boolean // 注册状态
  email: string // 邮箱
  phone: string // 手机号
  thirdpart_account: string // 第三方账号
  referral_url?: string // 跳转至当前页的上一 URL
  event_id?: string // 事件id
  event_name?: string // 事件名
  action_type?: string // 行为名称
  env?: 'DEV' | 'TEST' | 'FAT' | 'PRO' // 环境标识
  device?: string // 设备类型
  seller_id?: string // 注册成功后的seller_id
}

interface ExtraParams extends ParamsType {
  app: string
}

/**
 * 生成 traceParent
 * 主要用于 trace 上报
 */
export function genTraceParent() {
  const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
  const version = '00'
  /** 上报抽样 01 否则 00 */
  // const traceFlag = '01'

  let traceId = ''
  let parentId = ''
  let count = 0

  while (count < 32) {
    // 16位 十六进制字符串
    if (count < 16) {
      parentId += arr[Math.floor(Math.random() * 16)]
    }

    // 32位 十六进制字符串
    traceId += arr[Math.floor(Math.random() * 16)]
    count++
  }

  return `${version}-${traceId}-${parentId}`
}

export const getEnv = () => {
  switch (process.env.APP_ENV) {
    case 'develop':
      return 'DEV'
    case 'test':
      return 'TEST'
    case 'product':
      return 'PRO'
    case 'preview':
      return 'FAT'
    default:
      return 'DEV'
  }
}

export const trackerReport = async ({ app, event_name = '', action_type = 'click', ...rest }: ExtraParams) => {
  try {
    /**
     * 初始化上报act
     */
    tracker.setOptions({
      beforeSend: (params) => {
        ;(params.act = ACT), // 业务方自定义act，申请地址：https://jdp.eclytics.com/act/apply
          (params.project_id = PROJECT_ID) // 产品标识,跟聚数埋点系统产品标识对应
        return params
      },
      queryParams: {},
    })

    if (!app) return

    /**
     * 具体事件上报逻辑
     */
    tracker.report({
      event_id: /^os/i.test(app) ? EVENT_IDS.OS : EVENT_IDS.OW,
      event_name,
      key: '',
      env: getEnv(),
      action_type,
      time: Math.floor(new Date().getTime() / 1000), // 聚数那边的单位是 秒
      from: urlQuery.from,
      ...commonData,
      ...rest,
    })
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('error:', e)
  }
}
