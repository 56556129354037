import React from 'react'
import classNames from 'classnames'
import styles from './index.module.less'
import logo from '@common/oneclub/assets/imgs/logo-os.svg'

import { LanageSelector } from '@/components/LanageSelector'

interface Iprops {
  className?: string
  leftLogo?: string
}
const TopBar: React.FC<Iprops> = (props) => {
  const { className, leftLogo = logo } = props

  return (
    <div className={classNames(styles.header, className)}>
      <img src={leftLogo} alt="oneship" className={styles.logo} />
      <LanageSelector></LanageSelector>
    </div>
  )
}

export default TopBar
