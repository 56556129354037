import React from 'react'
import { withAsync } from '@/components/Async'
import { Redirect } from 'react-router'
import type { RouteConfig } from 'react-router-config'
import UserLayout from '@/layouts/UserLayout'

const routes: RouteConfig[] = [
  {
    path: '/user',
    component: UserLayout,
    routes: [
      {
        name: 'login',
        path: '/login',
        component: withAsync(() => import(/* webpackChunkName: "login" */ '@/containers/Login')),
        exact: true,
      },
      {
        name: 'register',
        path: '/register',
        component: withAsync(() => import(/* webpackChunkName: "register" */ '@/containers/Register')),
        exact: true,
      },
      {
        name: 'invite',
        path: '/invite',
        component: withAsync(() => import(/* webpackChunkName: "Invite" */ '@/containers/Invite')),
        exact: true,
      },
      {
        name: 'forgot',
        path: '/forgot',
        component: withAsync(() => import(/* webpackChunkName: "forgot" */ '@/containers/Forgot')),
        exact: true,
      },
      // {
      //   name: 'EmailExits',
      //   path: '/email-exits',
      //   component: withAsync(() => import(/* webpackChunkName: "EmailExits" */ '@/containers/EmailExits')),
      //   exact: true,
      // },
      {
        path: '/changePassword',
        component: withAsync(() => import(/* webpackChunkName: "Welcome/Login" */ '@/containers/ChangePassword')),
        exact: true,
      },
      {
        path: '/thirdBindEmail',
        component: withAsync(() => import(/* webpackChunkName: "User/ThirdBindEmail" */ '@/containers/ThirdBindEmail')),
        exact: true,
      },
      {
        path: '/thirdMerge',
        component: withAsync(() => import(/* webpackChunkName: "User/ThirdMerge" */ '@/containers/ThirdMerge')),
        exact: true,
      },
      {
        path: '/thirdBindCountry',
        component: withAsync(() => import(/* webpackChunkName: "User/ThirdBindCountry" */ '@/containers/ThirdBindCountry')),
        exact: true,
      },
      {
        path: '/',
        component() {
          return <Redirect to="/user/login" />
        },
        exact: true,
      },
    ],
  },
]

export default routes
