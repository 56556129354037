import React, { useState } from 'react'
import { useDispatch } from '@common/oneclub/store'
import { getUserInfo } from '@common/oneclub/store/userSlice'
import { PageLoading } from '@common/oneclub/components'
import { useMount } from 'ahooks'
import { checkLogin, getBaseUserInfo } from '@common/oneclub/store/authSlice'

/** 用户数据获取 */
export const withAuthCheck = <P extends Record<string, any>>(Component: React.FC<P>) => {
  const AuthCheck: React.FC<P> = (props) => {
    const dispatch = useDispatch()

    const [isReady, setIsReady] = useState(false)

    useMount(async () => {
      setIsReady(false)
      await Promise.all([dispatch(getBaseUserInfo(true)), dispatch(getUserInfo()), dispatch(checkLogin())])
      setIsReady(true)
    })

    return isReady ? <Component {...props} /> : <PageLoading />
  }

  AuthCheck.displayName = 'AuthCheck'
  return AuthCheck
}
