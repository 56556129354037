/**
 * udb 文档
 * https://aurogon.yuque.com/docs/share/59a147bf-3c67-4f80-bbe2-6480c93a6623?#
 */
export default {
  checkLogin: '/udb/cas/loginCheck.do',
  /** 用户权限 */
  authority: '/api/Authority',
  /** 用户地址信息总览 */
  addressOverview: '/api/user/address/overview',
  subAccount: '/api/user/sub_account/get',
  login: '/udb/lgn/login/verify.do',
  init: '/udb/lgn/login/init.do',
  verifyLoginCode: '/udb/lgn/login/sendSms.do',
  smsVerify: '/udb/lgn/login/verifySms.do',

  // 邀请注册
  enterpriseInit: '/udb/aq/pwd/reg/init.do', // 企业注册初始化
  enterpriseRegister: '/udb/aq/pwd/reg/modify.do', // 邮箱注册

  // 个人注册
  registerInit: '/udb/reg/registermix/init.do', //初始化链接
  checkEmial: '/udb/reg/register/checkacct.do', // 校验格式，判断输入帐号信息是否已注册
  sendVerifyCode: '/udb/reg/register/sendverifycode.do', // 根据输入信息，发送手机或者邮箱注册验证码
  register: '/udb/reg/registermix/regcore.do', // 注册验证

  /** 忘记密码 */
  forgetInit: '/udb/aq/pwd/retrieve/init.do',
  /** 检查账号 */
  forgetPrecheck: '/udb/aq/pwd/retrieve/prechk.do',
  /** 发送邮箱验证码 */
  sendEmail: '/udb/aq/uni/sendEmailCode.do',
  /** 验证邮箱验证码 */
  verifyEmailCode: '/udb/aq/uni/verifyEmailCode.do',
  /** 修改密码 */
  modifyPwd: '/udb/aq/pwd/retrieve/modify.do',

  /** 设置用户国家地区 */
  setCountry: '/oneship_user_info_country_change',

  /**  */
  settingList: '/api/user/setting/list',
}
