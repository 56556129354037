import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import language from './languageSlice'
import auth from './authSlice'
import user from './userSlice'
import address from './addressSlice'
import notification from './notificationSlice'
import subscribeSlice from './subscribeSlice'
import dictionarySlice from './dictionarySlice'

const history = createBrowserHistory()
const reducers = {
  language,
  auth,
  user,
  address,
  notification,
  subscribeSlice,
  dictionarySlice,
}

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history),
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
