import { compose } from './compose'
import { defaultsDeep, cloneDeep } from 'lodash'
import Cookies from 'js-cookie'
import { LANGUAGE } from '@common/oneclub/constants'
import { isCancelOrAbsorted } from './create'
import { setTraceInfo, hiidoReport, adminSentryReport, udbSentryReport } from './reports'
import { takeLatest } from './extensions'
import { formatHumpLineTransfer } from './humps'
import type { AxiosRequestConfig, AxiosInstance, AxiosResponse } from 'axios'

import type { Assign } from 'utility-types'
import type { MapTakeLatest } from './extensions'

export * from './reports'
export * from './types'

/**
 * 因为拦截器将返回参数修正
 * 因此这里需重写部分域的调用函数,
 * 因为TS是声明式, 所以不建议通过JS来实现方法重写
 * 所以有以下重写方式
 */

/** 网关返回 data 数据格式 */
type GatwayData = {
  isSuccess: boolean
  code?: string
  message?: string
  extMap?: any
}

/** 网关返回 body 数据格式 */
type GatwayBody<T extends Record<string, any> = any> = {
  data: Assign<GatwayData, T>
  code: string
  message: string
  bizCode?: string
  bizMessage?: string
  timestamp?: string
  sign?: string
  signType?: string
  channel?: string
  api?: string
}

/** UDB返回 body 数据格式 */
type UdbBody<T extends Record<string, any> = any> = {
  data?: T
  rescode: string
  resmsg: string
  stoken?: string
}

export const handleInterceptor = (interceptor: AxiosInstance['interceptors']) => {
  interceptor.request.use(async (requestOptions: AxiosRequestConfig) => {
    const finalOptions = cloneDeep(requestOptions)
    // 接入trace
    setTraceInfo(finalOptions)
    const data = finalOptions.data || {}
    // 在外面获取会导致获取出来的是undefined，从而使得locallanguage 是en
    // 因为用户可能改设置，所以这里改成实时读
    const selfLanguageInfo = LANGUAGE.filter((item) => item.lang === window.localStorage.i18nextLng)
    const localLang = selfLanguageInfo[0] ? selfLanguageInfo[0].languageType : 'en'

    finalOptions.data = defaultsDeep(data, { language: localLang, sourceSystem: 'ONECLUB' })

    return finalOptions
  })

  interceptor.response.use(
    (response: AxiosResponse) => {
      // trace 海度上报
      hiidoReport(response)

      const result = (() => {
        let { data } = response
        data = formatHumpLineTransfer(data)
        if (typeof data === 'undefined') {
          return { message: 'Failed to obtain data' }
        }

        const { code, bizMessage, message } = data
        if (code === 'SUCCESS') {
          return data
        }

        if (code === 'USER_NOT_VALID' || code === 'COOKIE_EXPIRED' || code === 'A0000001' || code === 'LST-135913-B0005') {
          Cookies.remove('osudb_uid')
          Cookies.remove('osudb_appid')
          Cookies.remove('osudb_subappid')
          // 多版本的cookie
          Cookies.remove('AQM_VERSION')
          Cookies.remove('authorization')
          const redirect_url = encodeURIComponent(location.href)
          window.location.replace(`/user/login?app=oc&redirect_url=${redirect_url}`)
          return
        }

        if (code === 'DATA_NOT_FOUND') {
          return {
            ...data,
            code: 'SUCCESS',
            data: null,
          }
        }

        if (code === 'COUNTRY_UNAUTHORIZED') {
          return Promise.reject(data)
        }

        return Promise.reject({
          ...data,
          message: bizMessage || message,
        })
      })()

      if (typeof result?.catch === 'function') {
        return result.catch((rejection: Error | Record<string, any>) => {
          adminSentryReport(rejection, response)
          return Promise.reject(rejection)
        })
      }

      return result
    },
    (rejection) => {
      const { data } = rejection?.origin?.response || {}
      const { code } = data || {}
      if (code === 'A0000001') {
        Cookies.remove('osudb_uid')
        Cookies.remove('osudb_appid')
        Cookies.remove('osudb_subappid')
        // 多版本的cookie
        Cookies.remove('AQM_VERSION')
        Cookies.remove('authorization')
        const redirect_url = encodeURIComponent(location.href)
        window.location.replace(`/user/login?app=oc&redirect_url=${redirect_url}`)
        return
      }

      if (isCancelOrAbsorted(rejection)) {
        return Promise.reject(rejection)
      }

      adminSentryReport(rejection, rejection?.response)
      return Promise.reject(rejection)
    }
  )
}

/** 最终 http 生成器 */
const generate = compose({
  admin: {
    interceptors(interceptor: AxiosInstance['interceptors']) {
      handleInterceptor(interceptor)
    },
    extensions: {
      takeLatest: takeLatest,
    },
  },
  udb: {
    interceptors(inteceptor: AxiosInstance['interceptors']) {
      inteceptor.response.use((response: AxiosResponse) => {
        const { data } = response || {}
        const { rescode, resmsg } = data || {}

        // 成功
        if (rescode === '0') {
          return data
        }

        udbSentryReport(resmsg, response)
        return Promise.reject(data)
      })
    },
  },
  normal: {
    interceptors(interceptor: AxiosInstance['interceptors']) {
      handleInterceptor(interceptor)
    },
    extensions: {
      takeLatest: takeLatest,
    },
  },
})

type GenerateBody = {
  admin: GatwayBody<unknown>
  normal: GatwayBody<unknown>
  udb: UdbBody<unknown>
}

type GenerateChains = {
  admin: ['data']
  udb: ['data']
  normal: ['data']
}

type GenerateExtends = MapTakeLatest<GenerateBody, GenerateChains>

/** 最终 http 实例 */
// prettier-ignore
const http = generate<GenerateBody, GenerateChains, GenerateExtends>()
export default http
