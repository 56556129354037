import { AccountType } from '@common/oneclub/bizComponents'
import http from '@common/oneclub/services/http'

export interface UserInfo {
  /**企业名字 */
  nickname: string
  email: string
  /**是否禁用，false-不禁用，true-禁用 */
  banned: boolean
  /**仓库列表 */
  warehouseBasicInfoRespDtos: {
    /**仓库id */
    warehouseId: string
    /**string */
    warehouseName: string
    /** 仓库所在国家地区 */
    country: string
    /** 仓库详细地址 */
    address: string
    /** 当前选中的国家/地区 */
    selfArea: string
  }[]
}

export type SOURCE_SYSTEM_TYPE = 'ONESHIP' | 'ONEWAREHOUSE'
interface RegistryUserParams {
  language?: string
  uid: string
  countryCode: string
  phone?: string
  email?: string
  registrySource: 'email' | 'tel' | 'facebook' | 'google'
  sourceSystem: SOURCE_SYSTEM_TYPE
}
/**
 * 注册成功通知
 * 避免延迟几秒进入系统
 * @returns
 */
export const registryUser = (data: RegistryUserParams) => http.normal.post('/api/user/info/registry', data)

export interface UserSettingListType {
  /** 来源国家地区 */
  source_country?: string
  /** 配置编码列表 */
  setting_codes?: string[]
}

export interface UserSettingListResType {
  extMap?: Record<string, any>
  settings: {
    settingCode: string
    dataType: any
    dataValue: string
  }[]
}
export interface IInfoProps {
  oneshipName: string
}

interface OneshipUserCheckPersonRequest {
  /**用户uid */
  uid: string
}
export enum USER_TYPE_ENUM {
  /** 企业用户 */
  enterprise = '0',
  /**个人用户 */
  personal = '1',
  /** 没有这个uid */
  notExist = '2',
}
export interface checkEmailTypeRes {
  sellerName: string
  /**
   * 0 企业用户
   * 1个人用户
   * 2:没有这个uid
   */
  userType: string
  token: string
}
interface CancelConfirmType {
  ticket: string
  code: string
}

interface UserAppealType {
  name?: string
  phone: string
  email: string
}

/**
 * 查询用户类型：判断是否可以合并账户
 * 不走网关！因为网关需要udb鉴权
 * @returns
 */
export const checkEmailType = (params: OneshipUserCheckPersonRequest) => http.normal.post<checkEmailTypeRes>('/api/user/info/get-usertype', params)

export interface CountryCheckRes {
  countryCode: string
}

export interface RegisterMethodItem {
  /** 用户注册类型 email、tel、facebook、google */
  registerSource: AccountType
  /** 注册/绑定邮箱显示: 0-否，1-是 */
  showEmail: number
  /** 注册/绑定邮箱必填: 0-否，1-是 */
  requireEmail: number
  /** 注册/绑定手机显示: 0-否，1-是 */
  showPhone: number
  /** 注册/绑定手机必填: 0-否，1-是 */
  requirePhone: number
  /** 通知邮箱显示：0-否，1-是 */
  showNotifyEmail: number
  // 通知邮箱必填: 0-否，1-是
  requireNotifyEmail: number
  /** 邮箱注册-联系手机显示：0-否，1-是 */
  showContactPhone?: number
  /** 邮箱注册-联系手机必填: 0-否，1-是 */
  requireContactPhone?: number
  // 排序，升序排序
  order: number
}

export interface MethodItem {
  countryCode: string
  registerMethods: RegisterMethodItem[]
}

export type FormatConfigType = {
  normalRegisterMethods: Array<RegisterMethodItem>
  thirdRegisterMethods: Array<RegisterMethodItem>
  facebook?: RegisterMethodItem
  google?: RegisterMethodItem
  email?: RegisterMethodItem
  tel?: RegisterMethodItem
}

/**
 * ip归属地获取
 * 不走网关！因为网关需要udb鉴权
 * @returns
 */
export const getCountryByIp = () => http.normal.post<CountryCheckRes>('/api/country/get-countrycode-by-ip')

/** 个人隐私申诉 */
export const userAppeal = async (data: UserAppealType) => http.normal.post('/api/appeal/create', data)

/** 个人隐私申诉确认 */
export const userAppealConfirm = async (data: CancelConfirmType) => await http.normal.post('/api/appeal/confirm', data)

/** 商家注销确认 */
export const sellerCancelConfrim = async (data: CancelConfirmType) => await http.normal.post('/api/seller/info/cancellationConfirm', data)

/** 注册方式配置 */
export const fetchRegisterConfig = async () => await http.normal.post<{ methods: MethodItem[] }>('/api/setting/register/get-setting-by-countrycode')

/** 注册方式配置 */
export const fetchCountryListAll = async () => await http.normal.post('/api/country/all')

/** 子账号注册成功回调 */
export const subAccountRegistey = async (data) => await http.normal.post('/api/admin/setting/sub-account-registry', data)
