import { List } from 'antd'
import { isEqual } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChevronDown, ChevronUp, FailureXCross, NotifyExport, NotifySuccess } from '@yy/one-icon'
import { OneSecondaryButton } from '@yy/one-ui'
import CircleLoading from '@/components/CircleLoading'
import { useDispatch } from '@common/oneclub/store'
import { readDownload } from '@common/oneclub/store/notificationSlice'
import { useGetDownLoadInfo } from '@common/oneclub/hooks'
import styles from '../styles/listItem.module.less'
import type * as Types from '../type'
import { formatDateFn } from '@common/oneclub/utils'

const ListItem: React.FC<Types.IlistDownloadItemType> = (props) => {
  const { item } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)

  const { messageInfo, messageStatus, btnConfigs } = useGetDownLoadInfo({ item })

  const [read, setRead] = useState(item.read)

  const isExpiredClass = useMemo(() => (item.fileStatus === 4 ? styles.isExpired : ''), [item.fileStatus])

  const itemIcon = useMemo(() => {
    if (messageStatus === 'normal') {
      return <NotifyExport origin />
    }
    if (messageStatus === 'success') {
      return <NotifySuccess origin />
    }
    if (messageStatus === 'fail') {
      return <FailureXCross origin />
    }
  }, [messageStatus])

  const icon = useMemo(() => {
    if (item.fileStatus === 1) {
      return <CircleLoading size={20} key={0} />
    }

    if (item.fileStatus === 4) {
      return t('download.expired')
    }

    return open ? <ChevronUp className={styles.icon} /> : <ChevronDown className={styles.icon} />
  }, [item.fileStatus, open, t])

  const isReadClass = useMemo(() => (read ? styles.isRead : ''), [read])

  const handleClick = () => {
    setOpen(!open)
    if (!read) {
      dispatch(readDownload(item.id)).then(() => {
        setRead(true)
      })
    }
  }

  const createTime = useMemo(() => formatDateFn(item?.createTime), [item?.createTime])

  return (
    <div className={`${styles.itemWrap} ${isReadClass} ${isExpiredClass}`}>
      <List.Item onClick={handleClick} style={{ width: '352px' }}>
        <div style={{ width: '100%' }}>
          <div className={styles.item}>
            <div className={styles.left}>
              <span className={styles.itemIcon}>{itemIcon}</span>
              <span className={styles.title}>{messageInfo.title}</span>
            </div>
            {icon}
          </div>
          <div className={styles.content} style={{ display: open ? 'block' : 'none' }}>
            <div className={styles.desc}>{messageInfo.content}</div>
            <div className={styles['button-wrap']}>
              {btnConfigs.map((btn, ind) => (
                <OneSecondaryButton key={ind} className={styles.button} onClick={btn.onClick}>
                  {btn.btnText}
                </OneSecondaryButton>
              ))}
            </div>
          </div>
          {createTime && <div className={styles.timeline}>{createTime}</div>}
        </div>
      </List.Item>
    </div>
  )
}

export default React.memo(ListItem, (prev, next) => {
  const result = Object.keys(prev).every((key) => {
    if (key === 'item') {
      return isEqual(prev[key], next[key])
    }

    return true
  })

  return result
})
