import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Input } from 'antd-mobile'
import styles from './index.module.less'
import classnames from 'classnames'

interface InputProps {
  placeholder?: string
  /** input type */
  type?: string
  value?: string
  className?: string[] | string
  error?: boolean
  errorTips?: string
  /** 右侧显示内容 */
  right?: string | React.ReactNode
  disabled?: boolean
  onChange?: (string) => void
}

export const OneInput: React.FC<InputProps> = (props) => {
  const { placeholder, className = [], error = false, onChange, type, errorTips, value = '', right, disabled = false } = props
  const [focus, setFocus] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  const inputEl = useRef(null)
  const classList = Array.isArray(className) ? [...className] : [className]

  const onFocusHanlder = () => {
    inputEl.current?.focus()
  }
  const changeHandler = useCallback(
    (value) => {
      setInputValue(value)
      onChange && onChange(value)
    },
    [onChange]
  )

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div
      className={classnames([
        styles.inputWrapper,
        {
          [styles.actived]: focus,
          [styles.hasValue]: focus || inputValue,
          [styles.errored]: error,
        },
        ...classList,
      ])}
    >
      <div className={styles.inputContent}>
        <div className={styles.placeholder} onClick={onFocusHanlder}>
          {placeholder}
        </div>
        <Input
          disabled={disabled}
          className={styles.input}
          value={inputValue}
          ref={inputEl}
          type={type}
          autoComplete="new-password"
          onChange={changeHandler}
          onFocus={() => {
            setFocus(true)
          }}
          onBlur={() => {
            setFocus(false)
          }}
        />
      </div>
      {right}
      {error && errorTips && <div className={styles.errTips}>{errorTips}</div>}
    </div>
  )
}
