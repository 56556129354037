import Api from '../../http'
import type * as Types from './type'

export const pollingPayStatus = (billId: number) => {
  return Api.admin.post<Types.IBillList>('/api/crm/admin/subscription/bill/get', { billId })
}
/**
 * 账单列表
 * @param parmas
 * @returns
 */
export const getBillList = (parmas: Types.IBillListReq) => {
  return Api.admin.post<Types.IBillListRes>('/api/crm/admin/subscription/bill/list', parmas)
}

export const exportBill = (parmas: Types.IBillListReq) => {
  return Api.admin.post<Types.IBillListRes>('/api/subscription/export/bill', parmas)
}

export const getFlowList = (parmas: Types.IFlowsListReq) => {
  return Api.admin.post<Types.IRightsListRes>('/api/crm/admin/subscription/rights/list', parmas)
}

export const cancelBill = (billId: number) => {
  return Api.admin.post('/api/crm/admin/subscription/bill/cancel', { billId })
}

// 获取商家订阅信息
export const getSubscriptionInfo = () => {
  return Api.admin.post<Types.IGetSubscriptionInfoRes>('/api/crm/admin/subscription/info')
}

// 设置是否自动续费
export const setAutoRecurring = (data: Types.ISetAutoRecurringReq) => {
  return Api.admin.post('/api/crm/admin/subscription/set-auto-recurring', data)
}

// 查询绑卡结果、信息(轮询时调用)
export const getCardInfo = (data: { bindUniqueId: string }) => {
  return Api.admin.post<Types.ICardInfoDTO>('/api/crm/admin/subscription/bill/card/info', data)
}

// 已绑定卡信息
export const getCardBounds = () => {
  return Api.admin.post<Types.IGetCardBounds>('/api/crm/admin/subscription/bill/card/bounds')
}

// sdk初始化信息
export const getBillSdkInfo = () => {
  return Api.admin.post<Types.IGetBillSdkInfoRes>('/api/crm/admin/subscription/bill/sdk/meta')
}

export const getSubscriptionProductGroup = () => {
  return Api.admin.post<Types.IGetSubscriptionProductGroupRes>('/api/crm/admin/subscription/product/group')
}

// 获取优惠信息
export const getSubscriptionProductPromotion = (param: any) => {
  return Api.admin.post<any>('/api/crm/admin/promotion/list-sub-promotion', {
    ...param,
    pageNum: 1,
    pageSize: 1000,
  })
}

export const getUnpaidSubBill = () => {
  return Api.admin.post<Types.IGetUnpaidSubBill>('/api/crm/admin/subscription/bill/get-unpaid-sub-bill')
}

export const getSubProducts = (data: Types.IGetSubProductsPrams) => {
  return Api.admin.post<Types.IGetSubProductsRes>('/api/crm/admin/subscription/product/products', data)
}

// 绑定卡
export const bindCard = (data) => {
  return Api.admin.post<{
    /** 错误码 */
    errCode: string
    /** 支付中台响应对象 */
    nextAction: Record<string, unknown>
    /** 绑定状态 */
    bindStatus: number
  }>('/api/crm/admin/subscription/bill/card/bind', data)
}

// 支付，默认绑卡
export const subBillPay = (data) => {
  return Api.admin.post('/api/crm/admin/subscription/bill/pay', data)
}

export const subUpgrade = (data) => {
  return Api.admin.post('/api/crm/admin/subscription/upgrade', data)
}

export const subCreate = (data) => {
  return Api.admin.post('/api/crm/admin/subscription/create', data)
}

/**
 * 获取商家当前套餐状态
 */
export const subscriptionStatus = () => Api.admin.post<Types.IPackageStatusRes>('/api/crm/admin/subscription/status')

export const billCardList = (data: Types.BillCardListReq) => Api.admin.post<Types.BillCardListRes>('/api/crm/admin/subscription/bill/card/list', data)

/**
 * 获取商家订阅当前账号类控制上限使用情况
 */
export const accountLimit = () => Api.admin.post<Types.AccountLimitStatusRespDTO[]>('/api/crm/admin/subscription/account/limit_info')

export const balancePay = (data: Types.BalancePayReq) => Api.admin.post<Types.BalancePayResp>('/api/crm/admin/wallet/pay', data)

/** 获取Settlement sdk token */
export const getSettleMentSdkToken = () => Api.admin.post<Types.TokenGetRes>(`/api/crm/admin/subscription/auth/token/get`)

/** 查询权益额度告警设置 */
export const getRightsWarn = () =>
  Api.admin.post<{
    list: Types.RightConfgType[]
  }>('/api/crm/admin/subscription/rights/warn/config')
