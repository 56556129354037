export enum UdbResCodeEnum {
  CAPTCHA_CODE = '1015',
  REGISTER_CAPTCHA_CODE = '2015', // 有风险
  REGISTER_CAPTCHA_ERROR_CODE = '2014', // 验证码失败
  REGISTER_VERIFY_CAPTCHA_ERROR_CODE = '2019', // 用户注册获取验证码有风险
  FORGOT_CAPTCHA_CODE = '3018', // 安全中心发送短信有风险
  FORGOT_CAPTCHA_ERROR__CODE = '3019', // 校验风控动态验证码失败
  REGISTER_OVER_TIMES = '2016', // 注册超过次数
  FORGOT_OVER_TIMES = '3020', // 忘记密码超过次数
  FORGOT_VERIFY_CODE = '3006', // 忘记密码校验验证码超过三次需要重新发送验证码
  REGISTER_VERIFY_CODE = '2006', // 注册校验验证码超过三次需要重新发送验证码
}

export const APP_ID = process.env.APPID
export const SUB_APP_ID = process.env.SUB_APPID

export enum ChangeTypeEnum {
  PSW = 1,
  EMAIL = 2,
  MOBILE = 3,
}

export enum StepEnum {
  VERIFY = 1,
  RESET = 2,
}

export const CHANGE_I18N_MAP = {
  [ChangeTypeEnum.PSW]: {
    btn: 'udb.forgot.reset.title',
    title: 'router.changePassword',
  },
  [ChangeTypeEnum.EMAIL]: {
    btn: 'udb.change.byEmail',
    title: 'udb.change.byEmail.title',
  },
  [ChangeTypeEnum.MOBILE]: {
    btn: 'udb.change.byMobile',
    title: 'udb.change.byMobile.title',
  },
}

export const FORGOT_I18N_MAP = {
  [ChangeTypeEnum.PSW]: {
    btn: 'udb.button.verifyPsw',
    title: 'router.changePassword',
  },
  [ChangeTypeEnum.EMAIL]: {
    btn: 'udb.forgot.byEmail.btn',
    title: 'udb.forgot.byEmail.title',
  },
  [ChangeTypeEnum.MOBILE]: {
    btn: 'udb.forgot.byMobile',
    title: 'udb.forgot.byMobile.title',
  },
}

export const REGISTER_SOURCE_MAP = {
  email: [ChangeTypeEnum.PSW, ChangeTypeEnum.EMAIL],
  tel: [ChangeTypeEnum.PSW, ChangeTypeEnum.MOBILE],
  facebook: [ChangeTypeEnum.PSW],
  google: [ChangeTypeEnum.PSW],
}

export const APP_CODE = 'amljpt'

export const COUNTRY_DOMAIN_MAP = {
  CN: process.env.OS_ADMIN_CN,
}
/* 返回码文档 https://shopline.yuque.com/sczv0i/zbwfmf/lg4txw */
/** 通用返回码 */
export const COMMON_FILTER_CODES = ['-1', '-2', '-3', '-4', '-5', '-6', '-13']

/** 登录流程返回码 */
export const LOGIN_FILTER_CODES = [
  '1001',
  '1002',
  '1003',
  '1004',
  '1005',
  '1006',
  '1007',
  '1008',
  '1009',
  '1011',
  '1013',
  '1014',
  '1015',
  '1016',
  '1017',
  '1018',
  '1019',
  '1020',
  '1021',
  '1022',
  '1023',
  '1024',
]

/** 注册流程返回码 */
export const REGISTER_FILTER_CODES = ['2001', '2002', '2003', '2004', '2005', '2006', '2009', '2010', '2011', '2014', '2015', '2016', '2019']

/** 安全中心流程返回码 */
export const SAFE_FILTER_CODES = ['3001', '3002', '3003', '3004', '3005', '3006', '3007', '3008', '3009', '3010', '3011', '3012', '3013', '3014', '3015', '3017', '3018', '3019']
