import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

interface CountDownProps {
  count?: number
  propHistory?: ReturnType<typeof useHistory>
  countDownfinish?: () => void
  className?: string
}

export const CountDown: React.FC<CountDownProps> = ({ count = 3, propHistory, countDownfinish, className }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [countDown, setCountDown] = useState(count)
  const timer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (countDown === 0) {
      clearInterval(timer.current)
      history ? history.replace('/user/register') : propHistory?.replace('/user/register')
      countDownfinish && countDownfinish()
    }
  }, [countDown, countDownfinish, history, propHistory])

  useEffect(() => {
    timer.current = setInterval(() => {
      setCountDown((countDown) => countDown - 1)
    }, 1000)

    return () => {
      clearInterval(timer.current)
    }
  }, [])

  return <div className={className}>{t('user.toRegister.time', { n: countDown })}</div>
}

CountDown.displayName = 'CountDown'
