// 导入oneui样式
import '@yy/one-ui/es/style/antd/index.less'
import '@yy/one-ui/es/style/index.less'

// 覆盖自定义主题色
import '@common/oneclub/styles/ant.less'

// 全局样式
import '@common/oneclub/index.css'
import '@common/oneclub/styles/overwrite.css'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import SLInternalGateway from '@sl/internal-gateway'
import { Provider } from 'react-redux'
import { DeviceProvider } from '@common/oneclub/components/Device'
import store from '@common/oneclub/store'
import { initSentry } from '@common/oneclub/services/sentry'
import '@common/oneclub/i18n'
import * as serviceWorker from '@common/oneclub/serviceWorker/serviceWorkerRegistration'

import App from './App'

initSentry(process.env.SENTRY_DSN)

SLInternalGateway.init({
  baseUrl: '/gapi/v20220301',
  appId: process.env.APPID,
  subAppId: process.env.SUB_APPID,
  clientType: 'h5', // 客户端类别，用于限流使用。 pc | app | h5
})

function render() {
  ReactDOM.render(
    <DeviceProvider device="pc">
      <Provider store={store}>
        <Suspense fallback={null}>{<App />}</Suspense>
      </Provider>
    </DeviceProvider>,
    document.getElementById('root')
  )
}
render()
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}

if (process.env.NODE_ENV === 'development') {
  serviceWorker.unregister()
} else {
  serviceWorker.register()
}
