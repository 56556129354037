import React, { useLayoutEffect } from 'react'
import { useDispatch } from '@common/oneclub/store'
import { setAdminTicket } from '@common/oneclub/store/userSlice'
import { deleteUrlQuery, getUrlParam, multiAccountLoginCheck } from '@common/oneclub/utils'
import Cookies from 'js-cookie'

/** 超管登录设置ticket */
export const withSuperManager = <P extends Record<string, any>>(Component: React.FC<P>) => {
  const SuperManager: React.FC<P> = (props) => {
    const dispatch = useDispatch()

    useLayoutEffect(() => {
      const ticket = getUrlParam('adminTicket') || Cookies.get('authorization')
      if (ticket) {
        multiAccountLoginCheck()
        dispatch(setAdminTicket(ticket))
        Cookies.set('authorization', ticket)
        deleteUrlQuery(['adminTicket'])
      }
    }, [dispatch])

    return <Component {...props} />
  }

  SuperManager.displayName = 'SuperManager'
  return SuperManager
}
