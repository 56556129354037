import { useRequest } from 'ahooks'
import { getTargetOmsConfig } from '../services/api'

/**
 * 使用配置的条款，全局缓存1小时
 * @returns
 */
export function useProtocol() {
  const { data: settings, loading } = useRequest(() => getTargetOmsConfig('oneclub_terms'), { cacheKey: 'oneclub_terms', staleTime: 3600 * 1000 })

  return { settings, loading }
}
