import http from '@common/oneclub/services/http'

interface SubmitKYCReqDTO {
  /**语言 */
  language?: string
  name: string
  phone: string
  email: string
  /**企业名称 */
  companyName: string
  /**统编号码/身份证字号 */
  idNumber: string
  /**您每月平均订单量 https://shopline.yuque.com/mlu41d/dwk0hx/axgs41#Rn2SR */
  avgOrderPerMonth?: string
  /**所使用的电商平台 https://shopline.yuque.com/mlu41d/dwk0hx/axgs41#Rn2SR */
  ecommercePlatforms?: string[]
  /**图形验证码 */
  captchaToken: string
}

/** 注册后提交 KYC 信息 */
export const submitKYC = (data?: SubmitKYCReqDTO) => {
  return http.normal.post('/api/customer/info/kyc', data)
}
