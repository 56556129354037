import i18n from '../i18n'

import type { Assign } from 'utility-types'
import emojiRegex from 'emoji-regex'
import type { ValidatorRule, RuleObject, StoreValue } from 'rc-field-form/es/interface'

type Options = {
  checkRequired?: boolean
}
interface OptionsParams {
  checkRequired?: boolean
}

type Rule = Assign<
  Omit<ValidatorRule, 'validator'>,
  {
    validator?(rule: RuleObject, value: StoreValue): Promise<any>
  }
>

declare function Create<R extends Record<string, ((...args: any[]) => Rule) | (() => Rule)>>(rules: R): R

/**
 * antd表单自定义校验规则
 */

interface OptionsParams {
  checkRequired?: boolean
  maxLength?: number
}

/** antd表单自定义校验规则 */
export default (((rules) => rules) as typeof Create)({
  /* 姓名 */
  fullName: (options?: Options) => {
    return {
      validator: async (_, value: string) => {
        const length = value?.length
        if (options.checkRequired && !length) {
          throw new Error(i18n.t('common.formValidRequired'))
        }

        if (length > 64) {
          throw new Error(i18n.t('common.formValidLengthLessThan', { length: 64 }))
        }
      },
    }
  },
  /* 邮箱 */
  email: (options?: OptionsParams) => {
    return {
      validator: async (_, value: string) => {
        const { checkRequired = true } = options || {}
        const length = value?.length
        if (checkRequired && !length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        // eslint-disable-next-line
        if (value && !/^([A-Za-z0-9_\-\+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(value)) {
          throw Error(i18n.t('common.formValidEmail'))
        }
        if (length > 64) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: 64 }))
        }
        return true
      },
    }
  },
  /* 手机 */
  mobile: (options?: OptionsParams) => {
    return {
      validator: async (_, value: string) => {
        const { checkRequired = true, maxLength = 30 } = options || {}
        const length = value?.length
        if (checkRequired && !length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value && !/^\+?\d+$/.test(value)) {
          throw Error(i18n.t('common.formValidMobile'))
        }
        if (length > maxLength) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: maxLength }))
        }

        return true
      },
    }
  },
  /* 必填 */
  required: () => {
    return {
      validator: async (_, value: string) => {
        if (!value?.length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
      },
    }
  },
  /* 最大长度 */
  maxLength: (max: number, required = true) => {
    return {
      validator: async (_, value: string) => {
        if (required && !value?.length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value?.trim()?.length > max) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: max }))
        }
      },
    }
  },
  /* 密码 */
  loginPwd: () => {
    return {
      validator: async (_, value: string) => {
        const length = value?.length || 0
        if (!length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (length < 8) {
          throw Error(i18n.t('common.formValidLengthMoreThan', { length: 8 }))
        }
        // 需要支持2种以及以上的校验
        // 大小写字母
        const isInUpperCase = /[A-Z]+/g.test(value)
        const isInLowerCase = /[a-z]+/g.test(value)
        const isNumber = /[0-9]+/g.test(value)
        const isChar = /[\!|\"|\#|\$|\%|\&|\'|\(|\)|\*|\+|\,|\-|\.|\/|\:|\;|\<|\=|\>|\?|\@|\[|\\|\]|\^|\_|\`|\{|\||\}|\~]+/g.test(value)
        // 密码只允许在这些字符组合里面
        const re = /^[A-Za-z0-9\!|\"|\#|\$|\%|\&|\'|\(|\)|\*|\+|\,|\-|\.|\/|\:|\;|\<|\=|\>|\?|\@|\[|\\|\]|\^|\_|\`|\{|\||\}|\~]+$/.test(value)
        const pwdVerify = [isInUpperCase, isInLowerCase, isNumber, isChar]
        const isMoreThenTwoVerify = pwdVerify.filter((verify) => verify).length >= 2
        if (!isMoreThenTwoVerify || !re) {
          throw Error(i18n.t('common.formValid.passwordHelpText'))
        }
        if (length > 30) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: 30 }))
        }
        return true
      },
    }
  },
  pwdAgain: ({ getFieldValue }) => {
    return {
      validator: async (_, value: string) => {
        if (!value || !value.length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        const pwd = getFieldValue('pwd')
        if (pwd !== value) {
          throw Error(i18n.t('common.formValid.passwordNotEqual'))
        }
      },
    }
  },
  account: () => {
    return {
      validator: async (_, value: string) => {
        const length = value?.length
        if (!length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (length > 64) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: 64 }))
        }
        if (value.includes('@')) {
          if (!/^([A-Za-z0-9_\-\+\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/.test(value)) {
            throw Error(i18n.t('login.validate.account'))
          }
          return true
        }
        if (value && !/^\+?\d+$/.test(value)) {
          throw Error(i18n.t('login.validate.account'))
        }
        return true
      },
    }
  },
  withoutEmoji() {
    return {
      validator(_, value) {
        if (value && emojiRegex().test(value)) {
          return Promise.reject(i18n.t('common.validate.noSpecialChar'))
        }
        return Promise.resolve()
      },
    }
  },
  /* 客服电话 */
  contactPhone: (options?: OptionsParams) => {
    return {
      validator: async (_, value: string) => {
        const { checkRequired = true, maxLength = 20 } = options || {}
        const length = value?.length
        if (checkRequired && !length) {
          throw Error(i18n.t('common.formValidRequired'))
        }
        if (value && !/^[0-9-]+$/.test(value)) {
          throw new Error(i18n.t('common.formValidContactPhone'))
        }
        if (length > maxLength) {
          throw Error(i18n.t('common.formValidLengthLessThan', { length: maxLength }))
        }

        return true
      },
    }
  },
  antdRequired: () => {
    return {
      required: true,
      message: i18n.t('common.formValidRequired'),
    }
  },
})

export const EMPTY_RULES = [{ validator: () => Promise.resolve() }]
