import type { ValuesType } from 'utility-types'
import type { IWallInfo } from '@common/oneclub/store/authSlice/type'
import type { SocketListener } from './socket'
import { Socket } from './socket'

/** 消息类型 */
export const TOPICS = {
  USER_MESSAGE: 'broadcast://user_message',
  EXPORT_LIST: 'broadcast://export_list_message',
  ASYNC_RESULT: 'broadcast://async_result_event',
  /** 钱包余额更新 */
  BALANCE_UPDATE: 'broadcast://oneclub/wallet_deposit_message',
} as const

/** 消息类型 */
export type Topic = ValuesType<typeof TOPICS>

export interface SocketVersionUpdatePayload {
  version: string
  uid: string
}

export class OneClubSocket extends Socket<Topic> {
  static readonly TOPICS = TOPICS

  public connect() {
    const topics = Object.keys(TOPICS).map((name) => TOPICS[name])
    this.registerTopics(topics)
  }

  public onUserMessage<P = any>(handle: SocketListener<P>) {
    return this.on(TOPICS.USER_MESSAGE, handle)
  }

  public onExportListMessage<P = any>(handle: SocketListener<P>) {
    return this.on(TOPICS.EXPORT_LIST, handle)
  }

  public onBalanceUpdate(handle: SocketListener<IWallInfo>) {
    return this.on(TOPICS.BALANCE_UPDATE, handle)
  }
}
